import * as React from 'react';

function SvgPhone(props) {
    return (
        <svg width={19} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M15.815 9.929c-.2 0-.408-.064-.607-.109a8.552 8.552 0 0 1-1.187-.353 1.812 1.812 0 0 0-2.246.905l-.2.408c-.882-.49-1.693-1.1-2.41-1.812a11.034 11.034 0 0 1-1.811-2.41l.38-.253A1.812 1.812 0 0 0 8.64 4.06a9.357 9.357 0 0 1-.353-1.187 6.724 6.724 0 0 1-.109-.616A2.718 2.718 0 0 0 5.461 0H2.743A2.718 2.718 0 0 0 .026 3.09 17.212 17.212 0 0 0 14.99 18h.344a2.717 2.717 0 0 0 2.483-1.61c.156-.352.236-.732.234-1.117v-2.717a2.717 2.717 0 0 0-2.237-2.627zm.453 5.435a.905.905 0 0 1-.656.871.961.961 0 0 1-.395.035A15.4 15.4 0 0 1 1.892 2.88a.987.987 0 0 1 .226-.743.906.906 0 0 1 .68-.308h2.717a.906.906 0 0 1 .906.716c.036.247.082.492.136.734.105.477.244.946.417 1.404l-1.269.588a.906.906 0 0 0-.444 1.205 13.126 13.126 0 0 0 6.342 6.341c.22.091.467.091.688 0a.907.907 0 0 0 .516-.47l.562-1.269c.468.168.946.307 1.431.417.242.054.486.1.734.136a.906.906 0 0 1 .716.906l.018 2.826z"
                fill="#FA835F"
            />
        </svg>
    );
}

export default SvgPhone;
