import React from 'react';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import CosmeticsIcon from 'static/icons/CosmeticsIcon.svg';
import MakeUpIcon from 'static/icons/MakeUpIcon.svg';
import MassageIcon from 'static/icons/MassageIcon.svg';
import NailIcon from 'static/icons/NailIcon.svg';
import ScissorsIcon from 'static/icons/ScissorsIcon.svg';
import SpaIcon from 'static/icons/SpaIcon.svg';
import TattooIcon from 'static/icons/TattooIcon.svg';
import { useTranslation } from 'react-i18next';
import style from './style.module.scss';
import { useSelector } from 'react-redux';
import { getSalonSelector } from 'modules/salon';
import { getCurrencySuffix } from 'helpers/getCurrencySuffix';
import { NumberFormat } from 'components';
import { InputProps } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        fontSize: '12px !important',
        borderRadius: '6px',
    },
    input: {
        paddingTop: '9px',
        paddingBottom: '9px',
    },
    icon: { width: '35px', height: '35px' },
}));
const saveNumber = ({
    setNumber,
    ev,
}: {
    setNumber: (str: string) => void;
    ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
}) => {
    const str = ev.target.value || '';
    setNumber(str.replace(/[^0-9.]/g, ''));
};

/**
 * DataInterface
 *
 * @interface
 * @property {string | number} id - The unique identifier for the data item.
 * @property {string} title - The title of the data item.
 * @property {string} parent_uid - The unique identifier for the parent of the data item.
 * @property {string} price - The price of the data item.
 * @property {number} duration - The duration of the data item.
 * @property {boolean} [is_unique_custom_service] - A flag indicating if the data item is a unique custom service.
 */

export interface DataInterface {
    id: string | number;
    title: string;
    parent_uid: string;
    price: string;
    duration: number;
    is_unique_custom_service?: boolean;
}

/**
 * SelectedItemInterface
 *
 * @interface
 * @property {string | number} id - The unique identifier for the selected item.
 * @property {string} [price] - The price of the selected item.
 * @property {number} [duration] - The duration of the selected item.
 * @property {boolean} [is_unique_custom_service] - A flag indicating if the selected item is a unique custom service.
 */
export interface SelectedItemInterface {
    id: string | number;
    price?: string;
    duration?: number;
    is_unique_custom_service?: boolean;
}

/**
 * SubItemProps
 *
 * @interface
 * @property {DataInterface} data - The data for the sub item.
 * @property {boolean} [showPrice] - If true, the price is shown.
 * @property {boolean} [showDuration] - If true, the duration is shown.
 * @property {boolean} [showInputsForce] - If true, inputs are forcibly shown.
 * @property {Function} setSelected - Function to set the selected items.
 * @property {SelectedItemInterface[]} selected - The selected items.
 * @property {boolean} [showInputs] - If true, inputs are shown.
 * @property {any} [inputComponent] - The component to be used as the input.
 * @property {TextFieldProps} [inputProps] - The props for the input component.
 * @property {TextFieldProps} [priceProps] - The props for the price component.
 * @property {TextFieldProps} [durationProps] - The props for the duration component.
 * @property {InputProps} [InputPriceProps] - The props for the price input.
 * @property {InputProps} [InputDurationProps] - The props for the duration input.
 */
export interface SubItemProps {
    data: DataInterface;
    showPrice?: boolean;
    showDuration?: boolean;
    showInputsForce?: boolean;
    setSelected: (arr: any[]) => void;
    selected: SelectedItemInterface[];
    showInputs?: boolean;
    inputComponent?: any;
    inputProps?: TextFieldProps;
    priceProps?: TextFieldProps;
    durationProps?: TextFieldProps;
    InputPriceProps?: InputProps;
    InputDurationProps?: InputProps;
}

/** SubItem -  component for show skill with inputs of price and duration
 *
 * @param param0
 *
 * @returns
 */

const SubItem = ({
    data, // item
    selected, // all selected  items
    setSelected, // set  items to selected array
    showInputs, // show inputs
    inputComponent, // input component
    inputProps = {},
    priceProps = {},
    durationProps = {},
    showInputsForce,
    InputPriceProps = {},
    InputDurationProps = {},
    showPrice,
    showDuration,
}: SubItemProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { currency_id } = useSelector<any, any>(getSalonSelector);

    const isSelected = React.useMemo(() => {
        // check if item is selected
        return selected.find(i => i.id == data.id) || false;
    }, [selected]);

    const setPrice = (price: string) => {
        //  add  changed item to selected
        setSelected([
            ...selected.filter(i => isSelected && i.id !== isSelected?.id), // remove  old item
            { ...isSelected, price }, // add  new item
        ]);
    };
    const setDuration = (duration: string) => {
        //  add  changed item to selected
        setSelected([
            ...selected.filter(i => isSelected && i.id !== isSelected?.id), // remove  old item
            { ...isSelected, duration }, // add  new item
        ]);
    };
    const { price, duration } = isSelected || {}; // get  price and duration from selected item

    const hangleClick = () => {
        setSelected(
            !isSelected
                ? [
                      ...selected,
                      showInputsForce
                          ? {
                                id: data.id,
                                price: priceProps.defaultValue || 0,
                                duration: durationProps.defaultValue || 0,
                                is_unique_custom_service: data.is_unique_custom_service,
                            }
                          : {
                                id: data.id,
                                is_unique_custom_service: data.is_unique_custom_service,
                            },
                  ]
                : selected.filter(i => i.id !== data.id)
        );
    };

    const getIcon = () => {
        switch (data.parent_uid) {
            case 'hair':
                return <img src={ScissorsIcon} alt="" width="35px" height="35px" />;
            case 'massage':
                return <img src={MassageIcon} alt="" width="35px" height="35px" />;
            case 'body':
                return <img src={SpaIcon} alt="" width="35px" height="35px" />;
            case 'nails':
                return <img src={NailIcon} alt="" width="35px" height="35px" />;
            case 'tattoo':
                return <img src={TattooIcon} alt="" width="35px" height="35px" />;
            case 'cosmetology':
                return <img src={CosmeticsIcon} alt="" width="35px" height="35px" />;
            case 'face':
                return <img src={MakeUpIcon} alt="" width="35px" height="35px" />;
            default:
                // @ts-ignore
                return <img src={Icon} alt="" width="35px" height="35px" />;
        }
    };

    return (
        <div className={style.item} key={data.id} onClick={hangleClick}>
            <div className={style.titleContainer}>
                <div className={style.iconContainer}>
                    {isSelected ? (
                        <CheckCircleIcon htmlColor="#fa835f" className={classes.icon} />
                    ) : (
                        getIcon()
                    )}
                </div>
                <div className={style.title}> {data.title}</div>
            </div>
            {(isSelected && showInputs) || showInputsForce ? (
                <div
                    className={style.inputs}
                    onClick={ev => {
                        ev.stopPropagation();
                    }}
                >
                    <TextField
                        InputProps={{
                            inputProps: {
                                displayType: 'input',
                            },
                            classes: {
                                root: classes.root,
                                input: classes.input,
                            },
                            inputComponent: inputComponent,
                            ...InputPriceProps,
                        }}
                        value={price}
                        onChange={ev => saveNumber({ ev, setNumber: setPrice })}
                        placeholder={`${t('event_price')}, ${getCurrencySuffix(currency_id)}`}
                        variant="outlined"
                        size="small"
                        onFocus={() => {
                            setSelected(
                                !isSelected
                                    ? [
                                          ...selected,
                                          {
                                              id: data.id,
                                              price: priceProps.defaultValue || 0,
                                              duration: durationProps.defaultValue || 0,
                                          },
                                      ]
                                    : [...selected]
                            );
                        }}
                        {...inputProps}
                        {...priceProps}
                    />
                    <span className={style.divider}> / </span>
                    <TextField
                        value={duration}
                        onChange={ev => saveNumber({ ev, setNumber: setDuration })}
                        placeholder={t('generic_time_min')}
                        InputProps={{
                            classes: {
                                root: classes.root,
                                input: classes.input,
                            },
                            inputProps: {
                                displayType: 'input',
                                suffix: ` ${t('generic_min')}`,
                            },
                            inputComponent: inputComponent,
                            ...InputDurationProps,
                        }}
                        onFocus={() => {
                            setSelected(
                                !isSelected
                                    ? [
                                          ...selected,
                                          {
                                              id: data.id,
                                              price: priceProps.defaultValue || 0,
                                              duration: durationProps.defaultValue || 0,
                                          },
                                      ]
                                    : [...selected]
                            );
                        }}
                        variant="outlined"
                        size="small"
                        {...inputProps}
                        {...durationProps}
                    />
                </div>
            ) : (
                <div className={style.price}>
                    {showPrice && (
                        // @ts-ignore
                        <NumberFormat
                            value={parseFloat(data.price)}
                            displayType={'text'}
                            thousandSeparator={' '}
                            suffix={getCurrencySuffix(currency_id)}
                        />
                    )}
                    {showDuration && (
                        <span className={style.duration}>
                            {`  /  `}
                            {data.duration} {t('generic_min')}.
                        </span>
                    )}
                </div>
            )}
        </div>
    );
};

export default SubItem;
