import React from 'react';
import {
    Popup as PopupBackground,
    RatingLocation,
    Skeleton,
    EventItem,
    NumberFormat,
} from 'components';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { getEventsHistoryRequest, getEventsHistorySelector } from 'modules/events';
import { Phone } from 'assets/img/svg/prepared';
import { getClientInfoRequest, getClientInfoSelector, blockClientRequest } from 'modules/clients';
import { openChat } from 'modules/chat';
import { getSystemLang } from 'helpers/getSystemLang';
import { makeStyles } from '@material-ui/core/styles';
import style from './style.scss';
import ClientNotes from 'containers/ClientNotes';
import {
    getSingleReferenceCategoryRequest,
    getSingleReferenceCategorySelector,
} from 'modules/salon';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import CommentIcon from '@material-ui/icons/Comment';

const color = ' #FF6335';
const useStyles = makeStyles(theme => ({
    root: {
        height: 40,
        width: 40,
        borderWidth: 2,
        boxSizing: 'border-box',
        '&:hover': {
            borderWidth: 2,
        },
        padding: 0,
        margin: '0px 15px 0 0 !important',
        minWidth: '0 !important',
    },
    icon: { fontSize: 22 },
    avatar: {
        width: theme.spacing(10),
        height: theme.spacing(10),
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

const ClientDetailsPopup = ({
    onSubmit,
    onCancel = () => {},
    onClose,
    user_id,
    salon_id,
    isShowPopup,
}: {
    onSubmit: (data: any) => void;
    onCancel?: () => void;
    onClose: () => void;
    user_id: number;
    salon_id: number;
    isShowPopup?: boolean;
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const events = useSelector(getEventsHistorySelector) as any;
    const client = useSelector(getClientInfoSelector) as any;
    const referenceCategories = useSelector(getSingleReferenceCategorySelector) as any;
    const [anchorEl, setAnchorEl] = React.useState(null);

    const lang = getSystemLang();

    const handleClick = (event: any) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (ev: any, data: any) => {
        if (typeof data !== 'undefined') {
            dispatch(
                blockClientRequest(
                    { id: salon_id, clientId: user_id },
                    {
                        onSucces: () => {
                            dispatch(getClientInfoRequest({ id: salon_id, user_id: user_id }));
                        },
                    }
                )
            );
        }
        ev.stopPropagation();
        setAnchorEl(null);
    };

    React.useEffect(() => {
        if (salon_id) {
            dispatch(getClientInfoRequest({ id: salon_id, user_id: user_id }));
            dispatch(getEventsHistoryRequest({ id: salon_id, client_ids: [user_id] }));
        }
    }, [dispatch, salon_id, user_id]);

    const {
        first_name = ' ',
        last_name = ' ',
        phone,
        avatar,
        rating,
        user_events_count,
        income,
        city,
        updated_at,
        feedback_count,
        gender,
        date_of_birth,
        reference_category_id,
    } = client as any;

    React.useEffect(() => {
        if (salon_id && reference_category_id) {
            dispatch(
                getSingleReferenceCategoryRequest({
                    id: salon_id,
                    categoryId: reference_category_id,
                })
            );
        }
    }, [dispatch, salon_id, reference_category_id]);

    return (
        <PopupBackground
            onSubmit={() => {}}
            onCancel={() => {
                onCancel();
            }}
            onClear={() => {
                onClose();
            }}
            showPopup={isShowPopup}
            showClear
            visible
            showCancel={false}
            showSubmit={false}
            childrenContainerClassName={style.dataContainer}
            popupBackgroundsProps={{ onClick: onClose }}
            clearButtonClassName={style.clearButton}
        >
            <Skeleton
                nextButtonText={t('client_detail_appointment_for_the_client')}
                subTitle={
                    (
                        <>
                            <span className={style.subtitle}>
                                {t('client_detail_added')}{' '}
                                <span className={style.info}>
                                    {moment(updated_at)
                                        .locale(lang)
                                        .format('MMMM YYYY')}
                                </span>
                            </span>
                            {gender && (
                                <span className={style.subtitle} style={{ marginLeft: '40px' }}>
                                    {t('generic_gender')}
                                    {': '}
                                    <span className={style.info}>
                                        {' '}
                                        {gender === 'f'
                                            ? t('generic_gender_female')
                                            : t('generic_gender_male')}
                                    </span>
                                </span>
                            )}
                        </>
                    ) as any
                }
                classes={{ titleContainer: style.title, children: style.children }}
                showTitle
                title={`${first_name} ${last_name ? last_name : ''}`}
                bottomPositionButtons={false}
                headerChildren={
                    <React.Fragment>
                        <div className={style.buttonContainer}>
                            <Button
                                classes={{ root: classes.root }}
                                onClick={handleClick}
                                variant="outlined"
                                color="primary"
                            >
                                <MoreHorizIcon className={classes.icon} />
                            </Button>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={ev => {
                                    handleClose(ev, undefined);
                                }}
                            >
                                <MenuItem onClick={(ev: any) => handleClose(ev, 0)}>
                                    {t('client_detail_to_block_the_client') as string}
                                </MenuItem>
                            </Menu>
                            <Button
                                onClick={() => {
                                    dispatch(openChat(user_id));
                                }}
                                variant="contained"
                                color="primary"
                            >
                                {t('client_detail_text')}
                            </Button>
                        </div>
                    </React.Fragment>
                }
            >
                <Grid container>
                    <Grid item xs={12}>
                        <div className={style.infoContainer}>
                            <Avatar
                                src={avatar}
                                className={classes.avatar}
                            >{`${first_name[0].toUpperCase()} ${
                                last_name ? last_name[0].toUpperCase() : ''
                            }`}</Avatar>
                            <div className={style.info}>
                                <div className={style.phone}>
                                    <Phone className={style.icon} htmlColor={color} /> +{phone}
                                </div>
                                <div>
                                    <RatingLocation
                                        user_rating={rating}
                                        master_feedbacks_count={feedback_count}
                                        city={city}
                                    />
                                </div>
                                <div className={style.referenceContainer}>
                                    {date_of_birth && (
                                        <div className={style.placeholder}>
                                            <CalendarTodayIcon
                                                className={style.icon}
                                                htmlColor={color}
                                            />{' '}
                                            {moment(date_of_birth)
                                                .locale(lang)
                                                .format('DD MMM YYYY')}
                                        </div>
                                    )}
                                    {referenceCategories && referenceCategories.name && (
                                        <div className={style.placeholder}>
                                            <CommentIcon className={style.icon} htmlColor={color} />
                                            <span>
                                                {t('generic_reference')}
                                                {': '}
                                            </span>
                                            <span>{referenceCategories.name}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div style={{ display: 'flex', gap: '15px' }}>
                            <div style={{ width: '50%' }}>
                                <div className={style.ordersInfo}>
                                    <span>
                                        {t('client_detail_client_appointments')}{' '}
                                        <span className={style.count}>{user_events_count}</span>
                                    </span>
                                    <span>
                                        {t('client_detail_Services_provided_for')}{' '}
                                        <span className={style.count}>
                                            <NumberFormat value={income} />
                                        </span>
                                    </span>
                                </div>
                                {events.length > 0 ? (
                                    <div className={style.eventsHistory}>
                                        {events.map((item: any) => (
                                            <EventItem
                                                showMaster
                                                key={item.id}
                                                data={item}
                                                isDisableClick
                                            />
                                        ))}
                                    </div>
                                ) : (
                                    <div className={style.placeHolderContainer}>
                                        <div className={style.placeHolder}>
                                            {t('client_detail_customer_appointment_history')}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div style={{ width: '50%' }}>
                                <ClientNotes user_id={user_id} salon_id={salon_id} />
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Skeleton>
        </PopupBackground>
    );
};

export default ClientDetailsPopup;
