import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';
import { getSubscriptionSessionSelector } from 'modules/subscription';
import style from './style.scss';

const stripeKey =
    process.env.NODE_ENV === 'development' ||
    window.location.origin === 'https://salondev.feelqueen.by'
        ? 'pk_test_51LMHxOGJXL9aL3neL5jBEbkK0c7LSKHE65JYnVkHEsrV5abkc0rzVfixjB56rHgc1u84keO3LJITwsYgtoDTpZW700D6FCIpJe'
        : 'pk_live_51LMHxOGJXL9aL3neyrjWqtV1I3nLzFszJhyVApZNxFtzvdseElMREfXGt9QilsNnMboohgFb0Dlwukg5iWSz76ws00eHjOordD';

const stripePromise = loadStripe(stripeKey, {});

export const Subscription = () => {
    const { client_secret, id } = useSelector(getSubscriptionSessionSelector);
    const options = client_secret
        ? {
              clientSecret: client_secret,
          }
        : {};

    if (id) {
        sessionStorage.setItem('subscriptionID', id);
    }

    if (!client_secret || !id) return null;

    return (
        <div className={style.container}>
            <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
                <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
        </div>
    );
};
