import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { InfoCard, NumberFormat } from 'components';
import { useTranslation } from 'react-i18next';
import { getSystemLang } from 'helpers/getSystemLang';
import style from './style.scss';
import SubscriptionPopup from 'components/SubscriptionPopup';
import DoneIcon from '@material-ui/icons/Done';

const Subscription = ({
    history,
    id,
    subscriptionStatus,
    subscriptionEndsAt,
    subscriptionTrialEndsAt,
}) => {
    const { t } = useTranslation();
    const lang = getSystemLang();
    const isSubscriptionNotActive = !(
        subscriptionStatus === 'trialing' || subscriptionStatus === 'active'
    );
    const [isShowSubscriptionPopup, setShowSubscriptionPopup] = useState(false);

    return (
        <>
            <InfoCard title={t('d_subscription_section_title')} minHeight="377px" showMenu={false}>
                <Grid item xs={12} className={style.dataContainerSmall}>
                    <div className={style.container}>
                        <div className={style.details}>
                            <>
                                {isSubscriptionNotActive ? (
                                    <div className={style.amount}>
                                        <NumberFormat value="149" />
                                        <span>{t('d_subscription_section_period')}</span>
                                    </div>
                                ) : (
                                    <>
                                        <div className={style.status}>
                                            <DoneIcon htmlColor="#60AF6E" color="success" />
                                            <span>
                                                {t(
                                                    subscriptionStatus === 'trialing'
                                                        ? 'd_subscription_section_subscription_trial'
                                                        : 'd_subscription_section_subscription_active'
                                                )}
                                            </span>
                                            <span>
                                                {moment(
                                                    subscriptionStatus === 'trialing'
                                                        ? subscriptionTrialEndsAt
                                                        : subscriptionEndsAt
                                                )
                                                    .locale(lang)
                                                    .format('DD MMMM YYYY')}
                                            </span>
                                        </div>

                                        <div className={style.info}>
                                            {t('d_subscription_section_description')}
                                        </div>
                                    </>
                                )}
                            </>
                        </div>
                        {isSubscriptionNotActive && (
                            <Button
                                className={style.button}
                                color="primary"
                                variant="contained"
                                onClick={() => setShowSubscriptionPopup(true)}
                            >
                                {t('d_subscription_section_purchase_button')}
                            </Button>
                        )}
                    </div>
                </Grid>
            </InfoCard>
            <SubscriptionPopup
                showPopup={isShowSubscriptionPopup}
                id={id}
                onClose={() => setShowSubscriptionPopup(false)}
                history={history}
            />
        </>
    );
};

Subscription.propTypes = {
    data: PropTypes.object,
    paymentsData: PropTypes.object,
    id: PropTypes.string,
};
Subscription.defaultProps = {};

export default Subscription;
