import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import get from 'lodash/get';
import { InfoCard, InfoCardLine } from 'components';
import { useTranslation } from 'react-i18next';
import style from './style.scss';

const Orders = ({ data }) => {
    const {
        avg_occupancy,
        cancelled_events_count,
        completed_events_count,
        events_count,
        partner_problem_events_count,
        users_created_events_count,
    } = data;
    const { t } = useTranslation();

    return (
        <InfoCard title={t('analytics_appointments')} showMenu={false}>
            <Grid item xs={12} className={style.dataContainerSmall}>
                <InfoCardLine title={t('analytics_total')} value={events_count} />
                <InfoCardLine title={t('analytics_completed')} value={completed_events_count} />
                <InfoCardLine
                    title={t('analytics_created_by_the_clients')}
                    value={users_created_events_count}
                />
                <InfoCardLine title={t('analytics_canceled')} value={cancelled_events_count} />
                <InfoCardLine
                    title={t('analytics_clients_no_come')}
                    value={partner_problem_events_count}
                />
                <InfoCardLine
                    title={t('analytics_average_occupancy')}
                    value={((avg_occupancy || 0) * 100).toFixed(1) + '%'}
                />
            </Grid>
        </InfoCard>
    );
};

Orders.propTypes = {
    topServices: PropTypes.object,
    currencyCurrent: PropTypes.object,
};
Orders.defaultProps = {
    topServices: [],
};

export default Orders;
