import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Popup from '../Popup';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import SkillsSelect from './SkillSelect';
import { useTranslation } from 'react-i18next';
import style from './style.module.scss';
import { CUSTOM_SKILLS_PARENT_TITLE_MAP } from 'constants/skills';

const color = '#fa835f';

const MasterSkillPopup = ({
    onSubmit,
    skills,
    onCancel,
    onClose,
    showPopup,
    title = 'Выберите услуги для комлекса',
    customSkills,
    forceExpand,
    onAddCustomSkills,
    showSelectAll,
    showSelectedItemsCount,
    skillsSelectProps,
    popupProps,
    selectedCustom,
    selected,
}) => {
    const [search, setSearch] = React.useState();
    const [selectedSkillsState, setSelectedSkills] = React.useState([...selected]);
    const [selectedCustomSkillsState, setSelectedCustomSkills] = React.useState([
        ...selectedCustom,
    ]);
    const { t } = useTranslation();

    useEffect(() => {
        setSelectedSkills(selected);
    }, [selected]);
    useEffect(() => {
        setSelectedCustomSkills(selectedCustom);
    }, [selectedCustom]);

    const preparedSkills = React.useMemo(() => {
        const uniqueSkills = customSkills
            .filter(elem => !!elem.parent_uid)
            .map(elem => {
                return {
                    ...elem,
                    is_unique_custom_service: true,
                };
            });

        const uniqueParent = [
            // eslint-disable-next-line no-undef
            ...new Set(
                uniqueSkills
                    .filter(
                        elem => elem.parent_uid && !skills.some(val => val.uid === elem.parent_uid)
                    )
                    .map(val => val.parent_uid)
            ),
        ];

        const fullSkillList = [
            ...skills.map(element => {
                return {
                    ...element,
                    sub_skills: [
                        ...element.sub_skills,
                        ...uniqueSkills.filter(value => value.parent_uid === element.uid),
                    ],
                };
            }),
            ...uniqueParent
                .filter(elem => CUSTOM_SKILLS_PARENT_TITLE_MAP.has(elem))
                .map(elem => {
                    return {
                        uid: elem,
                        sub_skills: uniqueSkills.filter(value => value.parent_uid === elem),
                        parent_skill_id: null,
                        parent_uid: null,
                        priority: 0,
                        title: `${elem[0].toUpperCase()}${elem.slice(1)} `,
                        description: `${elem[0].toUpperCase()}${elem.slice(1)} `,
                        id: CUSTOM_SKILLS_PARENT_TITLE_MAP.get(elem),
                    };
                }),
        ];

        return fullSkillList;
    }, [skills, customSkills]);

    const preparedCustomSkills = React.useMemo(() => {
        return customSkills.filter(elem => !elem.parent_uid);
    }, [customSkills]);

    const onChangeSkill = arr => {
        const skillsArr = arr.filter(elem => !elem.is_unique_custom_service);
        const customSkillsArr = arr.filter(elem => elem.is_unique_custom_service);
        setSelectedSkills(skillsArr);
        setSelectedCustomSkills(customSkillsArr);
    };

    return (
        <Popup
            showClear
            title={t(title)}
            onClear={() => {
                onClose();
            }}
            onSubmit={() => onSubmit(selectedSkillsState, selectedCustomSkillsState)}
            onCancel={() => {
                onCancel();
                setSelectedSkills([]);
                setSelectedCustomSkills([]);
            }}
            showSubmit
            showCancel
            showPopup={showPopup}
            visible
            cancelButtonText={t('generic_clear')}
            submitButtonText={t('custom_skill_Save_Add')}
            childrenContainerClassName={style.dataContainer}
            popupBackgroundsProps={{ onClick: onClose }}
            textInfo={`${t('services_services_selected')} ${selectedSkillsState.length +
                selectedCustomSkillsState.length}`}
            {...popupProps}
        >
            <div className={style.topBlock}>
                <TextField
                    className={style.searchInput}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon htmlColor={color} />
                            </InputAdornment>
                        ),
                    }}
                    required
                    value={search}
                    onChange={ev => setSearch(ev.target.value)}
                    fullWidth
                    size="small"
                    variant="outlined"
                    placeholder={t('services_name_of_the_service')}
                />
            </div>

            <div className={style.container}>
                <SkillsSelect
                    selectedCustom={selectedCustomSkillsState}
                    forceExpand={forceExpand}
                    selected={[...selectedSkillsState, ...selectedCustomSkillsState]}
                    setSelected={onChangeSkill}
                    setSelectedCustom={setSelectedCustomSkills}
                    showSelectAll={showSelectAll}
                    showSelectedItemsCount={showSelectedItemsCount}
                    selectedSkills={selectedSkillsState}
                    onChange={data => {
                        setSelectedSkills(data);
                    }}
                    showInputs={false}
                    skills={preparedSkills}
                    searchText={search}
                    showCustomSkill={false}
                    customSkills={preparedCustomSkills}
                    selectedCustomSkills={selectedCustomSkillsState}
                    onChangeCustomSkills={setSelectedCustomSkills}
                    addCustomSkills={onAddCustomSkills}
                    {...skillsSelectProps}
                />
            </div>
        </Popup>
    );
};

MasterSkillPopup.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    selectedSkills: PropTypes.array,
    skills: PropTypes.array,
    showPopup: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string,
    customSkills: PropTypes.array,
    forceExpand: PropTypes.bool,
    selectedCustomSkills: PropTypes.array,
    onAddCustomSkills: PropTypes.func,
    showSelectAll: PropTypes.bool,
    showSelectedItemsCount: PropTypes.bool,
    skillsSelectProps: PropTypes.object,
    popupProps: PropTypes.object,
    selectedCustom: PropTypes.array,
    selected: PropTypes.array,
    setSelected: PropTypes.func,
    setSelectedCustom: PropTypes.func,
};
MasterSkillPopup.defaultProps = {
    selectedCustomSkills: [],
    forceExpand: false,
    onAddCustomSkills: () => {},
    showPopup: false,
    selectedSkills: [],
    title: 'test',
    onSubmit: () => {},
    onCancel: () => {},
    onClose: () => {},
    showSelectAll: false,
    showSelectedItemsCount: false,
    skillsSelectProps: {},
    popupProps: {},
    selectedCustom: [],
    selected: [],
    setSelected: () => {},
    setSelectedCustom: () => {},
};

export default MasterSkillPopup;
