import { all, put, select, takeLatest } from 'redux-saga/effects';
import get from 'lodash/get';
import { INIT_DATA } from 'modules/init';
import { getCurrentUserCurrency, SAVE_CURRENT_USER } from 'modules/currentUser';
import { createAction } from 'redux-actions';
import * as api_helpers from 'api';
import {
    getCountryByCode,
    getCoubtryByCurrency,
    getCountryByLanguage,
    getDefaultCityByCode,
} from './localizeData';

const {
    helpers: { actionCreator, apiSelector },
    modules: { ApiRoutes },
} = api_helpers;
const apiRoutes = new ApiRoutes();
const modules = 'localization';

export const SAVE_CURRENT_LOCALIZATION = `${modules}/SAVE_CURRENT_LOCALIZATION`;
export const SAVE_CURRENT_CITY = `${modules}/SAVE_CURRENT_CITY`;
export const GET_CURRENCY_REQUEST = `${modules}/GET_CURRENCY_REQUEST`;
const saveLocalization = createAction(SAVE_CURRENT_LOCALIZATION);
export const saveCity = createAction(SAVE_CURRENT_CITY);
export const getCurrencyRequest = actionCreator(GET_CURRENCY_REQUEST);

apiRoutes.add(GET_CURRENCY_REQUEST, () => ({
    url: `api/v2/currency`,
    method: 'get',
}));

export const getExt = window => {
    return get(window, 'location.host', '')
        .split('.')
        .pop();
};
const getSystemLang = () => {
    const host = get(window, 'location.host', '');

    if (host.includes('localhost') || host.includes('test')) {
        return window && window.navigator && window.navigator.language
            ? window.navigator.language.substring(0, 2)
            : 'en';
    } else {
        if (host.includes('co.uk')) {
            return 'en';
        } else if (host.includes('by') || host.includes('ru')) {
            return 'ru';
        } else return 'en';
    }
};

const getCurrentCurrency = (ext, currency) => {
    const lang = getSystemLang();
    const country =
        getCoubtryByCurrency(currency) ||
        getCountryByCode(ext) ||
        getCountryByCode('ae') ||
        getCountryByLanguage(lang); // USE as default
    return country;
};

const defaultState = { ...getCurrentCurrency(getExt(window)) };
export const localizationReducer = (state = defaultState, action = {}) => {
    const { payload, type } = action;
    switch (type) {
        case SAVE_CURRENT_LOCALIZATION: {
            return {
                ...state,
                ...getCurrentCurrency(getExt(window), payload),
            };
        }
        case SAVE_CURRENT_CITY: {
            return {
                ...state,
                ...payload,
            };
        }

        default:
            return state;
    }
};
const saveLocalizationSaga = function*() {
    const country = yield select(getCurrentUserCurrency);

    yield put(saveLocalization(country));
};

// const getCurrencySaga = function*() {
//     // yield put(getCurrencyRequest());
// };

export const localizationModuleSaga = function*(dispatch) {
    yield all([
        takeLatest([INIT_DATA, SAVE_CURRENT_USER], saveLocalizationSaga, dispatch),
        // takeLatest([INIT_DATA], getCurrencySaga, dispatch),
        //takeLatest([INIT_DATA], getCity, dispatch),
    ]);
};
export const getCurrencySelector = apiSelector(GET_CURRENCY_REQUEST);
export const getLocalizationSelector = state => {
    return get(state, 'localization');
};

export { getDefaultCityByCode };
