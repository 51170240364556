import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const Comments = ({
    setFieldValue,
    values,
    disabledClientsComment = false,
    disabledComment = false,
}: {
    setFieldValue: (field: string, value: any) => void;
    values: Record<string, any>;
    disabledClientsComment?: boolean;
    disabledComment?: boolean;
}) => {
    const { t } = useTranslation();
    const comment = values['internal_comment'];
    const clientComment = values['commen'];
    const setComment = (value: string) => {
        setFieldValue('internal_comment', value);
    };
    const setClientComment = (value: string) => {
        setFieldValue('comment', value);
    };

    return (
        <Grid container spacing={6}>
            <Grid item xs={8}>
                <TextField
                    fullWidth
                    value={comment}
                    id="filled-multiline-static"
                    label={t('event_Comment_for_staff')}
                    multiline
                    placeholder={t('event_visible_to_staff')}
                    onChange={ev => setComment(ev.target.value)}
                    rowsMax={4}
                    defaultValue=""
                    disabled={disabledComment}
                    InputLabelProps={{ shrink: true }}
                />
            </Grid>
            <Grid item xs={8}>
                <TextField
                    fullWidth
                    value={clientComment}
                    id="filled-multiline-static"
                    label={t('event_client_comment')}
                    multiline
                    onChange={ev => setClientComment(ev.target.value)}
                    rowsMax={4}
                    defaultValue=""
                    InputLabelProps={{ shrink: true }}
                    disabled={disabledClientsComment}
                />
            </Grid>
        </Grid>
    );
};

export default Comments;
