import React from 'react';
import Item from './Item';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { DataInterface } from './SubItem';
import { ItemDataInterFace } from './Item';
import { uniq } from 'lodash';

export interface SkillItemInterface {
    id: string | number;
    title: string;
    sub_skills: DataInterface[];
    uid: string;
}

interface SkillSelectProps {
    skills: ItemDataInterFace[];
    searchText: string;
    showInputs: boolean;
    onChangeCustomSkills: (data: any) => void;
    onChange: (data: any) => void;
    showOnlySkills: (string | number)[] | null | false | undefined; //
    customSkills: DataInterface[];
    itemComponentCustomSubSkill: any;
    itemComponentSubSkill: any;
    forceExpand: boolean;
    defaultExpand: boolean;
    showSelectAll: boolean;
    showSelectedItemsCount: boolean;
    showCustomSkillsCount: boolean;
    onFilter: (data: any) => void;
    itemProps?: Record<string, any>;
    subItemProps?: (data: any) => Record<string, any>;
    selectedCustom: any[];
    selected: any[];
    setSelected: (data: any) => void;
    setSelectedCustom: (data: any) => void;
    showPrice: boolean;
    showDuration: boolean;
}

/**
 * SkillSelect Component
 *
 * @param {SkillItemInterface[]} skills - List of skills grouped by category.
 * @param {string} searchText - Search text for filtering skills.
 * @param {boolean} [showInputs=true] - If true, inputs for skills (price and duration) are shown.
 * @param {Function} onChangeCustomSkills - Function to handle changes in custom skills.
 * @param {Function} onChange - Function to handle changes.
 * @param {number[]} showOnlySkills - Array of skill IDs to show only these skills.
 * @param {CustomSkillInterface[]} [customSkills=[]] - List of custom skills.
 * @param {ReactNode} itemComponentCustomSubSkill - Component for custom skills.
 * @param {ReactNode} itemComponentSubSkill - Component for skills.
 * @param {boolean} forceExpand - If true, skill groups are forcibly expanded.
 * @param {boolean} defaultExpand - If true, all skill groups are expanded by default.
 * @param {boolean} [showSelectAll=false] - If true, a select all checkbox is shown.
 * @param {boolean} [showSelectedItemsCount=false] - If true, the count of selected skills is shown.
 * @param {boolean} [showCustomSkillsCount=false] - If true, the count of selected custom skills is shown.
 * @param {Function} onFilter - Function to handle filtering.
 * @param {any} itemProps - Props for the item component.
 * @param {any} subItemProps - Props for the sub item component.
 * @param {any[]} selectedCustom - Selected custom skills.
 * @param {any[]} selected - Selected skills.
 * @param {Function} setSelected - Function to set selected skills.
 * @param {Function} setSelectedCustom - Function to set selected custom skills.
 * @param {boolean} showPrice - If true, the price for subskills is shown.
 * @param {boolean} showDuration - If true, the duration for subskills is shown.
 */

const SkillSelect = ({
    skills, // list of  skills grouped by category
    searchText, // search text (filter)
    showInputs = true, // show inputs for skills (price and duration)
    onChangeCustomSkills, // function to handle changes in custom skills
    onChange, // function to handle changes
    showOnlySkills, // show only skills from this list  [arr of ids]
    customSkills = [], // list of custom skills
    itemComponentCustomSubSkill, // component for custom skills (if we will not pass this component - we will use default component)
    itemComponentSubSkill, // component for skills (if we will not pass this component - we will use default component)
    forceExpand, // force expand groups of skills (category) (can't collapse)
    defaultExpand, // default expand groups of skills (category) - if true - all groups will be expanded
    showSelectAll = false, // show select all checkbox
    showSelectedItemsCount = false, // show count of selected skills
    showCustomSkillsCount = false, // show count of selected custom skills
    onFilter, // function to handle filtering
    itemProps, // props for item
    subItemProps, // props for sub item
    selectedCustom, // selected custom skills
    setSelectedCustom, // function to set selected custom skills
    selected, // selected skills
    setSelected, // function to set selected skills
    showPrice, // show price for subskills
    showDuration, // show duration for subskills
}: SkillSelectProps) => {
    const { t } = useTranslation();

    // filtred skills by searchText or showOnlySkills (if we have searchText - we will show only skills that match searchText)
    // if we have showOnlySkills - we will show only skills from this list
    const skillsFiltred = React.useMemo(() => {
        return showOnlySkills
            ? skills.reduce((acc: SkillItemInterface[], i: SkillItemInterface) => {
                  const filtredSubskills = i.sub_skills.filter(i => showOnlySkills.includes(i.id));

                  return filtredSubskills.length
                      ? [...acc, { ...i, sub_skills: filtredSubskills }]
                      : [...acc];
              }, [])
            : skills.reduce((acc: SkillItemInterface[], i: SkillItemInterface) => {
                  const filtredSubskills = i.sub_skills.filter(
                      i => i.title.toLowerCase().search(searchText.toLowerCase()) !== -1
                  );

                  return filtredSubskills.length
                      ? [...acc, { ...i, sub_skills: filtredSubskills }]
                      : [...acc];
              }, []);
    }, [searchText, showOnlySkills, skills]);

    // filtred custom skills by searchText
    const customSkillsFiltred = React.useMemo(() => {
        const skills =
            searchText.length > 0
                ? customSkills.filter(
                      i => i.title.toLowerCase().search(searchText.toLowerCase()) !== -1
                  )
                : customSkills;
        return skills.length > 0 // if we have any customskills - add custom category
            ? [
                  {
                      description: t('generic_unique_services'),
                      id: 'custom',
                      parent_skill_id: null,
                      parent_uid: null,
                      sub_skills: [...skills],
                      title: t('generic_unique_services'),
                      uid: 'customSkills',
                  },
              ]
            : [];
    }, [customSkills, searchText]);

    const [expanded, setExpanded] = React.useState(defaultExpand ? [...skills.map(i => i.id)] : []); // expanded groups of skills (category) (can collapse)
    const [expandedCustom, setExpandedCustom] = React.useState(
        // expanded groups of custom skills (category) (can collapse)
        defaultExpand ? [...customSkills.map(i => i.id), 'custom'] : []
    );

    React.useEffect(() => {
        onChange([...selected]); // onChange - function to handle changes
    }, [selected]);

    React.useEffect(() => {
        onChangeCustomSkills([...selectedCustom]); // onChangeCustomSkills - function to handle changes in custom skills
    }, [selectedCustom]);

    React.useEffect(() => {
        onFilter({
            // onFilter - function to handle filtering
            skillsFiltred,
            customSkillsFiltred,
        });
    }, [skillsFiltred, customSkillsFiltred]);

    return (
        <div>
            {(searchText || showOnlySkills ? skillsFiltred : skills).map(i => (
                <Item
                    showSelectAll={showSelectAll}
                    showInputs={showInputs}
                    key={i.id}
                    expanded={
                        searchText || showOnlySkills ? skillsFiltred.map(i => i.id) : expanded
                    }
                    setExpanded={setExpanded}
                    forceExpand={forceExpand}
                    selected={[
                        ...selected,
                        ...selectedCustom.filter(i => i?.is_unique_custom_service),
                    ]}
                    setSelected={data => {
                        const uniq = data.filter(i => i?.is_unique_custom_service);
                        const skill = data.filter(i => !i?.is_unique_custom_service);

                        setSelectedCustom(uniq);
                        setSelected(skill);
                    }}
                    showSelectedItemsCount={showSelectedItemsCount}
                    data={i}
                    itemComponent={itemComponentSubSkill}
                    subItemProps={subItemProps}
                    {...itemProps}
                    showDuration={showDuration}
                    showPrice={showPrice}
                />
            ))}
            {customSkills &&
                customSkillsFiltred.map(i => (
                    <Item
                        showSelectedItemsCount={showSelectedItemsCount}
                        showSelectAll={showSelectAll}
                        itemComponent={itemComponentCustomSubSkill}
                        showInputs={showInputs}
                        key={i.id}
                        expanded={
                            searchText || showOnlySkills
                                ? customSkillsFiltred.map(i => i.id)
                                : expandedCustom
                        }
                        forceExpand={forceExpand}
                        setExpanded={setExpandedCustom}
                        selected={selectedCustom}
                        setSelected={setSelectedCustom}
                        data={i}
                        count={
                            showCustomSkillsCount
                                ? get(customSkillsFiltred, '[0].sub_skills', []).length
                                : undefined
                        }
                        subItemProps={subItemProps}
                        {...itemProps}
                        showDuration={showDuration}
                        showPrice={showPrice}
                    />
                ))}
        </div>
    );
};
SkillSelect.defaultProps = {
    selectedCustom: [],
    selected: [],
    setSelected: () => {},
    setSelectedCustom: () => {},
    customSkills: [],
    onChange: () => {},
    searchText: '',
    showOnlySkills: false,
    itemComponentSubSkill: false,
    itemComponentCustomSubSkill: false,
    onChangeCustomSkills: () => {},
    forceExpand: false,
    skills: [],
    defaultExpand: false,
    showCustomSkillsCount: false,
    onFilter: () => {},
};

export default SkillSelect;
