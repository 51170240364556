import React from 'react';
import { Skeleton } from 'components';

import { useTranslation } from 'react-i18next';
import {
    Grid,
    Button,
    FormHelperText,
    FormControlLabel,
    RadioGroup,
    Radio,
} from '@material-ui/core';
import { get } from 'lodash';
import { Label } from 'components';
import { makeStyles } from '@material-ui/core/styles';
import { useEditeEvent } from './hooks';
import SelectKills from './components/SelectSkills';
import SelectClient from '../components/SelectClient';
import SelectMaster from '../components/SelectMaster';
import SelectDate from '../components/SelectDate';
import SelectDuration from '../components/SelectDuration';
import Comments from '../components/Comments';
import SubmitPanel from '../components/SubmitPanel';
import FeedbackItem from '../components/FeedbackItem';
import FeedbackPopup from '../components/FeedbackPopup';
import style from './style.scss';
import {
    CONFIRMED_STATUS,
    FINISHED_STATUS,
    CANCELLED_STATUS_BY_MASTER,
    CANCELLED_STATUS_BY_CLIENT,
    PAYMENT_CARD,
    PAYMENT_CASH,
} from 'constants/events';

const useStyles = makeStyles(() => ({
    button: {
        fontSize: '12px',
    },
    rootCheckbox: {
        marginTop: 24,
    },
}));

const Edit = ({ history, eventId }: { history: any; eventId: number | string }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const {
        handleSubmit,
        values,
        errors,
        setFieldValue,
        salonData,
        eventRanges,
        event,
        addFeedback,
        combinedName,
    } = useEditeEvent({
        history,
        eventId,
    });
    const [showFeedbackPopup, switchFeedbackPopup] = React.useState(false);

    const textError =
        Object.entries(errors).filter(i => i[1])?.length > 0 ? 'Fill in the required fields' : '';

    const status = values['status_id'];
    const disabledFeedback = Boolean(get(event, 'master_feedback'));
    const payment_type_id = values['payment_type_id'] || PAYMENT_CASH;

    const disabledChanges =
        status === FINISHED_STATUS ||
        status === CANCELLED_STATUS_BY_CLIENT ||
        status === CANCELLED_STATUS_BY_MASTER;

    const disabledChangeSkills = disabledChanges || (eventRanges.started && eventRanges.ended);
    const disabledChangeUser = status === CONFIRMED_STATUS || disabledChanges;
    const disabledChangeMaster = disabledChanges || eventRanges.started;
    const disabledChangeDate = eventRanges.started || disabledChanges;
    const disableChangeDuration =
        disabledChanges ||
        (eventRanges.started && eventRanges.ended) ||
        (values['skills']?.length === 0 && values['services']?.length === 0);
    const disableChangePrice = eventRanges.started || get(event, 'fee', 0) !== 0 || disabledChanges;
    const disableChangePaymentType =
        disabledChanges || (payment_type_id !== PAYMENT_CARD && payment_type_id !== PAYMENT_CASH);

    const title =
        status === FINISHED_STATUS
            ? t('event_completed_appointment')
            : status === CONFIRMED_STATUS
            ? t('event_upcoming_appointment')
            : t('event_cancelled_appointment');

    return (
        <Skeleton
            textError={textError}
            backgroundColor="#fffaf6"
            title={title}
            showBreadcump
            showTitle
            nextButtonText={false}
            subTitle=""
            bottomPositionButtons={false}
            breadcamps={[{ link: '/calendar', title: t('menu_calendar') }]}
            headerChildren={<></>}
            onNext={false}
        >
            <Grid container spacing={6}>
                <Grid item xs={7}>
                    <Grid container>
                        <Grid item xs={10}>
                            <SelectKills
                                errors={errors}
                                values={values}
                                setFieldValue={setFieldValue}
                                salonData={salonData}
                                disabled={disabledChangeSkills}
                                useCombinedChange
                                combinedName={combinedName}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={10}>
                            <SelectClient
                                errors={errors}
                                values={values}
                                setFieldValue={setFieldValue}
                                salonData={salonData}
                                name="user_id"
                                disabled={disabledChangeUser}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={10}>
                            <SelectMaster
                                errors={errors}
                                values={values}
                                setFieldValue={setFieldValue}
                                salonData={salonData}
                                disabled={disabledChangeMaster}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={10}>
                            <SelectDate
                                errors={errors}
                                values={values}
                                setFieldValue={setFieldValue}
                                salonData={salonData}
                                disabled={disabledChangeDate}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={10}>
                            <SelectDuration
                                errors={errors}
                                values={values}
                                setFieldValue={setFieldValue}
                                disabled={disableChangeDuration}
                            />
                        </Grid>
                    </Grid>
                    <Comments
                        disabledComment={disabledChanges}
                        values={values}
                        setFieldValue={setFieldValue}
                    />
                </Grid>
                <Grid item xs={5}>
                    <SubmitPanel
                        setFieldValue={setFieldValue}
                        values={values}
                        errors={errors}
                        salonData={salonData}
                        handleSubmit={handleSubmit}
                        disabled={disableChangePrice}
                        name={'amount'}
                    >
                        {(status === FINISHED_STATUS || status === CONFIRMED_STATUS) && (
                            <div className={style.labelPayment}>
                                <Label title={t('event_payment_method')}>
                                    <RadioGroup
                                        aria-label="gender"
                                        value={payment_type_id}
                                        onChange={ev => {
                                            setFieldValue(
                                                'payment_type_id',
                                                parseInt(ev.target.value)
                                            );
                                        }}
                                        classes={{ root: classes.rootCheckbox }}
                                        row
                                    >
                                        <FormControlLabel
                                            value={PAYMENT_CASH}
                                            control={
                                                <Radio
                                                    color="primary"
                                                    disabled={disableChangePaymentType}
                                                />
                                            }
                                            label={t('payment_type_cash')}
                                        />
                                        <FormControlLabel
                                            value={PAYMENT_CARD}
                                            control={
                                                <Radio
                                                    color="primary"
                                                    disabled={disableChangePaymentType}
                                                />
                                            }
                                            label={t('payment_type_card')}
                                        />
                                    </RadioGroup>
                                </Label>
                            </div>
                        )}
                        {FINISHED_STATUS === status && !get(event, 'master_feedback') && (
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={disabledFeedback}
                                onClick={() => switchFeedbackPopup(!showFeedbackPopup)}
                            >
                                {t('event_give_feedback')}
                            </Button>
                        )}
                        {FINISHED_STATUS === status && get(event, 'master_feedback') && (
                            <Label title={t('event_your_feedback')}>
                                <br />
                                <FeedbackItem
                                    data={{
                                        feedback: get(event, 'master_feedback.feedback'),
                                        rating: get(event, 'master_feedback.rating'),
                                    }}
                                />
                            </Label>
                        )}
                        {status === CONFIRMED_STATUS && (
                            <div>
                                <div>
                                    <Button
                                        classes={{ root: classes.button }}
                                        variant="contained"
                                        color="primary"
                                        onClick={e => {
                                            setFieldValue('status_id', FINISHED_STATUS);
                                            // @ts-ignore
                                            handleSubmit(e);
                                        }}
                                    >
                                        {t('event_complete_the_booking')}
                                    </Button>
                                    <Button
                                        classes={{ root: classes.button }}
                                        variant="outlined"
                                        color="primary"
                                        onClick={e => {
                                            setFieldValue('status_id', CANCELLED_STATUS_BY_MASTER);
                                            // @ts-ignore
                                            handleSubmit(e);
                                        }}
                                    >
                                        {t('generic_cancel')}
                                    </Button>
                                </div>
                            </div>
                        )}
                        <FormHelperText error>{textError}</FormHelperText>
                        {/* <span className={style.textInfo}> {textInfo}</span> */}
                    </SubmitPanel>
                </Grid>
            </Grid>
            {showFeedbackPopup && (
                <FeedbackPopup
                    onClose={() => {
                        switchFeedbackPopup(!showFeedbackPopup);
                    }}
                    onSubmit={({ feedback, rating }) => {
                        addFeedback({ feedback, rating });
                        switchFeedbackPopup(!showFeedbackPopup);
                    }}
                />
            )}
        </Skeleton>
    );
};

export default Edit;
