import { IconButton, Tooltip } from '@material-ui/core';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import style from './style.scss';
import moment from 'moment';
import { getSystemLang } from 'helpers/getSystemLang';
import { useEffect, useRef } from 'react';
import { TFunction } from 'react-i18next';

type TCommentMode = 'view' | 'edit' | 'add';

interface CommentItemProps {
    mode: TCommentMode;
    value?: string;
    onChange: (id: string, value: string) => void;
    date?: string;
    id: string;
    onSave: (id: string, value: string) => void;
    onEdit: (id: string, mode: 'edit' | 'view') => void;
    onUpdate: (id: string, value: string) => void;
    onDelete: (id: string) => void;
    tFunction: TFunction;
}

const CommentItem = ({
    mode,
    date,
    value,
    id,
    onChange,
    onSave,
    onEdit,
    onUpdate,
    onDelete,
    tFunction,
}: CommentItemProps) => {
    const lang = getSystemLang();
    const inputRef = useRef<HTMLTextAreaElement | null>(null);

    const handleEditClick = () => {
        onEdit(id, 'edit');
        setTimeout(() => {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }, 0);
    };

    const handleSaveClick = () => {
        if (mode === 'edit') {
            onUpdate(id, value || '');
        } else if (mode === 'add') {
            onSave('new', value || '');
        }
    };

    const handleDeleteClick = () => {
        if (mode === 'edit') {
            onDelete(id);
        } else if (mode === 'add') {
            onDelete('new');
        }
    };

    useEffect(() => {
        if (!inputRef.current) {
            return;
        }
        // Moving cursor to the end
        inputRef.current.selectionStart = inputRef.current.value.length;
        inputRef.current.selectionEnd = inputRef.current.value.length;
    }, [mode]);

    return (
        <div className={style.commentContainer}>
            <div className={style.commentHeader}>
                <div>
                    {date
                        ? moment(date)
                              .locale(lang)
                              .format('DD MMMM YYYY, HH:mm')
                        : ''}
                </div>
                <div className={style.iconsContainer}>
                    {mode === 'view' && (
                        <Tooltip title={tFunction('generic_edit')} placement="bottom">
                            <IconButton color="primary" size="small" onClick={handleEditClick}>
                                <EditOutlinedIcon className={style.icon} />
                            </IconButton>
                        </Tooltip>
                    )}
                    {mode === 'edit' && (
                        <>
                            <Tooltip title={tFunction('generic_cancel')} placement="bottom">
                                <IconButton
                                    color="primary"
                                    size="small"
                                    onClick={() => onEdit(id, 'view')}
                                >
                                    <CancelOutlinedIcon className={style.icon} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={tFunction('generic_save')} placement="bottom">
                                <IconButton color="primary" size="small" onClick={handleSaveClick}>
                                    <SaveOutlinedIcon className={style.icon} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={tFunction('generic_delete')} placement="bottom">
                                <IconButton
                                    color="primary"
                                    size="small"
                                    onClick={() => onDelete(id)}
                                >
                                    <DeleteForeverOutlinedIcon className={style.icon} />
                                </IconButton>
                            </Tooltip>
                        </>
                    )}
                    {mode === 'add' && (
                        <>
                            <Tooltip title={tFunction('generic_save')} placement="bottom">
                                <IconButton color="primary" size="small" onClick={handleSaveClick}>
                                    <SaveOutlinedIcon className={style.icon} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={tFunction('generic_delete')} placement="bottom">
                                <IconButton
                                    color="primary"
                                    size="small"
                                    onClick={handleDeleteClick}
                                >
                                    <DeleteForeverOutlinedIcon className={style.icon} />
                                </IconButton>
                            </Tooltip>
                        </>
                    )}
                </div>
            </div>
            {mode === 'view' ? (
                <div className={style.text}>{value}</div>
            ) : (
                <textarea
                    rows={4}
                    value={value || ''}
                    onChange={event => onChange(id, event.target.value)}
                    className={style.textarea}
                    ref={inputRef}
                    autoFocus
                />
            )}
        </div>
    );
};
export default CommentItem;
