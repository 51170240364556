import moment, { utc } from 'moment';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { getDataFromCurrentLocarion } from 'helpers/url';
import { getSalonSelector } from 'modules/salon';
import { SalonInterface } from 'modules/salon/interface';
import { createEventRequest, addEventDateAction } from 'modules/events';
import { addClientNoteRequest } from 'modules/clients';

import * as yup from 'yup';

const validationSchema = (t: (x: any) => any) =>
    yup.object({
        date: yup.string().required(t('search_choose_a_date')),
        client_id: yup.string().required(t('event_select_a_client')),
        master_id: yup.number().required(t('search_choose_a_beautician')),
        skills: yup
            .array()
            .of(yup.string())
            .test('skills-or-services', t('event_select_at_least_one_service'), function(
                skills: any
            ) {
                const { services } = this.parent;
                return skills.length > 0 || services.length > 0;
            }),
        services: yup.array().of(yup.string()),
    });

interface ValueInterface {
    client_id: string | number;
    master_id: string | number;
    date: string | undefined;
    duration: number;
    skills: any[];
    services: any[];
    internal_comment: string;
    comment: string;
    amount: number;
}

const initialValues = {
    client_id: '',
    master_id: '',
    date: undefined,
    amount: 0,
    duration: 15,
    skills: [],
    services: [],
    internal_comment: '',
    comment: '',
} as ValueInterface;

export const useCreateEvent = ({
    history,
}: {
    history: any;
}): {
    handleChange: (e: React.ChangeEvent<any>) => void;
    handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
    values: any;
    errors: any;
    touched: any;
    setFieldValue: (field: string, value: any) => void;
    salonData: SalonInterface;
} => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const salonData = useSelector<(state: any) => SalonInterface, SalonInterface>(getSalonSelector);

    const {
        master,
        date,
    }: {
        master?: number | string;
        date?: string;
    } = getDataFromCurrentLocarion();

    const {
        handleChange,
        handleSubmit,
        values,
        errors,
        touched,
        setFieldValue,
        setTouched,
        setFieldTouched,
        isValid,
        validateForm,
    } = useFormik({
        initialValues: {
            ...initialValues,
        },
        validationSchema: validationSchema(t),
        enableReinitialize: false,
        isInitialValid: true,
        onSubmit: values => {
            const {
                client_id,
                amount,
                duration,
                skills,
                services,
                master_id,
                date,
                internal_comment,
                comment,
            } = values;

            dispatch(
                createEventRequest(
                    {
                        client_id: client_id,
                        master_id: master_id,
                        date: moment(date).format('YYYY-MM-DD HH:mm'),
                        utc_date: moment(date)
                            .subtract(moment(new Date()).utcOffset(), 'minutes')
                            .format('YYYY-MM-DD HH:mm'),
                        amount: amount,
                        duration,
                        skills: skills,
                        services: services,
                        internal_comment: internal_comment,
                        comment: comment,
                    },
                    {
                        onSuccess: () => {
                            // add event date to store to show it in calendar
                            dispatch(addEventDateAction(moment(date).format('YYYY-MM-DD HH:mm')));
                            dispatch(
                                addClientNoteRequest({
                                    clientId: client_id,
                                    note: internal_comment,
                                })
                            );
                            history?.push('/calendar');
                        },
                    }
                )
            );
            return;
        },
    });

    const errorsTouched = React.useMemo(() => {
        return Object.keys(errors).reduce(
            (acc, key: string) => ({
                ...acc,
                // @ts-ignore
                [key]: touched[key] ? errors[key] : '',
            }),
            {}
        );
    }, [errors, touched]);

    const setFieldValueAndTouched = React.useCallback(
        (field: string, value: any, shouldValidate = true) => {
            setFieldValue(field, value);
            setFieldTouched(field, true, shouldValidate);
            validateForm();
        },
        [setFieldValue, setTouched]
    );

    React.useEffect(() => {
        if (master && !values?.master_id) {
            setFieldValue('master_id', master);
        }
        if (date && !values?.date && moment(date).isValid()) {
            setFieldValue('date', date);
        }
        validateForm();
    }, [master, date, values, setFieldValue]);

    const handleSubmitWithToucheAll = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setTouched(
            {
                client_id: true,
                master_id: true,
                date: true,
                skills: true,
                services: true,
            },
            true
        );
        if (isValid) {
            handleSubmit(e);
        }
    };

    // console.log('values', values);

    return {
        handleChange,
        handleSubmit: handleSubmitWithToucheAll,
        values,
        errors: errorsTouched,
        touched,
        setFieldValue: setFieldValueAndTouched,
        salonData,
    };
};
