import get from 'lodash/get';

export const getTermsLink = () => {
    const host = get(window, 'location.host', '');
    if (host.includes('io')) {
        return 'https://feelbeauty.io/terms';
    } else if (host.includes('co.uk')) {
        return 'https://info.feelqueen.co.uk/terms_uk';
    } else if (host.includes('by') || host.includes('ru')) {
        return 'https://info.feelqueen.by/terms';
    } else return 'https://feelbeauty.io/terms';
};
export const getPrivacyLink = () => {
    const host = get(window, 'location.host', '');
    if (host.includes('io')) {
        return 'https://feelbeauty.io/privacy';
    } else if (host.includes('co.uk')) {
        return 'https://info.feelqueen.co.uk/privacy_uk';
    } else if (host.includes('by') || host.includes('ru')) {
        return 'https://info.feelqueen.by/privacy';
    } else return 'https://feelbeauty.io/privacy';
};
export const getAuthLink = () => {
    const host = get(window, 'location.host', '');
    if (host.includes('io')) {
        return 'https://feelbeauty.io/auth';
    } else if (host.includes('co.uk')) {
        return 'https://feelqueen.co.uk/auth';
    } else if (host.includes('by') || host.includes('ru')) {
        return 'https://feelqueen.by/auth';
    } else return 'https://feelbeauty.io/auth';
};
