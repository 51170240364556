/*!

=========================================================
* Material Dashboard React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { drawerWidth, transition, container } from 'assets/jss/material-dashboard-react';

const appStyle = theme => ({
    wrapper: {
        position: 'relative',
        top: '0',
        height: '100vh',
    },
    mainPanel: {
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
        },
        overflowY: 'auto',
        overflowX: 'hidden',
        position: 'relative',
        float: 'right',
        ...transition,
        maxHeight: '100%',
        width: '100%',
        overflowScrolling: 'touch',
    },
    content: {
        // marginTop: '70px',
        // padding: "30px 15px",
        minHeight: 'calc(100vh - 123px)',
    },
    container,
    map: {
        marginTop: '70px',
    },
});

export default appStyle;
