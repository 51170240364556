import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { InfoCard, NumberFormat } from 'components';
import { useTranslation } from 'react-i18next';
import { getSystemLang } from 'helpers/getSystemLang';
import style from './style.scss';

const INACTIVE = 'inactive';
const MyPayments = ({ data, paymentsData, onClick }) => {
    const { amount } = data;
    const { t } = useTranslation();
    const lang = getSystemLang();
    const { document_status } = paymentsData;
    let amountNum = parseFloat(amount || 0);
    amountNum = amountNum > 0 ? amountNum : amountNum;

    return (
        <InfoCard title={t('payment_payable_fees')} minHeight="377px" showMenu={false}>
            <Grid item xs={12} className={style.dataContainerSmall}>
                <div className={style.container}>
                    <div className={style.details}>
                        <div
                            className={style.amount}
                            style={{
                                color:
                                    amountNum > 0 ? '#F60303' : amount == 0 ? '#767676' : '#F60303',
                            }}
                        >
                            <NumberFormat value={`${amountNum}`} />
                        </div>
                        {document_status === INACTIVE ? (
                            <div className={style.info}>
                                {t("payment_fill_in_your_organisation's_details")}
                            </div>
                        ) : (
                            <>
                                <div className={style.info}>{`${t(
                                    'payment_feelqueen_fee_for'
                                )} ${moment().format('MMM YYYY')}`}</div>
                                <div className={style.info}>{`${t(
                                    'payment_It_will_automatically'
                                )} ${moment()
                                    .startOf('month')
                                    .add(1, 'month')
                                    .format('DD MMM YYYY')}`}</div>
                            </>
                        )}
                    </div>
                    <Button
                        onClick={onClick}
                        className={style.button}
                        color="primary"
                        disabled={
                            lang === 'en'
                                ? amountNum === 0
                                : document_status === INACTIVE || amountNum === 0
                        }
                        variant="contained"
                    >
                        {t('payment_pay')}
                    </Button>
                </div>
            </Grid>
        </InfoCard>
    );
};

MyPayments.propTypes = {
    data: PropTypes.object,
    paymentsData: PropTypes.object,
    onClick: PropTypes.func,
};
MyPayments.defaultProps = {};

export default MyPayments;
