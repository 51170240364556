import * as React from 'react';

function SvgCalendar(props) {
    return (
        <svg width={37} height={40} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M30.91 3.636h-1.82V1.818a1.818 1.818 0 1 0-3.636 0v1.818H10.91V1.818a1.818 1.818 0 0 0-3.636 0v1.818H5.455A5.455 5.455 0 0 0 0 9.091v25.454A5.454 5.454 0 0 0 5.455 40h25.454a5.455 5.455 0 0 0 5.455-5.455V9.091a5.454 5.454 0 0 0-5.455-5.455zm1.817 30.91a1.818 1.818 0 0 1-1.818 1.818H5.455a1.818 1.818 0 0 1-1.819-1.819V18.182h29.091v16.363zm0-20H3.637V9.09a1.818 1.818 0 0 1 1.818-1.818h1.818V9.09a1.818 1.818 0 0 0 3.636 0V7.273h14.545V9.09a1.818 1.818 0 0 0 3.637 0V7.273h1.818a1.818 1.818 0 0 1 1.818 1.818v5.455z"
                fill="#767676"
            />
        </svg>
    );
}

export default SvgCalendar;
