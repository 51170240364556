import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import PopupBackground from 'components/Popup';
import PhoneInput from '../PhoneInput';
import { useTranslation } from 'react-i18next';
import { Label } from 'components';
import { FormControlLabel, RadioGroup, Radio, MenuItem, Button } from '@material-ui/core';
import {
    getReferenceCategoriesListSelector,
    getReferenceCategoriesListRequest,
    updateReferenceCategoryRequest,
    addReferenceCategoryRequest,
    deleteReferenceCategoryRequest,
} from 'modules/salon';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { useSelector, useDispatch } from 'react-redux';
import ReferenceCategoryItem from 'components/ReferenceCategoryItem';
import style from './style.scss';
import { Add } from '@material-ui/icons';
import Popover from '@material-ui/core/Popover';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import moment from 'moment';

const Popup = ({ onSubmit, onCancel, onClose, showPopup, countryCode, id: salonId }) => {
    const [phone, setPhone] = React.useState('');
    const [name, setName] = React.useState('');
    const [gender, setGender] = React.useState('f');
    const [textError, setErrorText] = React.useState('');
    const [textInfo, setInfoText] = React.useState('');
    const [dateOfBirth, setDateOfBirth] = React.useState(new Date());
    const [reference, setReference] = React.useState({ name: '', id: '' });
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);
    const idPopover = open ? 'simple-popover' : undefined;
    const referenceCategories = useSelector(getReferenceCategoriesListSelector);

    const handleDateChange = date => {
        setDateOfBirth(date);
    };
    const { t } = useTranslation();

    React.useEffect(() => {
        if (salonId) {
            dispatch(getReferenceCategoriesListRequest({ id: salonId }));
        }
    }, [dispatch, salonId]);

    const [references, setReferences] = React.useState([]);

    React.useEffect(() => {
        if (!!referenceCategories?.data?.length) {
            const preparedCategories = referenceCategories.data
                .map(note => ({ ...note, mode: 'view' }))
                .sort(
                    (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
                );
            setReferences(preparedCategories);
        }
    }, [referenceCategories]);

    const addReferenceCategory = () => {
        const newCategory = { name: '', mode: 'add', id: 'new' };
        setReferences(prev => [newCategory, ...prev]);
    };
    const updateReferenceCategory = useCallback(
        (id, value) => {
            dispatch(
                updateReferenceCategoryRequest(
                    { id: salonId, categoryId: id, name: value },
                    {
                        onSuccess: () => {
                            dispatch(
                                getReferenceCategoriesListRequest({
                                    id: salonId,
                                })
                            );
                        },
                    }
                )
            );
        },
        [dispatch, salonId]
    );
    const deleteReferenceCategory = useCallback(
        id => {
            if (id === 'new') {
                setReferences(prev => prev.slice(1));
                return;
            }
            dispatch(
                deleteReferenceCategoryRequest(
                    { categoryId: id, id: salonId },
                    {
                        onSuccess: () => {
                            if (id === reference.id) {
                                setReference({ name: '', id: '' });
                            }
                            dispatch(
                                getReferenceCategoriesListRequest({
                                    id: salonId,
                                })
                            );
                        },
                    }
                )
            );
        },
        [dispatch, salonId]
    );

    const saveReferenceCategory = useCallback(
        (id, name) => {
            dispatch(
                addReferenceCategoryRequest(
                    { id: salonId, name },
                    {
                        onSuccess: () => {
                            dispatch(
                                getReferenceCategoriesListRequest({
                                    id: salonId,
                                })
                            );
                        },
                    }
                )
            );
        },
        [dispatch, salonId]
    );
    const handleEditReferenceCategory = (id, mode) => {
        setReferences(prev => {
            const newCategories = prev.map(item => {
                if (item.id === id) {
                    return { ...item, mode: mode };
                }
                return item;
            });
            return newCategories;
        });
    };

    const handleChangeReferenceCategory = (id, value) => {
        setReferences(prev => {
            const newComments = prev.map(item => {
                if (item.id === id) {
                    return { ...item, name: value };
                }
                return item;
            });
            return newComments;
        });
    };

    const onReferenceCategoryClick = (id, name) => {
        setReference({ id, name });
        setAnchorEl(null);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <PopupBackground
                onSubmit={() => {
                    switch (true) {
                        case phone.length < 6:
                            setErrorText(t('enter_client_phone_number'));
                            return;
                        case name.length < 1:
                            setErrorText(t('add_client_client_name'));
                            return;
                        case !moment(dateOfBirth).isValid():
                            setErrorText(t('add_client_date_of_birth'));
                            return;
                        case !reference.id:
                            setErrorText(t('add_client_reference_category'));
                            return;
                        default: {
                            onSubmit({
                                phone: phone.replace(/[^0-9.]/g, ''),
                                name,
                                date_of_birth: moment(dateOfBirth).format('YYYY-MM-DD'),
                                gender,
                                reference_category_id: reference.id,
                            });
                            return;
                        }
                    }
                }}
                onCancel={() => {
                    onCancel();
                }}
                textError={textError}
                textInfo={textInfo}
                showCancel={false}
                showClear
                onClear={() => {
                    onClose();
                    return true;
                }}
                showPopup={showPopup}
                visible
                title={t('add_client_add_a_new_client')}
                submitButtonText={t('add_client_add')}
                childrenContainerClassName={style.dataContainer}
                popupBackgroundsProps={{ onClick: onClose, childrenClassName: style.container }}
            >
                <div className={style.topBlock}>
                    <div>
                        <PhoneInput
                            countryCode={countryCode}
                            label={t('add_client_phone_number')}
                            value={phone}
                            onChange={setPhone}
                        />
                    </div>
                    <div className={style.inputName}>
                        <TextField
                            fullWidth
                            label={t('generic_name')}
                            required
                            value={name}
                            onChange={ev => {
                                setName(ev.target.value);
                            }}
                            InputLabelProps={{ shrink: true }}
                        />
                    </div>
                    <div className={style.inputName}>
                        <Label title={t('generic_gender')}>
                            <RadioGroup
                                aria-label="gender"
                                value={gender}
                                onChange={ev => {
                                    setGender(ev.target.value);
                                }}
                                row
                                fullWidth
                                style={{ marginTop: '14px' }}
                            >
                                <FormControlLabel
                                    value="f"
                                    control={<Radio color="primary" />}
                                    label={t('generic_gender_female')}
                                />
                                <FormControlLabel
                                    value="m"
                                    control={<Radio color="primary" />}
                                    label={t('generic_gender_male')}
                                />
                            </RadioGroup>
                        </Label>
                    </div>
                    <div>
                        <KeyboardDatePicker
                            fullWidth
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            label={t('generic_date_of_birth')}
                            value={dateOfBirth}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            disableFuture
                        />
                    </div>
                    <div className={style.inputName}>
                        <TextField
                            fullWidth
                            label={t('generic_reference')}
                            required
                            value={reference.name}
                            InputProps={{
                                endAdornment: <ArrowDropDownIcon />,
                            }}
                            InputLabelProps={{ shrink: true }}
                            onClick={event => {
                                event.stopPropagation();
                                setAnchorEl(event.currentTarget);
                            }}
                        />
                        <Popover
                            id={idPopover}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            className={style.popover}
                        >
                            <div className={style.categoryContainer}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={event => {
                                        event.stopPropagation();

                                        addReferenceCategory();
                                    }}
                                    startIcon={<Add />}
                                    size="small"
                                    className={style.addButton}
                                    disabled={references?.some(item => item.mode === 'add')}
                                >
                                    {t('generic_add')}
                                </Button>

                                {!!references?.length &&
                                    references.map(option => (
                                        <ReferenceCategoryItem
                                            key={option.id}
                                            id={option.id}
                                            onChange={handleChangeReferenceCategory}
                                            mode={option.mode}
                                            onSave={saveReferenceCategory}
                                            onEdit={handleEditReferenceCategory}
                                            onUpdate={updateReferenceCategory}
                                            onDelete={deleteReferenceCategory}
                                            tFunction={t}
                                            name={option.name}
                                            onItemClick={onReferenceCategoryClick}
                                        />
                                    ))}
                            </div>
                        </Popover>
                    </div>
                </div>
            </PopupBackground>
        </MuiPickersUtilsProvider>
    );
};

Popup.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    showPopup: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    countryCode: PropTypes.string.isRequired,
};

export default Popup;
