import React from 'react';
import get from 'lodash/get';
import { TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { InfoCard, NumberFormat, InfoCardLine } from 'components';
import { getCurrencySuffix } from 'helpers/getCurrencySuffix';
import { SalonInterface, Skill, Service } from 'modules/salon/interface';
// import {
//     CONFIRMED_STATUS,
//     FINISHED_STATUS,
//     CANCELLED_STATUS_BY_MASTER,
//     CANCELLED_STATUS_BY_CLIENT,
//     PAYMENT_CARD,
//     PAYMENT_CASH,
// } from 'constants/events';
import style from './style.scss';
// import FeedbackPopup from '../FeedbackPopup/index.js';
// import FeedbackItem from '../FeedbackItem/index.js';

const SubmitPanel = ({
    setFieldValue,
    values,
    errors,
    disabledClientsComment = false,
    disabledComment = false,
    salonData,
    disabled = false,
    name = 'amount',
    children,
}: {
    setFieldValue: (field: string, value: any) => void;
    values: Record<string, any>;
    errors: Record<string, string | undefined>;
    disabledClientsComment?: boolean;
    disabledComment?: boolean;
    salonData: SalonInterface;
    handleSubmit: (data: any) => void;
    disabled?: boolean;
    children?: React.ReactNode;
    name?: string;
}) => {
    const { currency_id, skills, services } = salonData;

    const selectedSkills = values['skills'];
    const fullDataSelectedSkills = React.useMemo<Skill[]>(() => {
        return skills ? skills.filter(i => selectedSkills.find((item: any) => item === i.id)) : [];
    }, [selectedSkills, skills]);

    const selectedCustomSkills = values['services'];
    const fullDataSelectedCustomSkills = React.useMemo<Service[]>(() => {
        return services
            ? services.filter(i => selectedCustomSkills.find((item: any) => item == i.id))
            : [];
    }, [selectedCustomSkills, services]);

    const price = values[name];

    const { t } = useTranslation();
    const includedSkills = [...fullDataSelectedSkills, ...fullDataSelectedCustomSkills];
    return (
        <InfoCard
            className={style.resultBlock}
            showHeader={!!fullDataSelectedSkills.length || !!fullDataSelectedCustomSkills.length}
            showDivider={!!fullDataSelectedSkills.length || !!fullDataSelectedCustomSkills.length}
            headerContent={
                (!!fullDataSelectedSkills.length || !!fullDataSelectedCustomSkills.length) && (
                    <div className={style.headerResult}>
                        <div>
                            <div className={style.labels}>
                                <span>{t('generic_service')}</span>
                                <span>{t('generic_price')}</span>
                            </div>
                            {fullDataSelectedSkills.map((i: Skill) => (
                                <InfoCardLine
                                    key={i.id}
                                    title={i.title}
                                    value={
                                        <NumberFormat
                                            value={
                                                get(i, 'pivot.price', 0)
                                                    .toString()
                                                    .includes('.00')
                                                    ? get(i, 'pivot.price', 0)
                                                          .toString()
                                                          .slice(
                                                              0,
                                                              get(i, 'pivot.price', 0).toString()
                                                                  .length - 3
                                                          )
                                                    : get(i, 'pivot.price', 0).toString()
                                            }
                                            suffix={getCurrencySuffix(currency_id)}
                                        />
                                    }
                                />
                            ))}
                            {fullDataSelectedCustomSkills.map((i: Service) => (
                                <InfoCardLine
                                    key={i.id}
                                    title={i.title}
                                    value={
                                        <NumberFormat
                                            value={i.price}
                                            suffix={getCurrencySuffix(currency_id)}
                                        />
                                    }
                                />
                            ))}
                        </div>
                    </div>
                )
            }
            minHeight={177}
            showMenu={false}
        >
            {includedSkills.length === 0 || disabled ? (
                <InfoCardLine
                    title={t('search_total_coast')}
                    value={<NumberFormat value={price} suffix={getCurrencySuffix(currency_id)} />}
                />
            ) : (
                <>
                    <div
                        className={
                            includedSkills.length > 0 ? style.titlePrice : style.emptyTitlePrice
                        }
                    >
                        {t('search_total_coast')}
                    </div>
                    <div className={style.helperText}>{t('event_price_of_the_service')}</div>
                    <TextField
                        InputProps={{
                            inputProps: {
                                displayType: 'input',
                            },
                            // @ts-ignore
                            inputComponent: NumberFormat,
                        }}
                        value={price}
                        onChange={ev => setFieldValue(name, parseFloat(ev.target.value))}
                        placeholder={`${t('event_price')}, ${getCurrencySuffix(currency_id)}`}
                        variant="outlined"
                        size="small"
                        fullWidth
                        onBlur={ev => setFieldValue(name, ev.target.value)}
                        className={style.inputPrice}
                    />
                </>
            )}
            {children}
        </InfoCard>
    );
};

export default SubmitPanel;
