import get from 'lodash/get';

export const getSystemLang = () => {
    const host = get(window, 'location.host', '');
    if (host.includes('localhost') || host.includes('salondev')) {
        return window && window.navigator && window.navigator.language
            ? window.navigator.language.substring(0, 2)
            : 'en';
    } else {
        if (host.includes('co.uk')) {
            return 'en';
        } else if (host.includes('by') || host.includes('ru')) {
            return 'ru';
        } else return 'en';
    }
};
