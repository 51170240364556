import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import { SkillItem, PhoneInput, MasterSkillPopup } from 'components';
import Skeleton from '../../components/Skeleton';
import { useTranslation } from 'react-i18next';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useHooks } from './hooks';
import style from './style.scss';

const useStyles = makeStyles(theme => ({
    root: {
        background: '#FFFAF6',
        border: '2px solid #FA835F',
        color: '#FA835F',
        margin: 'auto 10px;',
    },
    formControll: {
        width: '100%',
    },
}));

const AddMaster = ({
    history,
    match: {
        params: { id: editedMasterId },
    },
    currentLocalization: { countryCode },
}) => {
    const classes = useStyles();
    const {
        textError,
        dispatch,
        addMaster,
        showPopup,
        skillSelectProps,
        includedCustomSkills,
        customSkills,
        includedSkills,
        salonFiltredSkills,
        setIncludedSkills,
        switchShowPopup,
        onSubmitPopup,
        phone,
        name,
        setName,
        submitPushed,
        lastName,
        setLastName,
        setIncludedCustomSkills,
        getSkillDataById,
        allSkills,
        setAccessCreateRequest,
        accessCreateRequest,
        setAccessControllRequest,
        accessControllRequest,
        clearMasterByPhoneAction,
        selectedCustomSkills,
        currentMaster,
        setPhone,
        id,
        master,
        getMasterByPhoneRequest,
    } = useHooks({ editedMasterId, history });
    const { t } = useTranslation();

    return (
        <Skeleton
            textError={textError}
            title={editedMasterId ? t('edit_master') : t('add_master')}
            showProgressBar={false}
            onBack={() => {
                history.goBack();
                dispatch(clearMasterByPhoneAction());
            }}
            onNext={addMaster}
            nextButtonText={currentMaster ? t('add_master_apply') : t('add_master_add')}
            backButtonText={t('generic_back')}
            subTitle={
                editedMasterId
                    ? t('edit_beautician_subtitle')
                    : t('add_master_you_can_add_a_new_beautician')
            }
        >
            {showPopup && (
                <MasterSkillPopup
                    showSelectAll
                    skillsSelectProps={skillSelectProps}
                    showSelectedItemsCount
                    customSkills={customSkills}
                    skills={salonFiltredSkills}
                    showPopup={showPopup}
                    title={t('add_master_services_provided')}
                    onCancel={() => {
                        setIncludedSkills([]);
                        switchShowPopup(!showPopup);
                    }}
                    onClose={() => {
                        switchShowPopup(!showPopup);
                    }}
                    onSubmit={onSubmitPopup}
                    selected={includedSkills}
                    setSelected={setIncludedSkills}
                    setSelectedCustom={setIncludedCustomSkills}
                    selectedCustom={includedCustomSkills}
                />
            )}
            <Grid container>
                <Grid item md={6} xs={12}>
                    <div className={style.itemForm}>
                        <PhoneInput
                            disabled={currentMaster}
                            onBlur={() => {
                                if (typeof phone === 'string') {
                                    dispatch(
                                        getMasterByPhoneRequest({
                                            id,
                                            phone: phone.replace(/\D/g, ''),
                                        })
                                    );
                                }
                            }}
                            value={phone}
                            countryCode={countryCode}
                            onChange={setPhone}
                            margin="none"
                            fullWidth
                            required
                            label={t('add_master_phone_number')}
                            helperText={
                                submitPushed && !phone ? (
                                    <span className={style.errorText}>
                                        {t('add_master_number_of_a_beautician')}
                                    </span>
                                ) : (
                                    ''
                                )
                            }
                        />
                    </div>
                    <div className={style.itemForm}>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            margin="none"
                            required
                            label={t('generic_name')}
                            fullWidth
                            value={name}
                            onChange={ev => {
                                setName(ev.target.value);
                            }}
                            placeholder={t('eventdata_addpopup_the_beauticians')}
                            helperText={
                                submitPushed && !name ? (
                                    <span className={style.errorText}>
                                        {t('add_master_name_of_a_beautician')}
                                    </span>
                                ) : (
                                    ''
                                )
                            }
                        />
                    </div>
                    <div className={style.itemForm}>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            margin="none"
                            required
                            label={t('generic_surname')}
                            fullWidth
                            value={lastName}
                            onChange={ev => {
                                if (ev.target.value.length <= 256) {
                                    setLastName(ev.target.value);
                                }
                            }}
                            placeholder={t('add_master_surname_of_a_beautician')}
                            helperText={
                                submitPushed && !lastName ? (
                                    <span className={style.errorText}>
                                        {t('add_master_specify_the_surname')}
                                    </span>
                                ) : (
                                    ''
                                )
                            }
                        />
                    </div>
                    <div className={style.itemForm}>
                        <FormControl classes={{ root: classes.formControll }}>
                            <div className={style.editServices}>
                                <div className={style.skillsCounterContainer}>
                                    {t('master_detail_services_provided')}
                                    {!!(includedSkills.length + selectedCustomSkills.length) && (
                                        <div className={style.skillsCounter}>
                                            {includedSkills.length + selectedCustomSkills.length}
                                        </div>
                                    )}
                                </div>

                                <Button
                                    color="primary"
                                    className={style.editButton}
                                    disabled={
                                        (master && get(master, 'status') !== 'confirmed') ||
                                        (currentMaster &&
                                            get(currentMaster, 'status') !== 'confirmed')
                                    }
                                    onClick={() => switchShowPopup(!showPopup)}
                                >
                                    {t('add_edit_services')}
                                </Button>
                            </div>
                            <div className={style.selectedItemsContainer}>
                                {includedSkills.map(i => (
                                    <div key={i.id} className={style.skillItemContainer}>
                                        <SkillItem
                                            showDuration={false}
                                            showPrice={false}
                                            showEdit={false}
                                            data={getSkillDataById({ allSkills, id: i.id })}
                                            onDelete={() => {
                                                setIncludedSkills(
                                                    includedSkills.filter(item => item.id !== i.id)
                                                );
                                            }}
                                        />
                                    </div>
                                ))}{' '}
                                {selectedCustomSkills.map(i => (
                                    <div key={i.id} className={style.skillItemContainer}>
                                        <SkillItem
                                            showDuration={false}
                                            showPrice={false}
                                            showEdit={false}
                                            data={{
                                                ...i,
                                                price: get(i, 'price'),
                                                duration: get(i, 'duration'),
                                            }}
                                            onDelete={() => {
                                                setIncludedCustomSkills(
                                                    includedCustomSkills.filter(
                                                        item => item.id !== i.id
                                                    )
                                                );
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                            <div className={style.buttonContainer}>
                                <Button
                                    margin="none"
                                    style={{ borderRadius: '6px' }}
                                    disabled={
                                        (master && get(master, 'status') !== 'confirmed') ||
                                        (currentMaster &&
                                            get(currentMaster, 'status') !== 'confirmed')
                                    }
                                    onClick={() => switchShowPopup(!showPopup)}
                                    variant="contained"
                                    color="primary"
                                >
                                    {t('add_edit_services')}
                                </Button>
                            </div>
                            <FormHelperText id="component-error-text">
                                {submitPushed && includedSkills.length == 0 && !master ? (
                                    <span className={style.errorText}>
                                        {t('add_master_least_one_service')}
                                    </span>
                                ) : (
                                    ''
                                )}
                            </FormHelperText>
                        </FormControl>
                        <div className={style.accessControlls}>
                            <FormControlLabel
                                className={style.accessControllItem}
                                control={
                                    <Checkbox
                                        checked={accessCreateRequest}
                                        onChange={() => {
                                            setAccessCreateRequest(!accessCreateRequest);
                                        }}
                                        name="unworkedDay"
                                        color="primary"
                                    />
                                }
                                label={t('add_master_create_an_appointment')}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={accessControllRequest}
                                        onChange={() => {
                                            setAccessControllRequest(!accessControllRequest);
                                        }}
                                        name="unworkedDay"
                                        color="primary"
                                    />
                                }
                                label={t('add_master_edit_an_appointment')}
                            />
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Skeleton>
    );
};

AddMaster.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    currentLocalization: PropTypes.object.isRequired,
};

export default AddMaster;
