import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { Label } from 'components';
import { Popup } from 'feelqueen_components';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { Select } from 'feelqueen_components';
import { useTranslation } from 'react-i18next';
import style from './style.scss';

const useStyles = makeStyles(theme => ({
    adornedEnd: {
        paddingRight: 0,
        marginRight: 0,
    },
    icon: {
        color: '#767676',
    },
}));

const UploadPopup = ({ onSubmit, onClear, setCRMSync, phone, ...props }) => {
    const classes = useStyles();
    const ref = React.useRef(null);
    const [tranzactionsFile, setTranzactionsFile] = React.useState();
    const [clientsFile, setClientsFile] = React.useState();
    const [ordersFile, setOrdersFile] = React.useState();
    const [showAuthPopup, switchAuthPopup] = React.useState();
    const [showUniversePopup, switchUniversePopup] = React.useState();
    const [showUKSyncPopup, switchUKSyncPopup] = React.useState();
    const [showAltegioPopup, switchAltegioPopup] = React.useState();
    const [password, setPassword] = React.useState();
    const [login, setLogin] = React.useState();
    const [partnerToken, setPartnerToken] = React.useState();
    const [showPassword, switchShowPassword] = React.useState();
    const [textError, setTextError] = React.useState();
    const [url, setUrl] = React.useState();
    const [selectedCompany, setSelectedCompany] = React.useState(0);
    const { t } = useTranslation();
    const isUaePhoneNumber = phone.startsWith('971');
    const isUKPhoneNumber = !(phone.startsWith('375') || phone.startsWith('7'));
    const uploadAction = setTranzactionsFile => {
        if (ref.current) {
            const upload = function() {
                setTranzactionsFile(ref.current.files[0]);
                ref.current.removeEventListener('change', upload);
            };
            ref.current.addEventListener('change', upload);
            ref.current.click();
        }
    };
    const options = [
        { value: 0, label: 'yClients', key: 'yClients' },
        { value: 1, label: 'Universe', key: 'Universe' },
        { value: 2, label: 'Sonline', key: 'Sonline' },
        { value: 3, label: `1C.${t('synch_beauty')}`, key: '1c' },
        { value: 4, label: 'Dikidi', key: 'dikidi' },
    ];
    const UKOptions = [
        { value: 0, label: 'Treatwell', key: 'treatwell' },
        { value: 1, label: 'Fresha', key: 'fresha' },
    ];
    const UaeOptions = [
        { value: 0, label: 'Treatwell', key: 'treatwell' },
        { value: 1, label: 'Fresha', key: 'fresha' },
        { value: 2, label: 'Alteg.io', key: 'alteg.io' },
    ];

    const getOptions = () => {
        if (phone.startsWith('375') || phone.startsWith('7')) return options;
        else if (phone.startsWith('971')) return UaeOptions;
        else return UKOptions;
    };

    const onSubmitPopupYclients = () => {
        switch (true) {
            case !password || !login:
                setTextError(t('synch_data_entered'));
                break;

            default: {
                setTextError('');
                setCRMSync({
                    data: { password, login, type: 'yclients', url },

                    onSuccess: () => {
                        switchAuthPopup(!showAuthPopup);
                        onClear();
                    },
                });
            }
        }
    };

    const onSubmitPopupAltegio = () => {
        switch (true) {
            case !password || !login || !partnerToken:
                setTextError(t('synch_data_entered'));
                break;

            default: {
                setTextError('');
                setCRMSync({
                    data: { password, login, type: 'altegio', url, partner_token: partnerToken },

                    onSuccess: () => {
                        switchAltegioPopup(!showAltegioPopup);
                        onClear();
                    },
                });
            }
        }
    };

    const onSubmitPopupUniverse = () => {
        switch (true) {
            case !url:
                setTextError(t('synch_data_entered'));
                break;

            default: {
                setTextError('');
                setCRMSync({
                    data: {
                        url,
                        type: getOptions()
                            .find(item => item.value === selectedCompany)
                            .key.toLowerCase(),
                    },
                    onSuccess: () => {
                        switchUniversePopup(!showUniversePopup);
                        onClear();
                    },
                });
            }
        }
    };
    const onSubmitPopupUKSync = () => {
        switch (true) {
            case !url:
                setTextError(t('synch_data_entered'));
                break;

            default: {
                setTextError('');
                setCRMSync({
                    data: {
                        url,
                        type: UKOptions.find(
                            item => item.value === selectedCompany
                        ).key.toLowerCase(),
                    },
                    onSuccess: () => {
                        switchUKSyncPopup(!showUKSyncPopup);
                        onClear();
                    },
                });
            }
        }
    };

    const selectedCompanyName = React.useMemo(() => {
        return getOptions().find(item => item.value === selectedCompany).label;
    }, [selectedCompany]);

    return (
        <React.Fragment>
            <Popup
                title={t('synch_data_synchronisation')}
                onSubmit={() => {
                    if (isUaePhoneNumber) {
                        switchAltegioPopup(!showAltegioPopup);
                    } else if (selectedCompany == 0 && !isUKPhoneNumber) {
                        switchAuthPopup(!showAuthPopup);
                    } else if (isUKPhoneNumber) {
                        switchUKSyncPopup(!showUKSyncPopup);
                    } else {
                        switchUniversePopup(!showUniversePopup);
                    }
                }}
                // disableSubmit={!clientsFile && !ordersFile && !tranzactionsFile}
                onClear={onClear}
                showClear
                submitButtonText={t('synch_synchronise')}
                showCancel={false}
            >
                <div>
                    <div className={style.subtitle}>
                        {t('synch_using_another_crm_system')}
                        <br />
                        {t('synch_names_of_the_employees')}
                    </div>

                    <div className={style.contentContainer}>
                        <Select
                            options={getOptions()}
                            fullWidth
                            value={selectedCompany}
                            onChange={ev => {
                                setSelectedCompany(ev.target.value);
                            }}
                            label={t('synch_crm_system')}
                        />
                    </div>
                </div>
            </Popup>
            {showAuthPopup && (
                <Popup
                    textError={textError}
                    title={t('synch_synchronising_with_yClients')}
                    childrenContainerClassName={style.popupChildrenContainer}
                    submitButtonText={t('synch_download_files')}
                    showClear
                    showCancel={false}
                    onClear={() => {
                        switchAuthPopup(!showAuthPopup);
                    }}
                    disableSubmit={!clientsFile && !ordersFile && !tranzactionsFile}
                    onSubmit={() => {
                        onSubmit({
                            payments: tranzactionsFile,
                            clients: clientsFile,
                            records: ordersFile,
                        });
                        switchAuthPopup(!showAuthPopup);
                    }}
                    className={style.sync}
                >
                    <div className={style.subtitle}>{t('synch_authorise_with_your_data')}</div>
                    <div className={style.authContainer}>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            className={style.inputAuth}
                            fullWidth
                            value={login}
                            label={t('synch_login')}
                            placeholder={t('synch_enter_yClients_login')}
                            onChange={ev => setLogin(ev.target.value)}
                            classes={{ adornedEnd: classes.adornedEnd }}
                        />
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            label={t('synch_password')}
                            className={style.inputAuth}
                            value={password}
                            type={showPassword ? 'text' : 'password'}
                            placeholder={t('synch_enter_yClients_password')}
                            onChange={ev => setPassword(ev.target.value)}
                            classes={{ adornedEnd: classes.adornedEnd }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment
                                        onClick={() => switchShowPassword(!showPassword)}
                                        position="end"
                                    >
                                        {!showPassword ? (
                                            <VisibilityOutlinedIcon color="primary" />
                                        ) : (
                                            <VisibilityOffOutlinedIcon color="primary" />
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>

                    <TextField
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        className={style.inputAuth}
                        label={t('synch_link_to_widget')}
                        placeholder={t('synch_link_to_the_widget_placeholder')}
                        value={url}
                        onChange={ev => setUrl(ev.target.value)}
                    />
                    <div className={style.syncContainer}>
                        {t('synch_link_to_the_widget')}
                        <Tooltip
                            title={
                                selectedCompany === 0 && (
                                    <span>
                                        {t('synch_yClients_in_yClients')}
                                        <br />
                                        {t('synch_yClients_online_booking')}
                                        <br />
                                        {t('synch_yClients_link_on_the_screen')}
                                        https://n000001.yclients.com/)
                                        <br />
                                        {t('synch_yClients_link_on_the_screen')}
                                        https://n000001.yclients.com/company:483025?o=m1402857)
                                    </span>
                                )
                            }
                        >
                            <InfoOutlinedIcon color="primary" className={style.syncIcon} />
                        </Tooltip>
                    </div>
                    <React.Fragment>
                        <Button
                            size="large"
                            variant="contained"
                            color="primary"
                            onClick={onSubmitPopupYclients}
                            disabled={!password || !login}
                        >
                            {t('synch_synchronise')}
                        </Button>
                        <div className={style.subtitleFiles}>
                            {t('synch_yClients_transfer_working_data')}
                        </div>
                        <Label title={t('synch_client_table')}>
                            <div className={style.buttonContainer}>
                                {clientsFile && (
                                    <TextField
                                        size="small"
                                        disabled
                                        variant="outlined"
                                        className={style.input}
                                        value={clientsFile.name}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment
                                                    onClick={() => setClientsFile()}
                                                    position="end"
                                                >
                                                    <ClearIcon classes={{ root: classes.icon }} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                                <Button
                                    className={style.button}
                                    onClick={() => uploadAction(setClientsFile)}
                                    type="file"
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                >
                                    {clientsFile ? t('synch_add_file') : t('synch_add_file_add')}
                                    <input type="file" hidden />
                                </Button>
                            </div>
                        </Label>
                        <Label title={t('synch_table_of_bookings')}>
                            <div className={style.buttonContainer}>
                                {ordersFile && (
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        disabled
                                        className={style.input}
                                        value={ordersFile.name}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment
                                                    onClick={() => setOrdersFile()}
                                                    position="end"
                                                >
                                                    <ClearIcon classes={{ root: classes.icon }} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                                <Button
                                    className={style.button}
                                    onClick={() => uploadAction(setOrdersFile)}
                                    type="file"
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                >
                                    {ordersFile ? t('synch_add_file') : t('synch_add_file_add')}

                                    <input type="file" hidden />
                                </Button>
                            </div>
                        </Label>
                        <Label title={t('synch_transaction_table')}>
                            <div className={style.buttonContainer}>
                                {tranzactionsFile && (
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        disabled
                                        className={style.input}
                                        value={tranzactionsFile.name}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment
                                                    onClick={() => setTranzactionsFile()}
                                                    position="end"
                                                >
                                                    <ClearIcon classes={{ root: classes.icon }} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                                <Button
                                    className={style.button}
                                    onClick={() => uploadAction(setTranzactionsFile)}
                                    type="file"
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                >
                                    {tranzactionsFile
                                        ? t('synch_add_file')
                                        : t('synch_add_file_add')}
                                </Button>
                            </div>
                        </Label>
                        <input
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            hidden
                            ref={ref}
                            type="file"
                        />
                    </React.Fragment>
                </Popup>
            )}
            {showUniversePopup && (
                <Popup
                    textError={textError}
                    childrenContainerClassName={style.popupUniverseChildrenContainer}
                    submitButtonText={t('synch_synchronise')}
                    showClear
                    showCancel={false}
                    onClear={() => {
                        switchUniversePopup(!showUniversePopup);
                    }}
                    onSubmit={onSubmitPopupUniverse}
                    title={`${t('synch_synchronisation_with')} ${selectedCompanyName}`}
                    subTitle={'test'}
                >
                    <div className={style.subtitleUniverse}>
                        {t('synch_insert_the_link')} {selectedCompanyName}.
                    </div>
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        fullWidth
                        label="Ссылка на виджет"
                        placeholder={'Вставьте ссылку на виджет'}
                        value={url}
                        onChange={ev => setUrl(ev.target.value)}
                    />
                    <div className={style.syncContainer}>
                        {t('synch_link_to_the_widget')}
                        <Tooltip
                            title={
                                selectedCompany === 1 ? (
                                    <span>
                                        {t('synch_universe_in_universe')}
                                        <br />
                                        {t('synch_universe_select_an_active')}
                                        <br />
                                        {t('synch_universe_press_the')}
                                        <br />
                                        {t('synch_universe_go_to_the')}
                                    </span>
                                ) : (
                                    <span>
                                        1. {t('synch_Widget_in')} {selectedCompanyName}{' '}
                                        {t('synch_universe_selectedCompanyName')}
                                        <br />
                                        {t('synch_go_down_to')} <br />
                                        3. Скопировать общую ссылку
                                    </span>
                                )
                            }
                        >
                            <InfoOutlinedIcon color="primary" className={style.syncIcon} />
                        </Tooltip>
                    </div>
                </Popup>
            )}
            {showUKSyncPopup && (
                <Popup
                    textError={textError}
                    childrenContainerClassName={style.popupUniverseChildrenContainer}
                    submitButtonText={t('synch_synchronise')}
                    showClear
                    showCancel={false}
                    onClear={() => {
                        switchUKSyncPopup(!showUKSyncPopup);
                    }}
                    onSubmit={onSubmitPopupUKSync}
                    title={`${t('synch_synchronisation_with')} ${selectedCompanyName}`}
                    subTitle={'test'}
                >
                    <div className={style.subtitleUniverse}>
                        {t('synch_insert_the_link')} {selectedCompanyName}.
                    </div>
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        fullWidth
                        label="Salon Profile link"
                        placeholder={`Insert the link to your ${selectedCompanyName} Profile`}
                        value={url}
                        onChange={ev => setUrl(ev.target.value)}
                    />
                    <div className={style.syncContainer}>
                        {t('synch_link_to_the_widget')}
                        <Tooltip
                            title={
                                selectedCompany === 1 ? (
                                    <span>
                                        1. Go to your salon Profile page in Fresha Marketplace.
                                        <br />
                                        2. Copy the link of your profile from Browser URL field.
                                        <br />
                                        3. Paste copied profile link into FeeL field.
                                        <br />
                                        Link example:
                                        https://www.fresha.com/a/copper-row-barbers-london-saffron-wharf-18-shad-thames-xahuqhjq
                                    </span>
                                ) : (
                                    <span>
                                        1. Head to 'Settings' in Connect and click 'Online booking.
                                        <br />
                                        2. Go to the 'Booking Widget' tab.
                                        <br />
                                        3. Find "View link and setup instructions" under "Sharing
                                        the widget link". Click "Copy Link".
                                        <br />
                                        4. Paste copied link into FeeL field.
                                        <br />
                                        Link example:
                                        https://widget.treatwell.co.uk/place/connection-hair-beauty/
                                    </span>
                                )
                            }
                        >
                            <InfoOutlinedIcon color="primary" className={style.syncIcon} />
                        </Tooltip>
                    </div>
                </Popup>
            )}
            {showAltegioPopup && (
                <Popup
                    textError={textError}
                    title={t('synch_synchronising_with_altegio')}
                    childrenContainerClassName={style.popupChildrenContainer}
                    submitButtonText={t('synch_download_files')}
                    showClear
                    showCancel={false}
                    onClear={() => {
                        switchAltegioPopup(!showAltegioPopup);
                    }}
                    disableSubmit={!clientsFile && !ordersFile && !tranzactionsFile}
                    onSubmit={() => {
                        onSubmit({
                            payments: tranzactionsFile,
                            clients: clientsFile,
                            records: ordersFile,
                        });
                        switchAltegioPopup(!showAltegioPopup);
                    }}
                    className={style.sync}
                >
                    <div className={style.subtitle}>
                        {t('synch_authorise_with_your_data_altegio')}
                    </div>
                    <div className={style.authContainer}>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            className={style.inputAuth}
                            fullWidth
                            value={login}
                            label={t('synch_login')}
                            placeholder={t('synch_enter_yClients_login')}
                            onChange={ev => setLogin(ev.target.value)}
                            classes={{ adornedEnd: classes.adornedEnd }}
                        />
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            label={t('synch_password')}
                            className={style.inputAuth}
                            value={password}
                            type={showPassword ? 'text' : 'password'}
                            placeholder={t('synch_enter_yClients_password')}
                            onChange={ev => setPassword(ev.target.value)}
                            classes={{ adornedEnd: classes.adornedEnd }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment
                                        onClick={() => switchShowPassword(!showPassword)}
                                        position="end"
                                    >
                                        {!showPassword ? (
                                            <VisibilityOutlinedIcon color="primary" />
                                        ) : (
                                            <VisibilityOffOutlinedIcon color="primary" />
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>

                    <TextField
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        className={style.inputAuth}
                        label={t('synch_link_to_widget')}
                        placeholder={t('synch_link_to_the_widget_placeholder')}
                        value={url}
                        onChange={ev => setUrl(ev.target.value)}
                    />
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        className={style.inputAuth}
                        fullWidth
                        value={partnerToken}
                        label={t('synch_partner_token')}
                        placeholder={t('synch_enter_partner_token')}
                        onChange={ev => setPartnerToken(ev.target.value)}
                        classes={{ adornedEnd: classes.adornedEnd }}
                    />
                    <div className={style.syncContainer}>
                        {t('synch_link_to_the_widget')}
                        <Tooltip
                            title={
                                selectedCompany === 0 && (
                                    <span>
                                        {t('synch_yClients_in_yClients')}
                                        <br />
                                        {t('synch_yClients_online_booking')}
                                        <br />
                                        {t('synch_yClients_link_on_the_screen')}
                                        https://n000001.yclients.com/)
                                        <br />
                                        {t('synch_yClients_link_on_the_screen')}
                                        https://n000001.yclients.com/company:483025?o=m1402857)
                                    </span>
                                )
                            }
                        >
                            <InfoOutlinedIcon color="primary" className={style.syncIcon} />
                        </Tooltip>
                    </div>
                    <React.Fragment>
                        <Button
                            size="large"
                            variant="contained"
                            color="primary"
                            onClick={onSubmitPopupAltegio}
                            disabled={!password || !login || !partnerToken}
                        >
                            {t('synch_synchronise')}
                        </Button>
                        <div className={style.subtitleFiles}>
                            {t('synch_altegio_transfer_working_data')}
                        </div>
                        <Label title={t('synch_client_table')}>
                            <div className={style.buttonContainer}>
                                {clientsFile && (
                                    <TextField
                                        size="small"
                                        disabled
                                        variant="outlined"
                                        className={style.input}
                                        value={clientsFile.name}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment
                                                    onClick={() => setClientsFile()}
                                                    position="end"
                                                >
                                                    <ClearIcon classes={{ root: classes.icon }} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                                <Button
                                    className={style.button}
                                    onClick={() => uploadAction(setClientsFile)}
                                    type="file"
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                >
                                    {clientsFile ? t('synch_add_file') : t('synch_add_file_add')}
                                    <input type="file" hidden />
                                </Button>
                            </div>
                        </Label>
                        <Label title={t('synch_table_of_bookings')}>
                            <div className={style.buttonContainer}>
                                {ordersFile && (
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        disabled
                                        className={style.input}
                                        value={ordersFile.name}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment
                                                    onClick={() => setOrdersFile()}
                                                    position="end"
                                                >
                                                    <ClearIcon classes={{ root: classes.icon }} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                                <Button
                                    className={style.button}
                                    onClick={() => uploadAction(setOrdersFile)}
                                    type="file"
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                >
                                    {ordersFile ? t('synch_add_file') : t('synch_add_file_add')}

                                    <input type="file" hidden />
                                </Button>
                            </div>
                        </Label>
                        <Label title={t('synch_transaction_table')}>
                            <div className={style.buttonContainer}>
                                {tranzactionsFile && (
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        disabled
                                        className={style.input}
                                        value={tranzactionsFile.name}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment
                                                    onClick={() => setTranzactionsFile()}
                                                    position="end"
                                                >
                                                    <ClearIcon classes={{ root: classes.icon }} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                                <Button
                                    className={style.button}
                                    onClick={() => uploadAction(setTranzactionsFile)}
                                    type="file"
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                >
                                    {tranzactionsFile
                                        ? t('synch_add_file')
                                        : t('synch_add_file_add')}
                                </Button>
                            </div>
                        </Label>
                        <input
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            hidden
                            ref={ref}
                            type="file"
                        />
                    </React.Fragment>
                </Popup>
            )}
        </React.Fragment>
    );
};

UploadPopup.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    setCRMSync: PropTypes.func,
};

export default UploadPopup;
