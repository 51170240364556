import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { getSalonSelector } from 'modules/salon';
import { useTranslation } from 'react-i18next';
import {
    deleteCustomSkillSubSkillsRequest,
    getCustomSkillsRequest,
    getSalonCustomSkillsSelector,
} from 'modules/skills/customSkills.js';
import { showPopupAction } from 'modules/popups';
import { Skeleton, PagePlaceHolder, SkillItem } from 'components';
import style from './style.scss';
import {
    getListSkillsSelector,
    deleteSkillRequest,
    getSkillsRequest,
    getSalonSkillsSelector,
    getListSkillsRequest,
} from 'modules/skills';

const Services = ({ history }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [textError, setTextError] = React.useState('');
    const skillsFullList = useSelector(getListSkillsSelector);
    const skills = useSelector(getSalonSkillsSelector);
    const services = useSelector(getSalonCustomSkillsSelector);
    const { id, currency_id } = useSelector(getSalonSelector);

    React.useEffect(() => {
        dispatch(getListSkillsRequest());
    }, []);

    React.useEffect(() => {
        if (id) {
            dispatch(getSkillsRequest({ id }));
            dispatch(getCustomSkillsRequest({ id }));
        }
    }, [id]);
    let prevTitle;
    const preparedSkills = React.useMemo(() => {
        const uniqueServices = services
            .filter(service => !!service.parent_uid)
            .map(element => {
                return { ...element, is_unique: true };
            });
        return [...skills, ...uniqueServices].sort((a, b) => {
            if (a.parent_uid > b.parent_uid) {
                return 1;
            }
            if (a.parent_uid < b.parent_uid) {
                return -1;
            }

            return 0;
        });
    }, [skills, services]);

    const preparedServices = React.useMemo(() => {
        return services.filter(service => !service.parent_uid);
    }, [services]);
    console.log(services);
    return (
        <Skeleton
            bottomPositionButtons={false}
            textError={textError}
            nextButtonText={`${t('add_edit_services')}`}
            title={`${t('generic_services')}  ${preparedSkills.length + preparedServices.length ||
                ''}`}
            onNext={
                preparedSkills.length > 0 || preparedServices.length > 0
                    ? () => {
                          history.push('/services/edit');
                      }
                    : false
            }
            subTitle=""
        >
            <Grid container>
                {preparedSkills.length > 0 || preparedServices.length > 0 ? (
                    <Grid item xs={12} md={12}>
                        {preparedSkills.map((i, index) => {
                            const title = get(
                                skillsFullList.find(
                                    item =>
                                        item.id == i.parent_skill_id || item.uid === i.parent_uid
                                ),
                                'title'
                            );
                            return (
                                <React.Fragment key={i.id}>
                                    {prevTitle !== title && (prevTitle = title) && (
                                        <div
                                            className={
                                                index ? style.skillCategory : style.firstCategory
                                            }
                                        >
                                            {title}
                                        </div>
                                    )}

                                    <SkillItem
                                        showEdit={false}
                                        onEdit={() => {
                                            // history.push(`/init-custom-skills/${i.id}`);
                                        }}
                                        onDelete={() => {
                                            dispatch(
                                                showPopupAction({
                                                    message: t('generic_eally_want_to_delete'),
                                                    onClick: () => {
                                                        i?.is_unique
                                                            ? dispatch(
                                                                  deleteCustomSkillSubSkillsRequest(
                                                                      {
                                                                          id,
                                                                          service_id: i.id,
                                                                      }
                                                                  )
                                                              )
                                                            : dispatch(
                                                                  deleteSkillRequest({
                                                                      id,
                                                                      skill_id: i.id,
                                                                  })
                                                              );
                                                        return true;
                                                    },
                                                    onCancel: () => true,
                                                    showCancel: true,
                                                    submitButtonText: 'Ok',
                                                    cancelButtonText: t('generic_cancel'),
                                                    // confirmButtonProps0: { size: 'small' },
                                                    // cancelButtonProps: { size: 'small' },
                                                })
                                            );
                                        }}
                                        currency_id={currency_id}
                                        data={{ ...i, ...(i.pivot || {}) }}
                                    />
                                </React.Fragment>
                            );
                        })}
                        {preparedServices.length > 0 && (
                            <div className={style.skillCategory}>
                                {t('generic_unique_services')}
                            </div>
                        )}
                        {preparedServices.map(i => (
                            <SkillItem
                                key={i.id}
                                onEdit={() => {
                                    history.push(`/init-custom-skills/${i.id}`);
                                }}
                                onDelete={() => {
                                    dispatch(
                                        showPopupAction({
                                            message: t('generic_eally_want_to_delete'),
                                            onClick: () => {
                                                dispatch(
                                                    deleteCustomSkillSubSkillsRequest({
                                                        id,
                                                        service_id: i.id,
                                                    })
                                                );
                                                return true;
                                            },
                                            onCancel: () => true,
                                            showCancel: true,
                                            submitButtonText: 'Ok',
                                            cancelButtonText: t('generic_cancel'),
                                            confirmButtonProps: { size: 'small' },
                                            cancelButtonProps: { size: 'small' },
                                        })
                                    );
                                }}
                                currency_id={currency_id}
                                data={{ ...i, ...(i.pivot || {}) }}
                            />
                        ))}
                    </Grid>
                ) : (
                    <Grid item xs={12} md={12}>
                        <PagePlaceHolder
                            text={t('not_clients_added')}
                            buttonText={t('search_add_the_services')}
                            onClick={() => {
                                history.push('/services/edit');
                            }}
                        />
                    </Grid>
                )}
            </Grid>
        </Skeleton>
    );
};

Services.propTypes = {
    history: PropTypes.object,
};

export default Services;
