import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { InfoCard } from 'components';
import DoneIcon from '@material-ui/icons/Done';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { getSystemLang } from 'helpers/getSystemLang';
import { STRIPE_STATUS } from 'constants/finance';
import style from './style.scss';
const INACTIVE = 'inactive';

const PaymentsData = ({
    data,
    history,
    countryCode,
    stripe,
    initStripePaymentsAccount,
    id,
    getStripeLink,
}) => {
    const { document_status, online_payment_status, name, inn, kpp, unp = '' } = data;
    const { t } = useTranslation();
    const lang = getSystemLang();

    return (
        <>
            {lang === 'en' ? (
                <InfoCard
                    title={t('payment_payment_details')}
                    minHeight="377px"
                    showMenu={false}
                    menuItems={[]}
                >
                    <Grid item xs={12} className={style.dataContainerSmall}>
                        {stripe && stripe.status === STRIPE_STATUS.COMPLETED ? (
                            <div>
                                <div className={style.status}>
                                    <DoneIcon htmlColor="#60AF6E" color="success" />
                                    <span>{t('payment_payment_is_connected')}</span>
                                </div>
                                <div className={style.description}>
                                    {t('payment_funds_will_automatically_be_debited')}
                                </div>
                            </div>
                        ) : stripe && stripe.status === STRIPE_STATUS.RESTRICTED ? (
                            <div>
                                <div className={style.status}>
                                    <ReportProblemOutlinedIcon
                                        htmlColor="#FFBB2F"
                                        color="warning"
                                    />
                                    <span> {t('add_missing_info_title')}</span>
                                </div>
                                <div className={style.description}>
                                    {t('payment_connect_online_payments')}
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div className={style.status}>
                                    <ReportProblemOutlinedIcon
                                        htmlColor="#FFBB2F"
                                        color="warning"
                                    />
                                    <span>{t('payment_documents_are_not_downloaded')}</span>
                                </div>
                                <div className={style.description}>
                                    {t('payment_details_and_upload')}
                                </div>
                            </div>
                        )}
                        {(!stripe ||
                            (stripe &&
                                (stripe.status === STRIPE_STATUS.INITIAL ||
                                    stripe.status === STRIPE_STATUS.RESTRICTED))) && (
                            <div className={style.button}>
                                <Button
                                    onClick={() => {
                                        if (id) {
                                            stripe && stripe.status === STRIPE_STATUS.RESTRICTED
                                                ? getStripeLink(id)
                                                : initStripePaymentsAccount(id);
                                        }
                                    }}
                                    className={style.button}
                                    color="primary"
                                    variant="contained"
                                >
                                    {t('payments_connect')}
                                </Button>
                            </div>
                        )}
                    </Grid>
                </InfoCard>
            ) : (
                <InfoCard
                    title={t('payment_payment_details')}
                    minHeight="377px"
                    showMenu={document_status !== INACTIVE}
                    menuItems={[
                        {
                            id: 1,
                            title: t('payment_download_the_documents'),
                            classes: {},
                            onClick: () => {
                                if (countryCode === 'ru') {
                                    history.push('/payments-register?step=5');
                                } else {
                                    history.push('/payments-register?step=4');
                                }
                            },
                        },
                    ]}
                >
                    <Grid item xs={12} className={style.dataContainerSmall}>
                        {document_status !== INACTIVE ? (
                            <div>
                                <div className={style.status}>
                                    <DoneIcon htmlColor="#60AF6E" />
                                    <span>{t('payment_documents_are_downloaded')}</span>
                                </div>
                                {countryCode == 'ru' ? (
                                    <div className={style.description}>{`${name}
ИНН: ${inn}
КПП:${kpp}`}</div>
                                ) : (
                                    <div className={style.description}>{`${name}
УНП: ${unp || ''}`}</div>
                                )}
                            </div>
                        ) : (
                            <div>
                                <div className={style.status}>
                                    <ReportProblemOutlinedIcon
                                        htmlColor="#FFBB2F"
                                        color="warning"
                                    />
                                    <span>{t('payment_documents_are_not_downloaded')}</span>
                                </div>
                                <div className={style.description}>
                                    {t('payment_details_and_upload')}
                                </div>
                            </div>
                        )}
                        {countryCode !== 'ru' ? null : online_payment_status !== INACTIVE ? (
                            <div>
                                <div className={style.status}>
                                    <DoneIcon htmlColor="#60AF6E" color="success" />
                                    <span>{t('payment_payment_is_connected')}</span>
                                </div>
                                <div className={style.description}>
                                    {t('payment_funds_will_automatically_be_debited')}
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div className={style.status}>
                                    <ReportProblemOutlinedIcon
                                        htmlColor="#FFBB2F"
                                        color="warning"
                                    />
                                    <span> {t('payment_online_payment_is_not')}</span>
                                </div>
                                <div className={style.description}>
                                    {t('payment_connect_online_payments')}
                                </div>
                            </div>
                        )}
                        {document_status === INACTIVE && (
                            <div className={style.button}>
                                <Button
                                    onClick={() => {
                                        history.push('/payments-register');
                                    }}
                                    className={style.button}
                                    color="primary"
                                    variant="contained"
                                >
                                    {t('payment_download_the_documents')}
                                </Button>
                            </div>
                        )}
                    </Grid>
                </InfoCard>
            )}
        </>
    );
};

PaymentsData.propTypes = {
    data: PropTypes.object,
    history: PropTypes.object,
    countryCode: PropTypes.string,
    stripe: PropTypes.object,
    initStripePaymentsAccount: PropTypes.func,
    getStripeLink: PropTypes.func,
    id: PropTypes.string | PropTypes.number,
};
PaymentsData.defaultProps = {};

export default PaymentsData;
