export const getCurrencySuffix = (currencyId) => {
    switch (currencyId) {
        case 1:
            return 'BYN';

        case 2:
            return '₽';
        case 5:
            return '£';
        case 6:
            return 'zł';
        case 7:
            return 'AED';
        default:
            return 'BYN';
    }
};
export const getCurrencyCardSuffix = (currencyId) => {
    // for InfoCard in main page and search
    switch (currencyId) {
        case 1:
            return '$';
        case 2:
            return '₽';
        case 5:
            return '£';
        case 6:
            return '$';
        case 7:
            return '$';
        default:
            return '$';
    }
};
