import React from 'react';
import PropTypes from 'prop-types';
import style from './style.scss';
import { useTranslation } from 'react-i18next';

const EventStatusLabel = ({ statusId }) => {
    // let status = ;
    const { t } = useTranslation();
    const status = (() => {
        switch (statusId) {
            case 3:
                return { style: style.default, title: 'event_upcoming' };
            case 4:
                return { style: style.finished, title: 'event_completed' };
            case 5:
                return { style: style.cancelled, title: 'event_cancelled' };
            case 6:
                return { style: style.cancelled, title: 'event_cancelled' };
            case 7:
                return { style: style.pendingFinish, title: 'event_waiting_to_be_completed' };
            default:
                return { style: style.default, title: 'event_upcoming' };
        }
    })();

    return (
        <div className={style.container}>
            <div className={status.style}>{t(status.title)}</div>
        </div>
    );
};

EventStatusLabel.propTypes = {
    statusId: PropTypes.number,
};

export default EventStatusLabel;
