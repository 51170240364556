import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { initData } from 'modules/init';

class Init extends Component {
    componentDidMount() {
        const { initData } = this.props;
        setTimeout(function() {
            // body...
            initData();
        }, 100);
    }
    render() {
        return <Fragment />;
    }
}

const mapDispatchToProps = dispatch => ({
    initData: bindActionCreators(initData, dispatch),
});

export default connect(null, mapDispatchToProps)(Init);
