import React, { useEffect, useState } from 'react';
// import { useLocation } from 'react-router-dom';

export const RouteContext = React.createContext('');

export const RouteProvider = ({ children }) => {
    const [isShowScrollButton, setIsShowScrollButton] = useState(false);
    const location = window?.location;
    const [route, setRoute] = useState({
        to: location.pathname,
        from: location.pathname,
    });
    useEffect(() => {
        setRoute(prev => ({ to: location.pathname, from: prev.to }));
    }, [location.pathname]);

    return (
        <RouteContext.Provider value={{ route, setIsShowScrollButton, isShowScrollButton }}>
            {children}
        </RouteContext.Provider>
    );
};
