import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import { SimpleSelect as Select, SimpleInput } from 'feelqueen_components';
import DatePicker from 'components/DatePicker';
import { useTranslation } from 'react-i18next';
import style from './style.scss';

const FilterBlock = ({
    date,
    setDate,
    setMaster,
    master,
    masters = [],
    rightAlign = false,
    variant = '',
    dateFree,
    setDateFree,
    summ,
    setSumm,
    numVisits,
    setNumVisits,
}) => {
    const { t } = useTranslation();
    return (
        <Grid container className={style.filterContainer}>
            <Grid item xs={12} className={style.filter}>
                <Select
                    variant={master ? 'contained' : variant}
                    color={master ? 'primary' : ''}
                    placeholder={t('analytics_all_staff')}
                    value={master}
                    onChange={setMaster}
                    options={[...masters]}
                />

                <SimpleInput
                    variant={numVisits.from || numVisits.to ? 'contained' : variant}
                    color={numVisits.from || numVisits.to ? 'primary' : ''}
                    placeholder={t('filter_by_number_of_visits')}
                    imputsProps={{ size: 'small' }}
                    inputStartProps={{ placeholder: '', label: t('generic_from') }}
                    inputEndProps={{ placeholder: '', label: t('generic_to') }}
                    value={numVisits}
                    onChange={summ => {
                        setNumVisits(summ);
                    }}
                />

                <DatePicker
                    rightAlign={rightAlign}
                    onChange={data => {
                        setDate({
                            from: moment(data[0].startDate),
                            to: moment(data[0].endDate),
                        });
                    }}
                    variant={date.from || date.to ? 'contained' : variant}
                    color={date.from || date.to ? 'primary' : ''}
                    date={date}
                    placeholder={t('filter_by_date_of_visit')}
                    buttonProps={{
                        endIcon: <ExpandMoreIcon color={date.from || date.to ? '' : 'primary'} />,
                        classes: {},
                    }}
                />

                <DatePicker
                    rightAlign={rightAlign}
                    variant={dateFree.from || dateFree.to ? 'contained' : variant}
                    color={dateFree.from || dateFree.to ? 'primary' : ''}
                    onChange={data => {
                        setDateFree({
                            from: moment(data[0].startDate),
                            to: moment(data[0].endDate),
                        });
                    }}
                    date={dateFree}
                    placeholder={t('filter_by_date_of_absence')}
                    buttonProps={{
                        endIcon: (
                            <ExpandMoreIcon color={dateFree.from || dateFree.to ? '' : 'primary'} />
                        ),
                        classes: {},
                    }}
                />

                <SimpleInput
                    placeholder={<>{t('filter_by_the_amount_per_service')}</>}
                    inputsProps={{ type: 'number' }}
                    inputStartProps={{ placeholder: '', label: t('generic_from') }}
                    inputEndProps={{ placeholder: '', label: t('generic_to') }}
                    variant={summ.from || summ.to ? 'contained' : variant}
                    color={summ.from || summ.to ? 'primary' : ''}
                    value={summ}
                    onChange={summ => {
                        setSumm(summ);
                    }}
                />
            </Grid>
        </Grid>
    );
};

FilterBlock.propTypes = {
    date: PropTypes.object,
    setDate: PropTypes.func,
    services: PropTypes.array,
    setSkill: PropTypes.func,
    setMaster: PropTypes.func,
    master: PropTypes.object,
    skill: PropTypes.object,
    masters: PropTypes.array,
    rightAlign: PropTypes.bool,
    variant: PropTypes.string,
    dateFree: PropTypes.object,
    setDateFree: PropTypes.func,
    summ: PropTypes.object,
    setSumm: PropTypes.func,
    numVisits: PropTypes.object,
    setNumVisits: PropTypes.func,
};

export default FilterBlock;
