import React, { useCallback } from 'react';
import { CommentItem } from 'components';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';

import { useSelector, useDispatch } from 'react-redux';

import {
    getClientInfoRequest,
    getClientInfoSelector,
    addClientNoteRequest,
    updateClientNoteRequest,
    deleteClientNoteRequest,
} from 'modules/clients';
import style from './style.scss';
import { Add } from '@material-ui/icons';

interface ClientNotesProps {
    user_id: number;
    salon_id: number;
}

const ClientNotes = ({ user_id, salon_id }: ClientNotesProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const client = useSelector(getClientInfoSelector) as any;
    const [clientComments, setClientComments] = React.useState<any[]>([]);

    React.useEffect(() => {
        if (!!client?.client_notes?.length) {
            const notes = client.client_notes
                .map((note: any) => ({ ...note, mode: 'view' }))
                .sort(
                    (a: any, b: any) =>
                        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
                );
            setClientComments(notes);
        }
    }, [client]);

    const addClientComment = () => {
        const newComment = { note: '', mode: 'add', id: 'new' };
        setClientComments((prev: any[]) => [newComment, ...prev]);
    };
    const updateClientComment = useCallback(
        (id: string | null, value: string) => {
            console.log(id, value);
            dispatch(
                updateClientNoteRequest(
                    { clientId: user_id, note: value, id: id },
                    {
                        onSuccess: () => {
                            dispatch(getClientInfoRequest({ id: salon_id, user_id: user_id }));
                        },
                    }
                )
            );
        },
        [dispatch, salon_id, user_id]
    );
    const deleteClientComment = (id: string) => {
        if (id === 'new') {
            setClientComments((prev: any[]) => prev.slice(1));
            return;
        }
        dispatch(
            deleteClientNoteRequest(
                { id: id },
                {
                    onSuccess: () => {
                        dispatch(getClientInfoRequest({ id: salon_id, user_id: user_id }));
                    },
                }
            )
        );
    };

    const saveClientComment = useCallback(
        (id: string, value: string) => {
            dispatch(
                addClientNoteRequest(
                    { clientId: user_id, note: value },
                    {
                        onSuccess: () => {
                            dispatch(getClientInfoRequest({ id: salon_id, user_id: user_id }));
                        },
                    }
                )
            );
        },
        [dispatch, salon_id, user_id]
    );
    const handleEditComment = (id: string, mode: 'edit' | 'view') => {
        setClientComments((prev: any[]) => {
            const newComments = prev.map((item: any) => {
                if (item.id === id) {
                    return { ...item, mode: mode };
                }
                return item;
            });
            return newComments;
        });
    };

    const handleChangeComment = (id: string, value: string) => {
        setClientComments((prev: any[]) => {
            const newComments = prev.map((item: any) => {
                if (item.id === id) {
                    return { ...item, note: value };
                }
                return item;
            });
            return newComments;
        });
    };

    return (
        <div>
            <div className={style.commentInfo}>
                <span>
                    {t('client_detail_comments')}{' '}
                    <span className={style.count}>{clientComments.length}</span>
                </span>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={addClientComment}
                    startIcon={<Add />}
                    size="small"
                    className={style.addFeedbackButton}
                    disabled={clientComments?.some(item => item.mode === 'add')}
                >
                    {t('client_detail_add_comment')}
                </Button>
            </div>
            <div>
                {clientComments.map((item: any) => (
                    <CommentItem
                        key={item.id}
                        value={item.note}
                        id={item.id}
                        onChange={handleChangeComment}
                        mode={item.mode}
                        onSave={saveClientComment}
                        date={item.updated_at || ''}
                        onEdit={handleEditComment}
                        onUpdate={updateClientComment}
                        onDelete={deleteClientComment}
                        tFunction={t}
                    />
                ))}
            </div>
        </div>
    );
};

export default ClientNotes;
