import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { initSubscriptionSessionRequest } from 'modules/subscription';
import style from './style.module.scss';
import { PopupBackground } from 'feelqueen_components';
import ClearIcon from '@material-ui/icons/Clear';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CrmSystemIcon from 'static/icons/CrmSystemIcon.svg';
import OnlineBookingIcon from 'static/icons/OnlineBookingIcon.svg';
import MobileAppIcon from 'static/icons/MobileAppIcon.svg';
import AwareIcon from 'static/icons/AwareIcon.svg';

const SubscriptionPopup = ({ onClose, id, showPopup, history }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const onStartTrialPeriod = () => {
        dispatch(
            initSubscriptionSessionRequest(
                { id },
                {
                    onSuccess: () => {
                        onClose();
                        history.push('/start-subscription');
                    },
                }
            )
        );
    };

    if (!showPopup) return null;

    return (
        <>
            <PopupBackground
                popupBackgroundsProps={{ onClick: onClose }}
                visible
                onClick={onClose}
                childrenClassName={style.popupBackground}
            >
                <Grid container direction="column" className={style.container}>
                    <Grid item xs={12}>
                        <div className={style.clearIconContainer}>
                            <IconButton size="small" onClick={onClose}>
                                <ClearIcon className={style.clearIcon} />
                            </IconButton>
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <div className={style.title}>{t('d_paywall_title')}</div>
                    </Grid>

                    <Grid item xs={12} className={style.listContainer}>
                        <div className={style.listItem}>
                            <img src={CrmSystemIcon} alt="crm" />
                            <div className={style.listItemText}>
                                {t('d_paywall_feature1_label')}
                            </div>
                        </div>

                        <div className={style.listItem}>
                            <img src={OnlineBookingIcon} alt="online" />
                            <div className={style.listItemText}>
                                {t('d_paywall_feature2_label')}
                            </div>
                        </div>

                        <div className={style.listItem}>
                            <img src={MobileAppIcon} alt="mobile" />
                            <div className={style.listItemText}>
                                {t('d_paywall_feature3_label')}
                            </div>
                        </div>

                        <div className={style.listItem}>
                            <img src={AwareIcon} alt="AwareIcon" />
                            <div className={style.listItemText}>
                                {t('d_paywall_feature4_label')}
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <div className={style.priceContainer}>
                            <span className={style.price}>149 AED</span>
                            <span className={style.slash}>/</span>
                            <span className={style.period}>month</span>
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            color="primary"
                            variant="contained"
                            className={style.button}
                            onClick={onStartTrialPeriod}
                        >
                            {t('d_subscription_section_purchase_button')}
                        </Button>
                    </Grid>
                </Grid>
            </PopupBackground>
        </>
    );
};

SubscriptionPopup.propTypes = {
    showPopup: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    id: PropTypes.number,
    history: PropTypes.object,
};

export default SubscriptionPopup;
