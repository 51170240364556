//TODO: разделить на компоненты
import React from 'react';

import Create from './Create';
import Edit from './Edit';

const Event = ({
    match: {
        params: { eventId },
        ...rest
    },
    ...props
}: {
    match: {
        params: {
            eventId: string;
        };
    };
    history: any;
}) => {
    const isCreateEvent = eventId === 'add';
    const history = props.history;
    return isCreateEvent ? (
        <Create history={history} />
    ) : (
        <Edit eventId={eventId} history={history} />
    );
};

export default Event;
