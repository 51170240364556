import React from 'react';
import style from './style.scss';

const InfoCardLine = ({
    title,
    value,
}: {
    title: string | React.ReactNode;
    value: string | React.ReactNode;
}) => {
    return (
        <div className={style.container}>
            <div className={style.title}>{title}</div>
            <div className={style.value}>{value}</div>
        </div>
    );
};

export default InfoCardLine;
