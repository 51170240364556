import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { CalendarTimePicker } from 'components';
import { SalonInterface } from 'modules/salon/interface';
import { getSalonFreeTimesSalonRequest, getSalonsFreeTime } from 'modules/salon';
import { getSystemLang } from 'helpers/getSystemLang';

import style from './style.scss';

const dateFormat = 'YYYY-MM-DD HH:mm:ss';

const SelectDate = ({
    setFieldValue,
    values,
    errors,
    salonData,
    disabled = false,
}: {
    setFieldValue: (field: string, value: any) => void;
    values: Record<string, any>;
    errors: Record<string, string | undefined>;
    salonData: SalonInterface;
    disabled?: boolean;
}) => {
    const { id } = salonData;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const date = values['date'];
    const master = values['master'];
    const selectedSkills = values['skills'];
    const selectedCustomSkills = values['custom_skills'];
    const lang = getSystemLang();
    const onChangeDate = (date: Date) => {
        setFieldValue('date', moment(date).format('YYYY-MM-DD HH:mm'));
    };
    const freeTime = useSelector(getSalonsFreeTime);
    const textError = errors['date'];

    const onChangeSelectedDayInPicker = (day: any) => {
        if (id) {
            dispatch(
                getSalonFreeTimesSalonRequest({
                    id,
                    start_date: moment(day)
                        .startOf('month')
                        .format(dateFormat),
                    skills: Array.isArray(selectedSkills) ? selectedSkills : undefined,
                    services: Array.isArray(selectedCustomSkills)
                        ? selectedCustomSkills
                        : undefined,
                    masters: master || undefined,
                })
            );
        }
    };

    React.useEffect(() => {
        if (date && date !== '' && date !== null && date !== undefined) {
            onChangeSelectedDayInPicker(date);
        } else {
            if (id) {
                onChangeSelectedDayInPicker(new Date().toISOString());
            }
        }
    }, [date, id]);

    return (
        <>
            <Grid container className={style.calendarContainer}>
                <Grid item xs={12}>
                    <CalendarTimePicker
                        value={date}
                        freeTime={freeTime}
                        onChange={onChangeDate}
                        disabled={disabled}
                        variant="outlined"
                        isMobile={false}
                        onChangeDay={onChangeSelectedDayInPicker}
                        disableBeforeNow
                        interval={30}
                        pickerProps={{
                            maxDate: moment(new Date())
                                .add(3, 'M')
                                .toDate(),
                        }}
                    >
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            disabled={disabled}
                            value={
                                date
                                    ? moment(date)
                                          .locale(lang)
                                          .format('DD MMMM YYYY HH:mm')
                                    : undefined
                            }
                            label={t('generic_date')}
                            InputProps={{
                                disabled: disabled,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <CalendarTodayIcon htmlColor={'#767676'} />
                                    </InputAdornment>
                                ),
                            }}
                            helperText={textError}
                            error={!!textError}
                            style={{ width: '77%' }}
                        />
                    </CalendarTimePicker>
                </Grid>
            </Grid>
        </>
    );
};

export default SelectDate;
