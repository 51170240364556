import { PRICE_RANGES } from '../../config/countries';

const by = {
    range: PRICE_RANGES['BYN'],
    currency: 'BYN',
    currency_id: 1,
    country: 'Беларусь',
    countryCode: 'by',
    phoneCode: '375',
    defaultCity: 'Минск',
};

const uk = {
    range: PRICE_RANGES['GBP'],
    currency: 'GBP',
    currency_id: 5,
    country: 'United Kingdom',
    countryCode: 'uk',
    phoneCode: '44',
    defaultCity: 'London',
};

const pl = {
    range: PRICE_RANGES['PLN'],
    currency: 'PLN',
    currency_id: 6,
    country: 'Poland',
    countryCode: 'pl',
    phoneCode: '48',
    defaultCity: 'Warsaw',
};

const ae = {
    range: PRICE_RANGES['AED'],
    currency: 'AED',
    currency_id: 7,
    country: 'United Arab Emirates',
    countryCode: 'ae',
    phoneCode: '971',
    defaultCity: 'Dubai',
};

const ru = {
    range: PRICE_RANGES['RUB'],
    currency_id: 2,
    currency: 'RUB',
    country: 'Россия',
    countryCode: 'ru',
    phoneCode: '7',
    scaleSlider: x => {
        let res = x;
        switch (true) {
            case x <= 2000:
                res = 50 * x;
                break;
            case x <= 5000:
                res = 200 * x;
                break;
            case x <= 20000:
                res = 500 * x;
                break;
            default:
                break;
        }
        return res;
    },
    stepSlider: 50,
    defaultCity: 'Moscow',
};

export const getCoubtryByCurrency = currency => {
    switch (true) {
        case currency && currency === 1:
            return by;
        case currency && currency === 5:
            return uk;
        case currency && currency === 6:
            return pl;
        case currency && currency === 7:
            return ae;
        case currency && currency === 2:
            return ru;
        default:
            return null;
    }
};

export const getCountryByCode = code => {
    switch (true) {
        case code === 'by':
            return by;
        case code === 'uk':
            return uk;
        case code === 'pl':
            return pl;
        case code === 'ae':
            return ae;
        case code === 'ru':
            return ru;
        default:
            return null;
    }
};

export const getCountryByLanguage = language => {
    switch (true) {
        case language === 'ru':
            return ru;
        case language === 'en':
            return uk;
        case language === 'pl':
            return pl;
        case language === 'ar':
            return ae;
        case language === 'be':
            return by;
        default:
            return null;
    }
};

export const getDefaultCityByCode = code => {
    switch (true) {
        case code === 'by':
            return 'Минск';
        case code === 'uk':
            return 'London';
        case code === 'pl':
            return 'Warsaw';
        case code === 'ae':
            return ae;
        case code === 'Dubai':
            return 'Москва';
        default:
            return null;
    }
};
