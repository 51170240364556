import * as React from 'react';

function SvgUilHeart({ htmlColor, ...props }) {
    return (
        <svg
            viewBox="2 2 20 20"
            width={24}
            height={25}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M20.165 5.477a6.25 6.25 0 0 0-3.963-1.858 6.223 6.223 0 0 0-4.2 1.208 6.202 6.202 0 0 0-8.495.955 6.429 6.429 0 0 0-1.514 4.403 6.414 6.414 0 0 0 1.848 4.27l6.211 6.317c.52.52 1.221.81 1.95.81.73 0 1.431-.29 1.951-.81l6.212-6.317a6.418 6.418 0 0 0 1.823-4.49 6.418 6.418 0 0 0-1.823-4.488zm-1.41 7.576l-6.212 6.307a.759.759 0 0 1-.833.17.758.758 0 0 1-.247-.17L5.25 13.023a4.392 4.392 0 0 1-1.224-3.047c0-1.14.44-2.233 1.224-3.047a4.238 4.238 0 0 1 3-1.25c1.124 0 2.202.449 3.002 1.25a1 1 0 0 0 .71.3.987.987 0 0 0 .71-.3 4.238 4.238 0 0 1 3-1.25c1.124 0 2.202.449 3.001 1.25a4.39 4.39 0 0 1 1.264 3.03 4.394 4.394 0 0 1-1.183 3.064v.03z"
                fill={htmlColor}
            />
        </svg>
    );
}

export default SvgUilHeart;
