import { useState } from 'react';
import { Skeleton } from 'components';
import { useTranslation } from 'react-i18next';
import { Grid, Button, FormHelperText } from '@material-ui/core';
import { useCreateEvent } from './hooks';
import SelectKills from './components/SelectSkills';
import SelectClient from '../components/SelectClient';
import SelectMaster from '../components/SelectMaster';
import SelectDate from '../components/SelectDate';
import SelectDuration from '../components/SelectDuration';
import Comments from '../components/Comments';
import SubmitPanel from '../components/SubmitPanel';
import style from './style.scss';
import SubscriptionPopup from 'components/SubscriptionPopup';

const Create = ({ history }: { history: any }) => {
    const { t } = useTranslation();
    const [isShowSubscriptionPopup, setShowSubscriptionPopup] = useState(false);
    const { handleSubmit, values, errors, touched, setFieldValue, salonData } = useCreateEvent({
        history,
    });
    const { id } = salonData;
    const subscription_status = true;
    // const { subscription_status, id } = salonData;

    const textError =
        Object.entries(errors).filter(i => i[1])?.length > 0 ? 'Fill in the required fields' : '';

    return (
        <>
            <Skeleton
                textError={textError}
                backgroundColor="#fffaf6"
                title={t('generic_new_appointment')}
                showBreadcump
                showTitle
                nextButtonText={false}
                subTitle=""
                bottomPositionButtons={false}
                breadcamps={[{ link: '/calendar', title: t('menu_calendar') }]}
                headerChildren={<></>}
                onNext={false}
            >
                <Grid container spacing={6}>
                    <Grid item xs={7}>
                        <Grid container>
                            <Grid item xs={10}>
                                <SelectKills
                                    errors={errors}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    salonData={salonData}
                                />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={10}>
                                <SelectClient
                                    errors={errors}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    salonData={salonData}
                                />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={10}>
                                <SelectMaster
                                    errors={errors}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    salonData={salonData}
                                />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={10}>
                                <SelectDate
                                    errors={errors}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    salonData={salonData}
                                />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={10}>
                                <SelectDuration
                                    errors={errors}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    disabled={
                                        values['skills']?.length === 0 &&
                                        values['services']?.length === 0
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Comments values={values} setFieldValue={setFieldValue} />
                    </Grid>
                    <Grid item xs={5}>
                        <SubmitPanel
                            setFieldValue={setFieldValue}
                            values={values}
                            errors={errors}
                            salonData={salonData}
                            handleSubmit={handleSubmit}
                            // disabled
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                className={style.bookButton}
                                onClick={
                                    !subscription_status
                                        ? () => setShowSubscriptionPopup(true)
                                        : (e: any) => handleSubmit(e)
                                }
                            >
                                {t('event_book')}
                            </Button>
                            <FormHelperText error>{textError}</FormHelperText>
                            {/* <span className={style.textInfo}> {textInfo}</span> */}
                        </SubmitPanel>
                    </Grid>
                </Grid>
            </Skeleton>
            <SubscriptionPopup
                showPopup={isShowSubscriptionPopup}
                id={id}
                onClose={() => setShowSubscriptionPopup(false)}
                history={history}
            />
        </>
    );
};

export default Create;
