import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import moment from 'moment';
import { NumberFormat, EventStatusLabel } from 'components';
import style from './style.scss';
import { useTranslation } from 'react-i18next';
import { getSystemLang } from 'helpers/getSystemLang';

const EventItem = ({ data, history, showMaster = false, isDisableClick = false }) => {
    const { id, date, status_id, master, skills, services = [], amount, user } = data;
    const { t } = useTranslation();
    const lang = getSystemLang();
    return (
        <div
            className={style.container}
            onClick={() => {
                if (isDisableClick) return;
                history.push('/event/' + id);
            }}
        >
            <div className={style.headItem}>
                <span className={style.itemDate}>
                    {moment(date)
                        .locale(lang)
                        .format('DD MMMM YYYY, HH:mm')}
                </span>
                <EventStatusLabel
                    statusId={moment(date).toDate() < new Date() && status_id === 3 ? 7 : status_id}
                />
            </div>
            <div className={style.item}>
                <span>
                    <span className={style.title}>{t('generic_client')}:</span>
                    <span className={style.name}>{`${
                        showMaster
                            ? get(master, 'first_name', '')
                            : get(user, 'first_name', '') || ''
                    } ${
                        showMaster ? get(master, 'last_name', '') : get(user, 'last_name', '') || ''
                    }`}</span>
                </span>
            </div>
            <div className={style.item}>
                <span className={style.title}>Услуга:</span>
                <span className={style.itemText}>
                    {' '}
                    {[...skills, ...services].map(i => i.title).join(', ')}
                </span>
            </div>
            <div className={style.item}>
                <span className={style.title}>{t('generic_price')}:</span>
                <span className={style.itemText}>
                    <NumberFormat value={amount} />
                </span>
            </div>
        </div>
    );
};

EventItem.propTypes = {
    data: PropTypes.object,
    history: PropTypes.object,
    showMaster: PropTypes.bool,
};

export default EventItem;
