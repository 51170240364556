import React from 'react';
import PropTypes from 'prop-types';
import AutocompleteSelect from '../../AutocompleateSelect';
import { getSystemLang } from 'helpers/getSystemLang';

const countries = [
    { value: 'Беларусь', label: 'Беларусь' },
    { value: 'Россия', label: 'Россия' },
    { value: 'Объединённые Арабские Эмираты', label: 'Объединённые Арабские Эмираты' },
    { value: 'Польша', label: 'Польша' },
];
const countriesEn = [
    { value: 'United Kingdom', label: 'United Kingdom' },
    { value: 'Belarus', label: 'Belarus' },
    { value: 'United Arab Emirates', label: 'United Arab Emirates' },
    { value: 'Poland', label: 'Poland' },
];

const CountryAutoCompleate = ({
    onChange,
    value = '',
    name = '',
    label = 'Страна',
    placeholder = 'Например, Россия',
    required,
    helperText,
}) => {
    const [country, setCountry] = React.useState(value);
    const lang = getSystemLang();

    React.useEffect(() => {
        setCountry(value);
    }, [value]);
    React.useEffect(() => {
        onChange(country);
    }, [country]);

    return (
        <AutocompleteSelect
            options={lang === 'en' ? countriesEn : countries}
            inputValue={country}
            onInputChange={(event, newInputValue) => {
                setCountry(newInputValue);
            }}
            textFieldProps={{
                required,
                helperText,
                label,
                placeholder,
                autoComplete: 'off',
                name: name,
                margin: 'normal',
            }}
        />
    );
};

CountryAutoCompleate.defaultProps = {
    onChange: () => {},
};
CountryAutoCompleate.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    helperText: PropTypes.string,
};

export default CountryAutoCompleate;
