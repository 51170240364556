import React from 'react';
import { useTranslation } from 'react-i18next';
import { UserInfo } from 'components';
import { useSelector, useDispatch } from 'react-redux';
import { Label } from 'components';
import { SalonInterface } from 'modules/salon/interface';
import { getSalonMastersSelector, getMastersRequest } from 'modules/masters';
import AddButton from '../AddButton';
import AddPopup from '../AddPopup';

import style from './style.scss';

const SelectMaster = ({
    setFieldValue,
    values,
    errors,
    salonData,
    disabled = false,
}: {
    setFieldValue: (field: string, value: any) => void;
    values: Record<string, any>;
    errors: Record<string, string | undefined>;
    salonData: SalonInterface;
    disabled?: boolean;
}) => {
    const { id } = salonData;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const masterId = values['master_id'];
    const skills = values['skills'];
    const services = values['services'];
    const [showAddMasterPopup, switchAddMasterPopup] = React.useState(false);
    const masters = useSelector<any, any[]>(getSalonMastersSelector);
    const selectedSkills = values['skills'] || [];
    const selectedCustomSkills = values['custom_skills'] || [];

    const master = masters.find(i => i.id == masterId);

    React.useEffect(() => {
        if (id) {
            dispatch(
                getMastersRequest({
                    id,
                    skills,
                    services,
                    showLoader: false,
                })
            );
        }
    }, [id, dispatch, skills, services]);

    const textError = errors['master_id'] ? t('search_choose_a_beautician') : '';

    const filtredMasters = React.useMemo(() => {
        return masters.filter(i => i.status === 'confirmed');
    }, [masters]);

    return (
        <>
            <div className={style.itemContainer}>
                <Label fontSize={20} title={masterId && t('generic_beautician')}>
                    <div className={masterId ? style.selectedItemsContainer : ''}>
                        {masterId ? <UserInfo data={master} isMaster /> : <></>}
                        <AddButton
                            textError={textError}
                            disabled={disabled}
                            onClick={() => switchAddMasterPopup(!showAddMasterPopup)}
                            variant={masterId ? 'button' : false}
                            titleLine={t('search_add_a_beautician')}
                            titleButton={t('generic_edit')}
                        />
                    </div>
                </Label>
            </div>
            {showAddMasterPopup && (
                <AddPopup
                    title={t('event_select_a_beautician')}
                    isMaster
                    placeholder={
                        selectedSkills.length > 0 || selectedCustomSkills.length > 0
                            ? t('search_we_found_no_beaut')
                            : ''
                    }
                    items={filtredMasters}
                    onSubmit={i => {
                        setFieldValue('master_id', i.id);
                        switchAddMasterPopup(!showAddMasterPopup);
                    }}
                    onClose={() => {
                        switchAddMasterPopup(!showAddMasterPopup);
                    }}
                />
            )}
        </>
    );
};

export default SelectMaster;
