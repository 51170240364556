import * as React from 'react';

function SvgUilCalculator({ htmlColor, ...props }) {
    return (
        <svg
            viewBox="2 2 20 20"
            width={24}
            height={24}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M12.827 16.81a.91.91 0 0 0-.145-.11.508.508 0 0 0-.155-.082.547.547 0 0 0-.172-.054.845.845 0 0 0-.519.054.817.817 0 0 0-.49.491.764.764 0 0 0-.073.345.91.91 0 0 0 .063.346c.053.109.12.21.2.3a.91.91 0 0 0 .991.19c.11-.045.212-.11.3-.19a.91.91 0 0 0 .191-.99.91.91 0 0 0-.19-.3zm-3.782-3.746a.512.512 0 0 0-.154-.082.546.546 0 0 0-.173-.073.78.78 0 0 0-.354 0l-.164.055-.164.082-.136.109a.955.955 0 0 0-.196 1.012c.045.11.112.212.196.297.088.08.19.144.3.19a.91.91 0 0 0 1.255-.836.955.955 0 0 0-.264-.645l-.146-.11zm.146 3.745a.907.907 0 0 0-.3-.19.91.91 0 0 0-.527-.073L8.2 16.6a.691.691 0 0 0-.164.082c-.047.033-.093.07-.136.109a.91.91 0 0 0-.19.3.855.855 0 0 0 0 .69.909.909 0 0 0 .836.582.763.763 0 0 0 .345-.072c.11-.046.212-.11.3-.191.08-.088.145-.19.19-.3a.855.855 0 0 0 0-.69.909.909 0 0 0-.19-.3zm2.645-3.827a.91.91 0 0 0-.3.19.955.955 0 0 0-.263.646.91.91 0 0 0 1.254.837c.11-.046.212-.111.3-.191a.91.91 0 0 0 .264-.646.954.954 0 0 0-.264-.645.909.909 0 0 0-.99-.191zm4.628 3.827a1.043 1.043 0 0 0-.3-.19.909.909 0 0 0-.991.19.91.91 0 0 0-.191.3.854.854 0 0 0 0 .691c.046.11.11.212.19.3a.91.91 0 0 0 .646.264.763.763 0 0 0 .346-.073c.11-.046.211-.11.3-.191a.909.909 0 0 0 .19-.99.907.907 0 0 0-.19-.3zm-.646-11.173H8.545a.91.91 0 0 0-.909.91v3.636a.909.909 0 0 0 .91.909h7.272a.909.909 0 0 0 .91-.91V6.546a.91.91 0 0 0-.91-.909zm-.909 3.637H9.455V7.455h5.454v1.818zM17.636 2H6.727A2.727 2.727 0 0 0 4 4.727v14.546A2.727 2.727 0 0 0 6.727 22h10.91a2.727 2.727 0 0 0 2.727-2.727V4.727A2.727 2.727 0 0 0 17.636 2zm.91 17.273a.91.91 0 0 1-.91.909H6.727a.91.91 0 0 1-.909-.91V4.728a.91.91 0 0 1 .91-.909h10.908a.91.91 0 0 1 .91.91v14.545zm-2.228-6.21a.511.511 0 0 0-.154-.081.544.544 0 0 0-.173-.055.78.78 0 0 0-.355 0l-.163.055-.164.082-.136.109a.954.954 0 0 0-.264.645.908.908 0 0 0 1.255.837c.11-.046.211-.111.3-.191a.908.908 0 0 0 .263-.646.955.955 0 0 0-.263-.645l-.146-.11z"
                fill={htmlColor}
            />
        </svg>
    );
}

export default SvgUilCalculator;
