import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import { InfoCard, NumberFormat } from 'components';
import { DownloadLink } from 'feelqueen_components';
import { useTranslation } from 'react-i18next';
import style from './style.scss';
import { getSystemLang } from 'helpers/getSystemLang';

const Docs = ({ data }) => {
    const { t } = useTranslation();
    const lang = getSystemLang();

    return (
        <InfoCard title={t('payment_bills_and_acts')} minHeight="377px" showMenu={false}>
            <Grid item xs={12} className={style.dataContainerSmall}>
                <table className={style.table}>
                    <thead>
                        <tr>
                            <th className={style.tableHead} scope="col">
                                {t('generic_date')}
                            </th>
                            <th className={style.tableHead} scope="col">
                                {t('payment_amount')}
                            </th>
                            <th className={style.tableHead} scope="col" />
                            <th className={style.tableHead} scope="col" />
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((i, index) => (
                            <tr key={index}>
                                <td className={style.tableCell}>
                                    {moment(i.date).format('DD MMM YYYY')}
                                </td>
                                <td className={style.tableCellSumm}>
                                    <NumberFormat value={i.amount} />
                                </td>
                                <td className={style.tableCell}>
                                    {!lang === 'en' ? (
                                        <DownloadLink
                                            align="left"
                                            href={i.bill_path}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {t('payment_bill')}
                                        </DownloadLink>
                                    ) : (
                                        <div style={{ width: '100px' }} />
                                    )}
                                </td>
                                <td className={style.tableCell}>
                                    {!lang === 'en' ? (
                                        <DownloadLink
                                            align="left"
                                            href={i.act_path}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {t('payment_act')}
                                        </DownloadLink>
                                    ) : (
                                        <DownloadLink
                                            align="left"
                                            href={i.bill_path}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {t('payment_bill')}
                                        </DownloadLink>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Grid>
        </InfoCard>
    );
};

Docs.propTypes = {
    data: PropTypes.object,
};
Docs.defaultProps = { data: [] };

export default Docs;
