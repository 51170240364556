import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { getSalonSelector } from 'modules/salon';
import {
    getPaymentsRequest,
    getTopMasterRequest,
    paymentsSelector,
    topMastersSelector,
    topServicesSelector,
    getTopServicesRequest,
} from 'modules/statistics';
import { getEventsHistoryRequest, getEventsHistorySelector } from 'modules/events';
import { getUserCurrency } from 'modules/user';
import { Skeleton } from 'components';
import TopMastersCard from './components/TopMastersCard';
import StatisticsCard from '../Finance/components/StatiscticsCard';
import EventsHistory from './components/EventsHistory';
import TodayEvents from './components/TodayEvents';
import TopServices from './components/TopServices';
import Scale from '../Finance/components/Scale';
import { useTranslation } from 'react-i18next';
import style from './style.scss';

const Home = ({ viewPort, history }) => {
    const topServices = useSelector(topServicesSelector);
    const { id, currency_id } = useSelector(getSalonSelector);
    const topMasters = useSelector(topMastersSelector);
    const currency = useSelector(getUserCurrency);
    const events = useSelector(getEventsHistorySelector);
    const { t } = useTranslation();
    const payments = useSelector(paymentsSelector);
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (id) {
            dispatch(
                getTopServicesRequest({
                    id,
                    from: moment()
                        .date(0)
                        .format('YYYY-MM-DD HH:mm:ss'),
                    to: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                })
            );
            dispatch(getTopMasterRequest({ id }));
            dispatch(getEventsHistoryRequest({ id }));
            dispatch(
                getPaymentsRequest({
                    id,
                    fromDate: moment()
                        .date(0)
                        .toDate(),
                    toDate: new Date(),
                })
            );
        }
    }, [id]);

    const currencyCurrent = currency || {};

    console.log(currency_id);

    const eventsToday = events.filter(
        i => (i.status_id == 1 || i.status_id == 3) && moment(i.date).isSame(moment(), 'day')
    );
    const eventsHistory = React.useMemo(() => {
        return events.sort(
            (a, b) =>
                -moment(a.updated_at).format('YYYYMMDD') + moment(b.updated_at).format('YYYYMMDD')
        );
    }, [events]);
    return (
        <Skeleton
            bottomPositionButtons={false}
            backgroundColor="#fffaf6"
            textError={''}
            title={t('menu_home')}
            onNext={false}
            subTitle=""
        >
            <Grid container spacing={3} className={style.container}>
                <Grid item xs={8} md={8} lg={9} xl={9}>
                    <Scale
                        date={{
                            from: moment(new Date()).startOf('month'),
                            to: moment(new Date()).endOf('month'),
                        }}
                        viewPort={viewPort}
                        payments={payments}
                        currencyCurrent={currencyCurrent}
                    />
                </Grid>
                <Grid item xs={4} md={4} lg={3} xl={3}>
                    <StatisticsCard payments={payments} currencyCurrent={currencyCurrent} />
                </Grid>
            </Grid>
            <Grid container spacing={3} className={style.container}>
                <Grid item md={6}>
                    <TodayEvents
                        events={eventsToday}
                        currencyCurrent={currencyCurrent}
                        onClick={id => {
                            history.push('/event/' + id);
                        }}
                    />
                    <TopServices topServices={topServices} />
                </Grid>
                <Grid item md={6}>
                    <EventsHistory
                        events={eventsHistory}
                        currencyCurrent={currencyCurrent}
                        onClick={id => {
                            history.push('/event/' + id);
                        }}
                    />
                    <TopMastersCard topMasters={topMasters} currencyCurrent={currencyCurrent} />
                </Grid>
            </Grid>
        </Skeleton>
    );
};

Home.propTypes = {
    viewPort: PropTypes.object,
};

export default Home;
