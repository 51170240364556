export const getSalonsSkills = (skills, skillsSalon, injectedCustomSkills) => {
    return skills
        .reduce((acc, i) => {
            return [
                ...acc,
                {
                    ...i,
                    sub_skills: [
                        ...i.sub_skills.reduce((acc, sub) => {
                            const item = skillsSalon.find(skill => {
                                return skill.id == sub.id;
                            });
                            return item
                                ? [
                                      ...acc,
                                      {
                                          ...sub,
                                          price: item?.pivot?.price,
                                          duration: item?.pivot?.duration,
                                      },
                                  ]
                                : acc;
                        }, []),
                        ...injectedCustomSkills
                            .filter(item => item.parent_uid == i.uid)
                            .map(i => ({ ...i, is_unique_custom_service: true })),
                    ],
                },
            ];
        }, [])
        .filter(i => i.sub_skills.length > 0);
};

export const getAllSkills = skills => {
    return skills.reduce((acc, item) => [...acc, ...item.sub_skills], []);
};
export const getParentsSkillsBySkillsUID = (skills, allSkills) => {
    return allSkills
        .map(({ uid, title, id }) => ({
            id,
            uid,
            title,
        }))
        .filter(i => {
            let count = 0;
            skills.forEach(el => {
                if (el.parent_uid === i.uid) {
                    count++;
                }
            });
            i.count = count;
            return skills.find(item => item.parent_uid == i.uid);
        });
};
