import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import CosmeticsIcon from 'static/icons/CosmeticsIcon.svg';
import MakeUpIcon from 'static/icons/MakeUpIcon.svg';
import MassageIcon from 'static/icons/MassageIcon.svg';
import NailIcon from 'static/icons/NailIcon.svg';
import ScissorsIcon from 'static/icons/ScissorsIcon.svg';
import SpaIcon from 'static/icons/SpaIcon.svg';
import TattooIcon from 'static/icons/TattooIcon.svg';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getSalonSelector } from 'modules/salon';
import style from './style.module.scss';
import { getCurrencySuffix } from 'helpers/getCurrencySuffix';

const useStyles = makeStyles(theme => ({
    root: {
        fontSize: '12px !important',
        borderRadius: '6px',
    },
    input: {
        paddingTop: '9px',
        paddingBottom: '9px',
    },
    icon: { width: '35px', height: '35px' },
}));
const saveNumber = ({ setNumber, ev }) => {
    const str = ev.target.value || '';
    setNumber(str.replace(/[^0-9.]/g, ''));
};

const SubItem = ({
    data,
    selected,
    setSelected,
    showInputs,
    inputComponent,
    inputProps,
    priceProps,
    durationProps,
    showInputsForce,
    InputPriceProps,
    InputDurationProps,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { currency_id } = useSelector(getSalonSelector);
    const isSelected = React.useMemo(() => {
        return selected.find(i => i.id === data.id);
    }, [selected]);
    const setPrice = price => {
        setSelected([...selected.filter(i => i.id !== isSelected.id), { ...isSelected, price }]);
    };
    const setDuration = duration => {
        setSelected([...selected.filter(i => i.id !== isSelected.id), { ...isSelected, duration }]);
    };
    const { price, duration } = isSelected || {};

    const hangleClick = () => {
        setSelected(
            !isSelected
                ? [
                      ...selected,
                      showInputsForce
                          ? {
                                id: data.id,
                                price: priceProps.defaultValue || 0,
                                duration: durationProps.defaultValue || 0,
                                is_unique_custom_service: data.is_unique_custom_service,
                            }
                          : { id: data.id },
                  ]
                : selected.filter(i => i.id !== data.id)
        );
    };

    const getIcon = () => {
        switch (data.parent_uid) {
            case 'hair':
                return <img src={ScissorsIcon} alt="" width="35px" height="35px" />;
            case 'massage':
                return <img src={MassageIcon} alt="" width="35px" height="35px" />;
            case 'body':
                return <img src={SpaIcon} alt="" width="35px" height="35px" />;
            case 'nails':
                return <img src={NailIcon} alt="" width="35px" height="35px" />;
            case 'tattoo':
                return <img src={TattooIcon} alt="" width="35px" height="35px" />;
            case 'cosmetology':
                return <img src={CosmeticsIcon} alt="" width="35px" height="35px" />;
            case 'face':
                return <img src={MakeUpIcon} alt="" width="35px" height="35px" />;
            default:
                return <img src={Icon} alt="" width="35px" height="35px" />;
        }
    };

    return (
        <div className={style.item} key={data.id} onClick={hangleClick}>
            <div className={style.titleContainer}>
                <div className={style.iconContainer}>
                    {isSelected ? (
                        <CheckCircleIcon htmlColor="#fa835f" className={classes.icon} />
                    ) : (
                        getIcon()
                    )}
                </div>
                <div className={style.title}> {data.title}</div>
            </div>
            {((isSelected && showInputs) || showInputsForce) && (
                <div
                    className={style.inputs}
                    onClick={ev => {
                        ev.stopPropagation();
                    }}
                >
                    <TextField
                        InputProps={{
                            inputProps: {
                                displayType: 'input',
                            },
                            classes: {
                                root: classes.root,
                                input: classes.input,
                            },
                            inputComponent: inputComponent,
                            ...InputPriceProps,
                        }}
                        value={price}
                        onChange={ev => saveNumber({ ev, setNumber: setPrice })}
                        placeholder={`${t('event_price')}, ${getCurrencySuffix(currency_id)}`}
                        variant="outlined"
                        size="small"
                        onFocus={() => {
                            setSelected(
                                !isSelected
                                    ? [
                                          ...selected,
                                          {
                                              id: data.id,
                                              price: priceProps.defaultValue || 0,
                                              duration: durationProps.defaultValue || 0,
                                              is_unique_custom_service:
                                                  data.is_unique_custom_service,
                                          },
                                      ]
                                    : [...selected]
                            );
                        }}
                        {...inputProps}
                        {...priceProps}
                    />
                    <span className={style.divider}> / </span>
                    <TextField
                        value={duration}
                        onChange={ev => saveNumber({ ev, setNumber: setDuration })}
                        placeholder={t('generic_time_min')}
                        InputProps={{
                            classes: {
                                root: classes.root,
                                input: classes.input,
                            },
                            inputProps: {
                                displayType: 'input',
                                suffix: ` ${t('generic_min')}`,
                            },
                            inputComponent: inputComponent,
                            ...InputDurationProps,
                        }}
                        onFocus={() => {
                            setSelected(
                                !isSelected
                                    ? [
                                          ...selected,
                                          {
                                              id: data.id,
                                              price: priceProps.defaultValue || 0,
                                              duration: durationProps.defaultValue || 0,
                                              is_unique_custom_service:
                                                  data.is_unique_custom_service,
                                          },
                                      ]
                                    : [...selected]
                            );
                        }}
                        variant="outlined"
                        size="small"
                        {...inputProps}
                        {...durationProps}
                    />
                </div>
            )}
        </div>
    );
};

SubItem.propTypes = {
    data: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    selected: PropTypes.array.isRequired,
    setSelected: PropTypes.func.isRequired,
    showInputs: PropTypes.bool,
    inputComponent: PropTypes.element,
    inputProps: PropTypes.object,
    showUnselectedPriceDuration: PropTypes.bool,
    priceProps: PropTypes.object,
    durationProps: PropTypes.object,
    showInputsForce: PropTypes.bool,
    InputPriceProps: PropTypes.object,
    InputDurationProps: PropTypes.bool,
};

export default SubItem;
