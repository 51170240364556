import React from 'react';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import IconButton from '@material-ui/core/IconButton';
import SubItem, { SubItemProps, DataInterface, SelectedItemInterface } from './SubItem';
import style from './style.module.scss';

/**
 * Item Component
 *
 * @param {Object} data - The data for the item  for select.
 * @param {boolean} expanded - If true, the item is expanded.
 * @param {boolean} showInputs - If true, inputs are shown.
 * @param {Function} setExpanded - Function to set the item as expanded.
 * @param {any} selected - The selected item.
 * @param {Function} setSelected - Function to set the selected item.
 * @param {ReactNode} itemComponent - The component to be rendered as the item.
 * @param {boolean} [showSelectAll=false] - If true, a select all option is shown.
 * @param {boolean} [showSelectedItemsCount=false] - If true, the count of selected items is shown.
 * @param {number} count - The total count of items.
 * @param {Object} subItemProps - The props for the sub item.
 * @param {boolean} forceExpand - If true, the item is forcibly expanded.
 * @param {boolean} showPrice - If true, the price is shown.
 * @param {boolean} showDuration - If true, the duration is shown.
 */

export interface ItemDataInterFace {
    id: string | number;
    title: string;
    sub_skills: DataInterface[];
    uid: string;
}

interface ItemProps {
    data: ItemDataInterFace;
    expanded: (string | number)[];
    showInputs?: boolean;
    setExpanded: (data: any) => void;
    selected: SelectedItemInterface[];
    setSelected: (data: { id: string | number; is_unique_custom_service?: boolean }[]) => void;
    itemComponent?: any;
    showSelectAll?: boolean;
    showSelectedItemsCount?: boolean;
    count?: number;
    subItemProps?: (data: any) => Record<string, keyof SubItemProps>;
    forceExpand?: boolean;
    showPrice?: boolean;
    showDuration?: boolean;
}

const Item = ({
    data, // data groups of skills (category)
    expanded, // show expanded  groups of skills (category)
    showInputs, // show inputs for skills
    setExpanded, // set expanded  groups of skills (category)
    selected, // selected skills
    setSelected, // set selected skills
    itemComponent, // component for skills  can be custom. default - SubItem
    showSelectAll = false, // show select all checkbox
    showSelectedItemsCount = false, // show count of selected skills
    count, // count of selected skills
    subItemProps, // props for subItem
    forceExpand, // force expand groups of skills (category)
    showPrice, // show price for skills
    showDuration, // show duration for skills
}: ItemProps) => {
    const isExpanded = React.useMemo(() => {
        // is expanded groups of skills (category)
        return forceExpand ? true : expanded.find(i => i == data.id);
    }, [expanded, forceExpand, data.id]);

    const selectedFullPrepared = React.useMemo(() => {
        // selected skills - get list ids of selected skills (used for select all)
        return selected.map(i => i.id);
    }, [selected]);

    const selectedInCategory = React.useMemo(() => {
        // selected skills in category - get list ids of selected skills in current category
        return data.sub_skills.reduce((acc: (string | number)[], i: DataInterface) => {
            const item = selectedFullPrepared.find(item => item === i.id);
            return item ? [...acc, item] : acc;
        }, []);
    }, [selectedFullPrepared, data]);

    const categotyIsSelected = data.sub_skills.length === selectedInCategory.length; // is all skills in category selected

    const selectAll = (ev: any) => {
        // select all skills in category
        ev.stopPropagation();
        if (!categotyIsSelected) {
            setSelected(
                Array.from(
                    new Set([...selectedFullPrepared, ...data.sub_skills.map(i => i.id)])
                ).map(i => ({ id: i }))
            );
        } else {
            setSelected(
                Array.from(
                    new Set([
                        ...selectedFullPrepared.filter(item => {
                            return !data.sub_skills.find(i => i.id == item);
                        }),
                    ])
                ).map(i => ({ id: i }))
            );
        }
    };

    const switchExpand = () => {
        // switch expand groups of skills (category)
        setExpanded(!isExpanded ? [...expanded, data.id] : expanded.filter(i => i !== data.id));
    };

    const subskills = data.sub_skills;

    return (
        <div className={style.container}>
            <div
                className={[style.titleContainer, isExpanded ? style.expanded : ''].join(' ')}
                onClick={switchExpand}
            >
                <div className={style.titleButtons}>
                    {showSelectAll && (
                        <IconButton
                            className={style.selectAllButton}
                            size="small"
                            onClick={selectAll}
                        >
                            {categotyIsSelected ? (
                                <CheckBoxIcon htmlColor={'#FA835F'} />
                            ) : (
                                <CheckBoxOutlineBlankIcon htmlColor={'#FA835F'} />
                            )}
                        </IconButton>
                    )}
                    <div className={style.title}>{data.title}</div>
                </div>
                <div className={style.icons}>
                    {showSelectedItemsCount && (!!count || !!selectedInCategory.length) && (
                        <div className={style.count}>{count || selectedInCategory.length}</div>
                    )}
                    <IconButton size="small">
                        {isExpanded ? (
                            <ExpandLessIcon htmlColor={'#FA835F'} />
                        ) : (
                            <ExpandMoreIcon htmlColor={'#FA835F'} />
                        )}
                    </IconButton>
                </div>
            </div>
            {isExpanded &&
                subskills.map(i =>
                    itemComponent ? (
                        React.createElement(itemComponent, { ...i })
                    ) : (
                        <SubItem
                            showInputs={showInputs}
                            key={i.id}
                            selected={selected}
                            setSelected={setSelected}
                            showDuration={showDuration}
                            showPrice={showPrice}
                            data={i}
                            {...(subItemProps ? subItemProps(i) : {})}
                        />
                    )
                )}
        </div>
    );
};
Item.defaultProps = {
    subItemProps: () => {},
};

Item.propTypes = {
    showInputs: PropTypes.bool,
    itemComponent: PropTypes.any,
    data: PropTypes.object,
    expanded: PropTypes.bool,
    setExpanded: PropTypes.bool,
    selected: PropTypes.array,
    setSelected: PropTypes.func,
    showSelectAll: PropTypes.bool,
    showSelectedItemsCount: PropTypes.bool,
    count: PropTypes.number,
    subItemProps: PropTypes.func,
    forceExpand: PropTypes.bool,
};

export default Item;
