import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getSalonSelector, getSalonRequest } from 'modules/salon';
import { showPopupAction } from 'modules/popups';
import {
    getPaymentDataRequst,
    getOrderRequst,
    getBalanceRequest,
    getPaymentsRequest,
    getBalanceSelector,
    getPaymentsHistorySelector,
    getOrdersSelector,
    getPaymentDataSelector,
    setPaymentRequest,
    initStripePayments,
    initStripePaymentsSelector,
    getStripeLinkRequest,
    getStripeLinkRequestSelector,
} from 'modules/payments';

import { useTranslation } from 'react-i18next';
import { showSuccess } from 'modules/notifications';

export const useHooks = ({ history }) => {
    const {
        id,
        stripe_account,
        subscription_status,
        subscription_ends_at,
        subscription_trial_ends_at,
        ...salon
    } = useSelector(getSalonSelector);
    const initStripeResponse = useSelector(initStripePaymentsSelector);
    const stripeLinkResponse = useSelector(getStripeLinkRequestSelector);
    const { t } = useTranslation();

    const dispatch = useDispatch();

    React.useEffect(() => {
        if (id) {
            dispatch(getPaymentsRequest({ id }));
            dispatch(getOrderRequst({ id }));
            dispatch(getBalanceRequest({ id }));
            dispatch(getPaymentDataRequst({ id }));
        }
    }, [id]);

    React.useEffect(() => {
        if (initStripeResponse.link) {
            window.open(initStripeResponse.link, '_self');
        }
    }, [initStripeResponse.link]);
    React.useEffect(() => {
        if (stripeLinkResponse.link) {
            window.open(stripeLinkResponse.link, '_self');
        }
    }, [stripeLinkResponse.link]);

    const onClickPayment = () => {
        dispatch(
            showPopupAction({
                title: t('payment_fee_payment'),
                message: t('payment_you_can_pay_the_fee'),
                onClick: () => {
                    dispatch(
                        setPaymentRequest(
                            { id },
                            {
                                onSuccess: () => {
                                    dispatch(showSuccess({ message: t('payment_successfully') }));
                                    dispatch(getPaymentsRequest({ id }));
                                    dispatch(getOrderRequst({ id }));
                                    dispatch(getBalanceRequest({ id }));
                                    dispatch(getPaymentDataRequst({ id }));
                                },
                            }
                        )
                    );
                    return true;
                },
                showClear: true,
                align: 'right',
                cancelButtonText: t('generic_cancel'),
                submitButtonText: t('payment_generate_an_act_and_an_bill'),
                showCancel: false,
                // confirmButtonProps: { size: 'small' },
            })
        );
    };
    const initStripePaymentsAccount = id => {
        dispatch(initStripePayments({ id }));
    };
    const getStripeLink = id => {
        dispatch(getStripeLinkRequest({ id }));
    };

    const balance = useSelector(getBalanceSelector);
    const paymentsHistory = useSelector(getPaymentsHistorySelector);
    const orders = useSelector(getOrdersSelector);
    const paymentsData = useSelector(getPaymentDataSelector);

    return {
        balance,
        paymentsHistory,
        orders,
        paymentsData,
        onClickPayment,
        stripe_account,
        subscription_status,
        initStripePaymentsAccount,
        id,
        getStripeLink,
        subscription_ends_at,
        subscription_trial_ends_at,
        getSalonRequest,
    };
};
