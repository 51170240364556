import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { InfoCard, InfoCardLine } from 'components';
import { useTranslation } from 'react-i18next';
import style from './style.scss';

const Clients = ({ data }) => {
    const {
        clients_count,
        new_events_clients_count,
        events_clients_count,
        retention,
        repeated_events_clients_count,
    } = data;
    const { t } = useTranslation();

    return (
        <InfoCard title={t('menu_clients')} minHeight="377px" showMenu={false}>
            <Grid item xs={12} className={style.dataContainerSmall}>
                <InfoCardLine title={t('analytics_added')} value={clients_count} />
                <InfoCardLine title={t('analytics_bookings')} value={events_clients_count} />
                <InfoCardLine
                    title={t('analytics_new_bookings')}
                    value={new_events_clients_count}
                />
                <InfoCardLine
                    title={t('analytics_re-appointment')}
                    value={repeated_events_clients_count}
                />
                <InfoCardLine
                    title={t('analytics_monthly_return')}
                    value={((retention || 0) * 100).toFixed(1) + '%'}
                />
                <InfoCardLine title={''} value={''} />
            </Grid>
        </InfoCard>
    );
};

Clients.propTypes = {
    data: PropTypes.object,
};
Clients.defaultProps = {};

export default Clients;
