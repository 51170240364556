import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from '../../components/Skeleton';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useSelector, useDispatch } from 'react-redux';
import { getSalonSelector } from 'modules/salon';
import { getMastersRequest, getSalonMastersSelector, deleteMasterRequest } from 'modules/masters';
import MasterItem from '../../components/MasterItem';
import { useTranslation } from 'react-i18next';
import style from './style.scss';

const InitMasters = ({ history }) => {
    const dispatch = useDispatch();
    const [textError, setTextError] = React.useState('');
    const { t } = useTranslation();
    const { id } = useSelector(getSalonSelector);
    const masters = useSelector(getSalonMastersSelector);
    React.useEffect(() => {
        if (id) {
            dispatch(getMastersRequest({ id }));
        }
    }, [id]);
    return (
        <Skeleton
            progress={40}
            textError={textError}
            title={t('master_detail_salon_staff')}
            nextButtonText={t('generic_next')}
            backButtonText={t('generic_back')}
            onNext={() => {
                switch (true) {
                    case masters.length == 0:
                        setTextError(t('master_detail_least_one_beautician'));
                        return;
                    default:
                        history.push('/init-portfolio');
                        return;
                }
            }}
            onBack={() => {
                history.push('/init-skills');
            }}
            subTitle={t("master_detail_your_salon's_beauticians")}
        >
            <Grid container>
                <Grid item xs={12} sm={10} md={8} lg={8}>
                    {masters.map(i => (
                        <MasterItem
                            key={i.id}
                            data={i}
                            onEdit={() => {
                                history.push(`/add-master/${i.id}`);
                            }}
                            onDelete={() => {
                                dispatch(deleteMasterRequest({ id, user_id: i.id }));
                            }}
                        />
                    ))}

                    <Button
                        className={style.button}
                        size="small"
                        onClick={() => {
                            history.push('/add-master');
                        }}
                        variant="contained"
                        color="primary"
                    >
                        {`+ ${t('search_add_a_beautician')}`}
                    </Button>
                </Grid>
            </Grid>
        </Skeleton>
    );
};

InitMasters.propTypes = {
    history: PropTypes.object,
};

export default InitMasters;
