import { IconButton, TextField, Tooltip, MenuItem } from '@material-ui/core';
import React from 'react';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import style from './style.scss';

const ReferenceCategoryItem = ({
    mode,
    date,
    value,
    id,
    onChange,
    onSave,
    onEdit,
    onUpdate,
    onDelete,
    tFunction,
    name,
    onItemClick,
}) => {
    const handleEditClick = () => {
        onEdit(id, 'edit');
    };

    const handleSaveClick = () => {
        if (mode === 'edit') {
            onUpdate(id, name);
        } else if (mode === 'add') {
            onSave('new', name);
        }
    };

    const handleDeleteClick = () => {
        if (mode === 'edit') {
            onDelete(id);
        } else if (mode === 'add') {
            onDelete('new');
        }
    };

    const ToolBar = () => {
        return (
            <div className={style.iconsContainer}>
                {mode === 'view' && (
                    <Tooltip title={tFunction('generic_edit')} placement="bottom">
                        <IconButton color="primary" size="small" onClick={handleEditClick}>
                            <EditOutlinedIcon className={style.icon} />
                        </IconButton>
                    </Tooltip>
                )}
                {mode === 'edit' && (
                    <>
                        <Tooltip title={tFunction('generic_cancel')} placement="bottom">
                            <IconButton
                                color="primary"
                                size="small"
                                onClick={event => {
                                    event.stopPropagation();
                                    onEdit(id, 'view');
                                }}
                            >
                                <CancelOutlinedIcon className={style.icon} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={tFunction('generic_save')} placement="bottom">
                            <IconButton color="primary" size="small" onClick={handleSaveClick}>
                                <SaveOutlinedIcon className={style.icon} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={tFunction('generic_delete')} placement="bottom">
                            <IconButton
                                color="primary"
                                size="small"
                                onClick={event => {
                                    event.stopPropagation();
                                    onDelete(id, 'view');
                                }}
                            >
                                <DeleteForeverOutlinedIcon className={style.icon} />
                            </IconButton>
                        </Tooltip>
                    </>
                )}
                {mode === 'add' && (
                    <>
                        <Tooltip title={tFunction('generic_save')} placement="bottom">
                            <IconButton color="primary" size="small" onClick={handleSaveClick}>
                                <SaveOutlinedIcon className={style.icon} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={tFunction('generic_delete')} placement="bottom">
                            <IconButton color="primary" size="small" onClick={handleDeleteClick}>
                                <DeleteForeverOutlinedIcon className={style.icon} />
                            </IconButton>
                        </Tooltip>
                    </>
                )}
            </div>
        );
    };
    return (
        <div className={style.container}>
            {mode === 'view' ? (
                <MenuItem
                    className={style.menuItem}
                    onClick={event => {
                        onItemClick(id, name);
                    }}
                >
                    {name}
                    <ListItemIcon
                        style={{ marginRight: 0 }}
                        onClick={event => {
                            event.stopPropagation();
                        }}
                    >
                        {ToolBar()}
                    </ListItemIcon>
                </MenuItem>
            ) : (
                <TextField
                    value={name}
                    onChange={e => {
                        e.stopPropagation();
                        onChange(id, e.target.value);
                    }}
                    InputProps={{
                        endAdornment: ToolBar(),
                    }}
                    variant="outlined"
                    autoFocus
                    className={style.textField}
                    focused
                    size="small"
                />
            )}
        </div>
    );
};
export default ReferenceCategoryItem;
