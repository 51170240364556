import { ROLES_KEYS } from 'config/roles';
import {
    InitCustomSkills,
    Home,
    RolesList,
    RoleEdit,
    InitProfile,
    InitSkills,
    AuthNew,
    InitMastersInSalon,
    AddMaster,
    InitWorkingTime,
    InitPortfolio,
    Masters,
    Feedbacks,
    Clients,
    ClientDetail,
    Services,
    ServicesEdit,
    Chat,
    Profile,
    WorkingTime,
    ProfileEdit,
    PortfolioEdit,
    Finance,
    Calendar,
    Analitics,
    MasterDetails,
    Event,
    Schedule,
    LayoutEmpty,
    Payments,
    RegisterPayments,
    PaymentsTaxes,
    Subscription,
    EventsList,
} from 'containers';

export const redirectAuthPath = '/auth';

//* layout used for   set layouts for pages
//* if layout ===  false   we use page without layout
//* if layout is  react elemen - we use it
const mainRoutes = [
    {
        path: '/',
        exact: true,
        component: Home,
        isPrivate: true,
        showHeader: true,
        header: true,
        // layout: Tutorial,
    },
    {
        path: '/payments',
        exact: true,
        component: Payments,
        isPrivate: true,
        showHeader: false,
        header: false,
        // layout: Tutorial,
    },
    {
        path: '/payments/success',
        exact: true,
        component: Payments,
        isPrivate: true,
        showHeader: false,
        header: false,
        // layout: Tutorial,
    },
    {
        path: '/payments-register-completed',
        exact: true,
        component: Payments,
        isPrivate: true,
        showHeader: false,
        header: false,
        // layout: Tutorial,
    },
    {
        path: '/payments-register-expired',
        exact: true,
        component: Payments,
        isPrivate: true,
        showHeader: false,
        header: false,
        // layout: Tutorial,
    },
    {
        path: '/payments-register',
        exact: true,
        component: RegisterPayments,
        isPrivate: true,
        showHeader: false,
        header: false,
        layout: LayoutEmpty,
    },
    {
        path: '/payments-taxes',
        exact: true,
        component: PaymentsTaxes,
        isPrivate: true,
        showHeader: false,
        header: false,
    },
    {
        path: '/profile',
        exact: true,
        component: Profile,
        isPrivate: true,
        showHeader: true,
        showFooter: false,
        // layout: false,
    },
    {
        path: '/profile/edit',
        exact: true,
        component: ProfileEdit,
        isPrivate: true,
        showHeader: false,
        showFooter: false,
        // layout: false,
    },
    {
        path: '/profile/portfolio',
        exact: true,
        component: PortfolioEdit,
        isPrivate: true,
        showHeader: false,
        showFooter: false,
        // layout: false,
    },
    {
        path: '/profile/working-time',
        exact: true,
        component: WorkingTime,
        isPrivate: true,
        showHeader: false,
        showFooter: false,
        // layout: false,
    },
    {
        path: '/init-profile',
        exact: true,
        component: InitProfile,
        isPrivate: true,
        header: true,
        layout: LayoutEmpty,
    },
    {
        path: '/init-time',
        exact: true,
        component: InitWorkingTime,
        isPrivate: true,
        header: true,
        layout: LayoutEmpty,
    },
    {
        path: '/init-skills',
        exact: true,
        component: InitSkills,
        isPrivate: true,
        header: true,
        layout: LayoutEmpty,
    },
    {
        path: '/init-masters',
        exact: true,
        component: InitMastersInSalon,
        isPrivate: true,
        header: true,
        layout: LayoutEmpty,
    },
    {
        path: '/init-portfolio',
        exact: true,
        component: InitPortfolio,
        isPrivate: true,
        header: true,
        layout: LayoutEmpty,
    },
    {
        path: '/add-master/:id?',
        exact: true,
        component: AddMaster,
        isPrivate: true,
        header: false,
        layout: LayoutEmpty,
        roleKey: ROLES_KEYS.roles,
    },
    {
        path: '/init-custom-skills/:id?',
        exact: true,
        component: InitCustomSkills,
        isPrivate: true,
        header: true,
        layout: LayoutEmpty,
    },

    {
        path: '/roles-list',
        exact: true,
        component: RolesList,
        isPrivate: true,
        header: true,
        roleKey: ROLES_KEYS.roles,
    },
    {
        path: '/role/:id',
        exact: true,
        component: RoleEdit,
        isPrivate: true,
        header: true,
        roleKey: ROLES_KEYS.roles,
    },
    {
        path: '/auth/:path?',
        component: AuthNew,
        exact: false,
        isPrivate: false,
        showHeader: false,
        showFooter: false,
        layout: LayoutEmpty,
    },
    {
        path: '/create-role',
        exact: true,
        component: RoleEdit,
        isPrivate: true,
        header: true,
        isAdd: true,
        roleKey: ROLES_KEYS.roles,
    },
    {
        path: '/masters',
        exact: true,
        component: Masters,
        isPrivate: true,
        header: true,
        isAdd: true,
        roleKey: ROLES_KEYS.roles,
        showHeader: true,
    },
    {
        path: '/masters/:id',
        exact: true,
        component: MasterDetails,
        isPrivate: true,
        header: true,
        isAdd: true,
        roleKey: ROLES_KEYS.roles,
        showHeader: false,
    },
    {
        path: '/clients',
        exact: true,
        component: Clients,
        isPrivate: true,
        header: true,
        isAdd: true,
        roleKey: ROLES_KEYS.roles,
        showHeader: true,
    },
    {
        path: '/clients/:id?',
        exact: true,
        component: ClientDetail,
        isPrivate: true,
        showHeader: false,
        showFooter: false,
        isAdd: true,
        roleKey: ROLES_KEYS.roles,
    },
    {
        path: '/clients/:id?/feedbacks',
        exact: true,
        component: ClientDetail,
        isPrivate: true,
        showHeader: false,
        showFooter: false,
        isAdd: true,
        roleKey: ROLES_KEYS.roles,
    },
    {
        path: '/feedbacks',
        exact: true,
        component: Feedbacks,
        isPrivate: true,
        showHeader: false,
        showFooter: false,
        isAdd: true,
        roleKey: ROLES_KEYS.roles,
    },
    {
        path: '/services',
        exact: true,
        component: Services,
        isPrivate: true,
        showHeader: false,
        showFooter: false,
        isAdd: true,
        roleKey: ROLES_KEYS.roles,
    },
    {
        path: '/services/edit',
        exact: true,
        component: ServicesEdit,
        isPrivate: true,
        showHeader: false,
        showFooter: false,
        isAdd: true,
        roleKey: ROLES_KEYS.roles,
    },
    {
        path: '/messages/:chatId?',
        exact: true,
        component: Chat,
        isPrivate: true,
        isAdd: true,
        roleKey: ROLES_KEYS.roles,
        showFooter: false,
        showHeader: true,
    },
    {
        path: '/finance',
        exact: true,
        component: Finance,
        isPrivate: true,
        isAdd: true,
        roleKey: ROLES_KEYS.roles,
        showFooter: false,
        showHeader: true,
    },
    {
        path: '/analitics',
        exact: true,
        component: Analitics,
        isPrivate: true,
        isAdd: true,
        roleKey: ROLES_KEYS.roles,
        showFooter: false,
        showHeader: true,
    },
    {
        path: '/calendar',
        exact: true,
        component: Calendar,
        isPrivate: true,
        isAdd: true,
        roleKey: ROLES_KEYS.roles,
        showFooter: false,
        showHeader: true,
    },
    {
        path: '/calendar/edit/:masterId?',
        exact: true,
        component: Schedule,
        isPrivate: true,
        isAdd: true,
        // layout: false,
        roleKey: ROLES_KEYS.roles,
        showFooter: false,
        showHeader: true,
    },

    {
        path: '/event/:eventId?',
        exact: true,
        component: Event,
        isPrivate: true,
        isAdd: true,
        roleKey: ROLES_KEYS.roles,
        showFooter: false,
        showHeader: false,
    },
    {
        path: '/start-subscription',
        exact: true,
        component: Subscription,
        isPrivate: true,
        showHeader: false,
        header: false,
    },
    {
        path: '/events-list',
        exact: true,
        component: EventsList,
        isPrivate: true,
        isAdd: true,
        roleKey: ROLES_KEYS.roles,
        showFooter: false,
        showHeader: true,
    },
];
export default [].concat(mainRoutes);
