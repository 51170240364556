import React from 'react';
import PropTypes from 'prop-types';
import style from './style.scss';
import { StoresBox } from 'feelqueen_components';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Crown } from '../../assets/img/svg/Crown.svg';

const MobileScreen = ({ isMobile }) => {
    const { t } = useTranslation();
    return (
        <div className={style.container}>
            <Crown width="160" height="160" />
            <div className={style.textContainer}>
                <Typography variant="h5">{t('auth_download_the_feelqueen_app')}</Typography>{' '}
                <span className={style.text}>{t('auth_register_a_salon')}</span>
            </div>
            <div className={style.storesBoxContainer}>
                <StoresBox
                    appStoreLink={'https://clc.am/ayX0_w'}
                    googlePlayLink={'https://clc.am/DzP2yA'}
                />
            </div>
        </div>
    );
};

MobileScreen.propTypes = {
    isMobile: PropTypes.bool,
};

export default MobileScreen;
