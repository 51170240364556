import PropTypes from 'prop-types';
import { AddDataButton } from 'components';
import Button from '@material-ui/core/Button';
import { FormHelperText } from '@material-ui/core';
import style from './style.scss';

const AddButton = ({
    variant,
    onClick,
    disabled,
    titleButton,
    titleLine,
    textError = '',
}: {
    variant: 'button' | false | undefined;
    onClick: () => void;
    disabled: boolean;
    titleButton: string;
    titleLine: string;
    textError: string;
}) => {
    return variant !== 'button' ? (
        <div className={style.buttonContainer}>
            <AddDataButton onClick={onClick} title={titleLine} helperText={textError} error />
        </div>
    ) : (
        <div className={style.buttonContainer}>
            <Button
                size="small"
                disabled={disabled}
                variant="contained"
                color="primary"
                fullWidth={false}
                onClick={onClick}
                className={style.button}
            >
                {titleButton}
            </Button>
            {textError && <FormHelperText error>{textError}</FormHelperText>}
        </div>
    );
};

AddButton.defaultProps = {
    variant: 'button',
    disabled: false,
    titleButton: 'Изменить',
};
AddButton.propTypes = {
    variant: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    titleButton: PropTypes.string,
    titleLine: PropTypes.bool,
};

export default AddButton;
