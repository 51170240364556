import React from 'react';
import TextField from '@material-ui/core/TextField';
import Popup from '../Popup';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import SkillsSelect, { SkillItemInterface } from '../SkillSelect';
import { useTranslation } from 'react-i18next';
import style from './style.module.scss';
import { DataInterface } from 'components/SkillSelect/SubItem';

const color = '#fa835f';

export type SkillStateItem = string | number;

interface SelectSkillsPopupProps {
    skills: SkillItemInterface[];
    customSkills: DataInterface[];

    onSubmit: (selectedSkills: any, selectedCustomSkills: any) => void;
    onCancel: () => void;
    onClose: () => void;
    onChangeSkills?: (skills: SkillStateItem[], customSkills: SkillStateItem[]) => void;
    onAddCustomSkills: () => void;

    selectedSkills: SkillStateItem[];
    selectedCustom: SkillStateItem[];

    title?: string;
    forceExpand: boolean;
    showSelectAll: boolean;
    showSelectedItemsCount: boolean;
    skillsSelectProps: Record<string, any>;
    popupProps: Record<string, any>;
    showPrice: boolean;
    showDuration: boolean;
    enableReinitialize?: boolean;
    showPopup: boolean;
}

const SelectSkillsPopup = ({
    onSubmit, // used for submiting selected skills and custom skills
    skills, // used for skills list (wit categories  and subcategories)
    customSkills, // custom skills (list)
    onCancel, // used for canceling selection
    onClose, // used for closing popup
    showPopup, // boolean to show popup
    title = 'Выберите услуги для комлекса', // title of popup
    forceExpand, // used for force expand of skills
    showSelectAll, // used for showing select all button
    showSelectedItemsCount, // used for showing selected items count
    skillsSelectProps, // props os selctSkills component
    popupProps, // props of popup
    selectedSkills, // default selected skills
    selectedCustom, // default selected custom skills
    showPrice, // used for showing price of service
    showDuration, // used for showing duration of service
    onChangeSkills = () => {}, // listener of changing skills
    enableReinitialize = false, // used for reinitializing selected skills
}: SelectSkillsPopupProps) => {
    const [search, setSearch] = React.useState<string>(); // used for search (filtering)
    const [selectedSkillsState, setSelectedSkills] = React.useState<{ id: string | number }[]>([
        ...selectedSkills.map(i => ({ id: i })),
    ]);

    const [selectedCustomSkillsState, setSelectedCustomSkills] = React.useState<
        { id: string | number }[]
    >([...selectedCustom.map(i => ({ id: i, is_unique_custom_service: true }))]);
    const { t } = useTranslation();

    React.useEffect(() => {
        if (enableReinitialize) {
            setSelectedSkills(selectedSkills.map(i => ({ id: i })));
            setSelectedCustomSkills(selectedCustom.map(i => ({ id: i })));
        }
    }, [selectedSkills, selectedCustom, enableReinitialize]);

    React.useEffect(() => {
        onChangeSkills(
            selectedSkillsState.map(i => i.id),
            selectedCustomSkillsState.map(i => i.id)
        );
    }, [selectedSkillsState, selectedCustomSkillsState, onChangeSkills]);

    return (
        <Popup
            showClear
            title={t(title)}
            onClear={() => {
                onClose();
            }}
            onSubmit={() =>
                onSubmit(
                    selectedSkillsState.map(i => i.id),
                    selectedCustomSkillsState.map(i => i.id)
                )
            }
            onCancel={() => {
                onCancel();
                setSelectedSkills([]);
                setSelectedCustomSkills([]);
            }}
            showSubmit
            showCancel
            showPopup={showPopup}
            visible
            cancelButtonText={t('generic_clear')}
            submitButtonText={t('custom_skill_Save_Add')}
            childrenContainerClassName={style.dataContainer}
            popupBackgroundsProps={{ onClick: onClose }}
            {...popupProps}
        >
            <div className={style.topBlock}>
                <TextField
                    className={style.searchInput}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon htmlColor={color} />
                            </InputAdornment>
                        ),
                    }}
                    required
                    value={search}
                    onChange={ev => setSearch(ev.target.value)}
                    fullWidth
                    size="small"
                    variant="outlined"
                    placeholder={t('services_name_of_the_service')}
                />
            </div>

            <div className={style.container}>
                <SkillsSelect
                    selectedCustom={selectedCustomSkillsState}
                    setSelectedCustom={setSelectedCustomSkills}
                    selected={selectedSkillsState}
                    setSelected={setSelectedSkills}
                    showSelectAll={showSelectAll}
                    showSelectedItemsCount={showSelectedItemsCount}
                    forceExpand={forceExpand}
                    showInputs={false}
                    skills={skills}
                    searchText={search}
                    customSkills={customSkills}
                    showDuration={showDuration}
                    showPrice={showPrice}
                    {...skillsSelectProps}
                />
            </div>
        </Popup>
    );
};

SelectSkillsPopup.defaultProps = {
    selectedCustomSkills: [],
    forceExpand: false,
    onAddCustomSkills: () => {},
    showPopup: false,
    selectedSkills: [],
    title: 'test',
    onSubmit: () => {},
    onCancel: () => {},
    onClose: () => {},
    showSelectAll: false,
    showSelectedItemsCount: false,
    skillsSelectProps: {},
    popupProps: {},
    selectedCustom: [],
    selected: [],
    setSelected: () => {},
    setSelectedCustom: () => {},
};

export default SelectSkillsPopup;
