/* global global */
import React from 'react';
import PropTypes from 'prop-types';
import 'react-phone-input-2/lib/material.css';
import NumberInput from './NumberInput';
import { useTranslation } from 'react-i18next';

const PhoneInput = ({ countryCode, ...props }) => {
    const { t } = useTranslation();

    return (
        <NumberInput
            onlyNumbers
            countryCode={countryCode || 'gb'}
            {...props}
            placeholder={t('auth_phone')}
            onlyCountries={[
                'ru',
                'by',
                'gb',
                'au',
                'be',
                'bg',
                'hu',
                'de',
                'gr',
                'dn',
                'ie',
                'es',
                'it',
                'lv',
                'lt',
                'lu',
                'ml',
                'nl',
                'pl',
                'pt',
                'ro',
                'sk',
                'si',
                'fi',
                'fr',
                'hr',
                'cz',
                'sw',
                'ee',
                'br',
                'ge',
                'id',
                'ca',
                'cn',
                'nz',
                'us',
                'sg',
                'tr',
                'za',
                'kr',
                'jp',
                'ua',
                'ae',
                'bh',
                'kw',
                'sa',
            ]}
            countryCodes={[
                { country: 'by', code: '375' },
                { code: '7', country: 'ru' },
                { code: '44', country: 'gb' },
                { code: '43', country: 'au' },
                { code: '32', country: 'be' },
                { code: '359', country: 'bg' },
                { code: '36', country: 'hu' },
                { code: '49', country: 'de' },
                { code: '30', country: 'gr' },
                { code: '45', country: 'dn' },
                { code: '372', country: 'ie' },
                { code: '34', country: 'es' },
                { code: '39', country: 'it' },
                { code: '371', country: 'lv' },
                { code: '370', country: 'lt' },
                { code: '352', country: 'lu' },
                { code: '356', country: 'ml' },
                { code: '31', country: 'nl' },
                { code: '48', country: 'pl' },
                { code: '351', country: 'pt' },
                { code: '40', country: 'ro' },
                { code: '421', country: 'sk' },
                { code: '386', country: 'si' },
                { code: '358', country: 'fi' },
                { code: '33', country: 'fr' },
                { code: '385', country: 'hr' },
                { code: '420', country: 'cz' },
                { code: '46', country: 'sw' },
                { code: '372', country: 'ee' },
                { code: '55', country: 'br' },
                { code: '995', country: 'ge' },
                { code: '91', country: 'id' },
                { code: '1', country: 'ca' },
                { code: '86', country: 'cn' },
                { code: '64', country: 'nz' },
                { code: '1', country: 'us' },
                { code: '65', country: 'sg' },
                { code: '90', country: 'tr' },
                { code: '27', country: 'za' },
                { code: '82', country: 'kr' },
                { code: '81', country: 'jp' },
                { code: '380', country: 'ua' },
                { code: '971', country: 'ae' },
                { code: '973', country: 'bh' },
                { code: '965', country: 'kw' },
                { code: '966', country: 'sa' },
            ]}
        />
    );
};

PhoneInput.propTypes = {
    defaultCountry: PropTypes.string,
};
export default PhoneInput;
