import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import get from 'lodash/get';
import { InfoCard, InfoCardLine, NumberFormat } from 'components';
import { Calculator, Calendar, Cup, Case, Time } from 'assets/img/svg/prepared';
import { useTranslation } from 'react-i18next';
import { getSystemLang } from 'helpers/getSystemLang';
import style from './style.scss';

const TopServices = ({ topServices, title, showHead = true }) => {
    const { t } = useTranslation();
    const lang = getSystemLang();
    return (
        <InfoCard
            title={title || t('home_top_services')}
            headerContent={
                showHead && (
                    <span className={style.headerContent}>
                        {moment()
                            .locale(lang)
                            .format('MMMM YYYY')}
                    </span>
                )
            }
            showMenu={false}
        >
            <Grid item xs={12} className={style.dataContainerSmall}>
                {topServices.length === 0 ? (
                    <div className={style.containerEmpty}>
                        <Case />
                        <span className={style.title}>{t('home_yet_this_month')}</span>
                        {/*<span className={style.text}>Используйте ваши услуги с умом! </span>*/}
                    </div>
                ) : (
                    <div className={style.servicesContainer}>
                        <div className={style.labels}>
                            <span>{t('generic_service')}</span>
                            <span>{t('home_number_of_appointments')}</span>
                        </div>
                        {topServices.map(i => (
                            <InfoCardLine key={i.id} title={i.title} value={i.count} />
                        ))}
                    </div>
                )}
            </Grid>
        </InfoCard>
    );
};

TopServices.propTypes = {
    topServices: PropTypes.object,
    currencyCurrent: PropTypes.object,
};
TopServices.defaultProps = {
    topServices: [],
};

export default TopServices;
