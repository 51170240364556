export const getAddressPlaceholder = countryCode => {
    switch (countryCode) {
        case 'ae':
            return 'Al Khaleej Road 12';
        case 'uk':
            return '8 Pushkin Street';
        case 'by':
            return 'улица Пушкина 8';
        case 'ru':
            return 'улица Пушкина 8';
        default:
            return '8 Pushkin Street';
    }
};

export const getWebsitePlaceholder = countryCode => {
    switch (countryCode) {
        case 'ae':
            return 'feelbeauty.io';
        case 'uk':
            return 'feelqueen.co.uk';
        case 'by':
            return 'feelqueen.by';
        case 'ru':
            return 'feelqueen.by';
        default:
            return 'feelqueen.co.uk';
    }
};
