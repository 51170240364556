import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector, useDispatch } from 'react-redux';
import { Skeleton, EventItem } from 'components';
import { useTranslation } from 'react-i18next';
import style from './style.scss';
import FilterBlock from './FilterBlock';
import moment from 'moment';
import { getEventsHistoryRequest, getEventsHistorySelector } from 'modules/events';
import { getSalonSelector } from 'modules/salon';
import { getMastersRequest, getSalonMastersSelector } from 'modules/masters';
import {
    getTextByPayment,
    PAYMENT_APPLE,
    PAYMENT_CARD,
    PAYMENT_CASH,
    PAYMENT_CASSHLESS,
    PAYMENT_GOOGLE,
    PAYMENT_ONLINE,
} from 'constants/events';

interface IEventsListProps {
    history: any;
    match: any;
    currentLocalization: any;
}

const EventsList = ({ history, match, currentLocalization: { countryCode } }: IEventsListProps) => {
    const dispatch = useDispatch();

    const [date, setDate] = React.useState({
        from: moment().startOf('month'),
        to: moment().endOf('month'),
    });
    const [dateFree, setDateFree] = useState({ from: undefined, to: undefined });
    const [summ, setSumm] = useState({ from: undefined, to: undefined });
    const [skill, setSkill] = useState<any[]>([]);
    const [master, setMaster] = useState<any[]>([]);
    const [payments, setPayments] = useState<any[]>([]);
    const { t } = useTranslation();
    const { id, services = [], skills = [] } = useSelector(getSalonSelector) as any;
    const events = useSelector(getEventsHistorySelector) as any[];
    const mastersArr = useSelector(getSalonMastersSelector) as any[];

    const handleChangeMaster = (value: any) => {
        if (Array.isArray(value) && value.length === 0) {
            setMaster([]);
            return;
        }
        setMaster((prev: any) =>
            value.value ? [...prev, value] : prev.filter((i: any) => i.id !== value.id)
        );
    };
    const handleChangePayment = (value: any) => {
        if (Array.isArray(value) && value.length === 0) {
            setPayments([]);
            return;
        }
        setPayments((prev: any) =>
            value.value ? [...prev, value] : prev.filter((i: any) => i.id !== value.id)
        );
    };
    const handleChangeSkill = (value: any) => {
        if (Array.isArray(value) && value.length === 0) {
            setSkill([]);
            return;
        }
        setSkill((prev: any) =>
            value.value ? [...prev, value] : prev.filter((i: any) => i.id !== value.id)
        );
    };

    const payments_ids = [
        {
            value: PAYMENT_CASSHLESS,
            label: t(getTextByPayment(PAYMENT_CASSHLESS)),
            id: PAYMENT_CASSHLESS,
        },
        { value: PAYMENT_GOOGLE, label: t(getTextByPayment(PAYMENT_GOOGLE)), id: PAYMENT_GOOGLE },
        { value: PAYMENT_APPLE, label: t(getTextByPayment(PAYMENT_APPLE)), id: PAYMENT_APPLE },
        { value: PAYMENT_CARD, label: t(getTextByPayment(PAYMENT_CARD)), id: PAYMENT_CARD },
        { value: PAYMENT_ONLINE, label: t(getTextByPayment(PAYMENT_ONLINE)), id: PAYMENT_ONLINE },
        { value: PAYMENT_CASH, label: t(getTextByPayment(PAYMENT_CASH)), id: PAYMENT_CASH },
    ];

    useEffect(() => {
        if (id) {
            dispatch(getMastersRequest({ id }));
        }
    }, [dispatch, id]);

    const { masters, services: servicesFull } = useMemo(() => {
        return {
            masters: [
                ...mastersArr
                    .filter(i => i.status === 'confirmed')
                    .map(i => ({
                        label: `${i.first_name} ${i.last_name}`,
                        value: false,
                        id: i.id,
                    })),
            ],
            services: [
                ...skills.map((i: any) => ({
                    label: i.title,
                    value: false,
                    id: i.id,
                    isCustom: false,
                })),
                ...services.map((i: any) => ({
                    label: i.title,
                    value: false,
                    id: i.id,
                    isCustom: true,
                })),
            ],
        };
    }, [services, skills, mastersArr]);

    const updateEvents = useCallback(() => {
        if (id) {
            const preparedData = {
                skill_ids: [...skill].filter((i: any) => !i.isCustom).map((i: any) => i.id),
                service_ids: [...skill].filter((i: any) => i.isCustom).map((i: any) => i.id),
                payment_type_ids: [...payments].map((i: any) => i.id),
                master_ids: [...master].map((i: any) => i.id),
            };

            dispatch(
                getEventsHistoryRequest({
                    id,
                    showLoader: false,
                    fromDate: date.from.format('YYYY-MM-DD'),
                    toDate: date.to.format('YYYY-MM-DD'),
                    amount_from: summ.from || '',
                    amount_to: summ.to || '',
                    ...preparedData,
                })
            );
        }
    }, [date.from, date.to, dispatch, id, master, payments, skill, summ.from, summ.to]);

    useEffect(() => {
        updateEvents();
    }, [updateEvents]);

    return (
        <Skeleton title={t('menu_event_list')} subTitle="" bottomPositionButtons={false}>
            <Grid container className={style.gridContainer}>
                <Grid className={style.gridItem}>
                    <FilterBlock
                        date={date}
                        setDate={setDate}
                        setMaster={handleChangeMaster}
                        master={master}
                        masters={masters}
                        rightAlign
                        dateFree={dateFree}
                        setDateFree={setDateFree}
                        summ={summ}
                        setSumm={setSumm}
                        skill={skill}
                        services={servicesFull}
                        setSkill={handleChangeSkill}
                        variant="contained"
                        payment_ids={payments_ids}
                        payment={payments}
                        setPayment={handleChangePayment}
                    />
                </Grid>
                <Grid className={style.eventList}>
                    {events.length > 0 ? (
                        <>
                            {events.map((item: any) => (
                                <EventItem showMaster key={item.id} data={item} history={history} />
                            ))}
                        </>
                    ) : (
                        <div className={style.placeHolderContainer}>
                            <div className={style.placeHolder}>
                                {t('home_no_appointment_history')}
                            </div>
                        </div>
                    )}
                </Grid>
            </Grid>
        </Skeleton>
    );
};

export default EventsList;
