import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from 'components';
import Grid from '@material-ui/core/Grid';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrencySelector } from 'modules/localization';
import { useTranslation } from 'react-i18next';
import style from './style.scss';

const Analitics = ({ history }) => {
    const currency = useSelector(getCurrencySelector);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    return (
        <Skeleton
            backgroundColor="#fffaf6"
            textError=""
            title={t('payment_feelqueen_fee_amount')}
            subTitle=""
            showBackButtonInTop
            backText={t('menu_payments')}
            onBack={() => {
                history.goBack();
            }}
            classes={{ titleContainer: style.titleContainer }}
            bottomPositionButtons={false}
            onNext={false}
        >
            <Grid container spacing={3} className={style.container}>
                <Grid item md={12} className={style.priceItem}>
                    <div className={style.price}>
                        <span className={style.procent}>20%</span>{' '}
                        {t('payment_cost_of_the_service')}
                    </div>
                    <div className={style.description}>{t('payment_appointment_of_a_new')}</div>
                    <div className={style.note}>{t('payment_booked_for_you_via_the_app')}</div>
                </Grid>
                <Grid item md={12} className={style.priceItem}>
                    <div className={style.price}>
                        <span className={style.procent}>5% </span>{' '}
                        {t('payment_of_the_cost_of_the_service')}
                    </div>
                    <div className={style.description}>{t('payment_subsequent_appointments')}</div>
                    <div className={style.note}>{t('payment_appointments_of_the_client')}</div>
                </Grid>
                <Grid item md={12} className={style.priceItem}>
                    <div className={style.price}>
                        <span className={style.procent}>0%</span>{' '}
                        {t('payment_of_the_cost_of_the_service')}
                    </div>
                    <div className={style.description}>
                        {t('payment_appointment_of_your_client')}
                    </div>
                    <div className={style.note}>{t('payment_added_by_you_to_the_client')}</div>
                </Grid>
            </Grid>
        </Skeleton>
    );
};

Analitics.propTypes = {
    history: PropTypes.object,
};

export default Analitics;
