import { Button, makeStyles } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import style from '../../style.scss';
import { useFormHook } from '../common/formHook';
import PlainField from '../common/PlainField';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    backButton: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        padding: '0px 24px 0px 20px',
        minWidth: 128,
        border: '2px solid #FA835F',
        '&:hover': {
            border: '2px solid #FA835F',
        },
    },
    arrow: {
        marginRight: 13,
    },
    date: {
        minWidth: 340,
    },
}));

const Bank = ({ step, saveStepData, savedStepData, handleNext, handleBack, sendForm, isBel }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const onSaveSuccess = stepData => {
        sendForm(stepData, () => {
            handleNext();
        });
    };

    const getRequiredFields = useCallback(
        () =>
            isBel
                ? ['bank_account', 'bank_name', 'bank_bik']
                : ['bank_account', 'bank_name', 'bank_bik', 'bank_correspondent_account'],
        [isBel]
    );

    const { formData, errorsData, onFormFieldChange, onSave } = useFormHook({
        formId: step,
        saveFormData: saveStepData,
        initFormData: savedStepData,
        getRequiredFields: getRequiredFields,
        onSaveSuccess: onSaveSuccess,
    });

    const handleBackClick = () => {
        saveStepData(step, formData); // presave to outer scope not to loose user filled data;
        handleBack();
    };

    const { bank_account, bank_name, bank_bik, bank_correspondent_account } = formData;

    return (
        <>
            <p className={style.stepDescription}>{t('payment_register_organisation_from_which')}</p>

            <PlainField
                label={t('payment_register_current_account')}
                placeholder={t('payment_register_account_of_the_organisation')}
                value={bank_account}
                onChange={onFormFieldChange('bank_account')}
                error={errorsData.bank_account}
            />
            <PlainField
                label={t('payment_register_bank_name')}
                placeholder={t('payment_register_for_example_alfa_bank_jsc')}
                value={bank_name}
                onChange={onFormFieldChange('bank_name')}
                error={errorsData.bank_name}
            />
            <PlainField
                label={t('payment_register_bic')}
                placeholder={t('payment_register_bic')}
                value={bank_bik}
                onChange={onFormFieldChange('bank_bik')}
                error={errorsData.bank_bik}
            />
            {!isBel && (
                <PlainField
                    label={t('payment_register_corresponding_account_title')}
                    placeholder={t('payment_register_corresponding_account')}
                    value={bank_correspondent_account}
                    onChange={onFormFieldChange('bank_correspondent_account')}
                    error={errorsData.bank_correspondent_account}
                />
            )}
            <Button
                variant="outlined"
                color="primary"
                onClick={handleBackClick}
                className={classes.backButton}
                size="large"
            >
                <ArrowBackIcon className={classes.arrow} />
                {t('generic_back')}
            </Button>
            <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={onSave}
                size="large"
            >
                {t('generic_next')}
            </Button>
        </>
    );
};

Bank.propTypes = {
    step: PropTypes.number,
    saveStepData: PropTypes.func,
    savedStepData: PropTypes.object,
    handleNext: PropTypes.func,
    handleBack: PropTypes.func,
    sendForm: PropTypes.func,
};

export default Bank;
