import { all, call, takeLatest } from 'redux-saga/effects';
import { INIT_DATA } from '../init';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import get from 'lodash/get';
import ru from './ru.json';
import en from './en.json';

const getLang = () => {
    const host = get(window, 'location.host', '');
    if (host.includes('localhost') || host.includes('salondev')) {
        const lang =
            window && window.navigator && window.navigator.language
                ? window.navigator.language.substring(0, 2)
                : 'en';
        return lang === 'be' ? 'en' : lang;
    } else {
        if (host.includes('co.uk')) {
            return 'en';
        } else if (host.includes('by') || host.includes('ru')) {
            return 'ru';
        } else return 'en';
    }
};

const lang = getLang();

i18next.use(initReactI18next).init({
    fallbackLng: lang || 'en',
    debug: false,
    resources: {
        en: {
            translation: {
                ...en,
            },
        },
        ru: {
            translation: {
                ...ru,
            },
        },
    },
});

const getTranslateSaga = function*() {
    const lang = yield call(getLang);
    i18next.changeLanguage(lang);
};

export const i18nextModuleSaga = function*(dispatch) {
    yield all([takeLatest([INIT_DATA], getTranslateSaga, dispatch)]);
};

export default i18next;
