import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { Popup as PopupBackground } from 'components';
import Rating from '@material-ui/lab/Rating';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import style from './style.scss';

const useStyles = makeStyles(theme => ({
    icon: {
        margin: '0 10px',
    },
}));

const FeedbackPopup = ({ onClose, onCancel, onSubmit }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [rating, setRating] = React.useState(0);
    const [feedback, setFeedback] = React.useState();
    const [textError, setTextError] = React.useState();

    return (
        <PopupBackground
            onSubmit={() => {
                switch (true) {
                    case rating < 4 && !feedback && rating > 0:
                        setTextError(t('generic_add_feedback'));
                        return;
                    case feedback && rating === 0:
                        setTextError(t('generic_please_rate'));
                        return;
                    case rating === 0:
                        setTextError(t('generic_add_a_rating'));
                        return;
                    default:
                        onSubmit({ rating, feedback });
                        return;
                }
                // if (rating < 4 && !feedback) {
                // } else {
                // }
            }}
            onCancel={() => {
                onCancel();
                setRating(0);
                setFeedback('');
            }}
            onClear={() => {
                onClose();
            }}
            title={t('generic_review_client_after')}
            showPopup
            showClear
            visible
            showCancel
            showSubmit
            textError={textError}
            cancelButtonText={t('generic_clear')}
            submitButtonText={t('add_client_add')}
            childrenContainerClassName={style.dataContainer}
            popupBackgroundsProps={{ onClick: onClose }}
        >
            <div className={style.subtitle}>{t('generic_rating_and')}</div>
            <div className={style.ratingContainer}>
                <Rating
                    classes={{ icon: classes.icon }}
                    size="large"
                    name="simple-controlled"
                    value={rating}
                    onChange={(event, newValue) => {
                        setRating(newValue);
                    }}
                />
            </div>
            <div className={style.topBlock}>
                <TextField
                    className={style.searchInput}
                    InputLabelProps={{ shrink: true }}
                    value={feedback}
                    onChange={ev => setFeedback(ev.target.value)}
                    fullWidth
                    size="small"
                    label={t('generic_leave_a_comment')}
                    placeholder=""
                />
            </div>
        </PopupBackground>
    );
};

FeedbackPopup.propTypes = {
    onClose: PropTypes.func,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
};

FeedbackPopup.defaultProps = {
    onClose: () => {},
    onCancel: () => {},
    onSubmit: () => {},
};
export default FeedbackPopup;
