// eslint-disable-next-line no-undef
export const CUSTOM_SKILLS_PARENT_TITLE_MAP = new Map([
    ['hair', 1],
    ['nails', 23],
    ['body', 42],
    ['massage', 87],
    ['tattoo', 115],
    ['face', 130],
    ['cosmetology', 155],
]);
