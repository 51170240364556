import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';

import { IncreasePicker } from 'components';

import style from './style.scss';

const SelectDuration = ({
    setFieldValue,
    values,
    errors,
    disabled = false,
}: {
    setFieldValue: (field: string, value: any) => void;
    values: Record<string, any>;
    errors: Record<string, string | undefined>;
    disabled?: boolean;
}) => {
    const { t } = useTranslation();
    const duration = values['duration'];
    // const isTouched = touched['duration'];
    const textError = errors['duration'];

    const onChangeDuration = (value: number) => {
        setFieldValue('duration', value);
    };

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <div className={style.durationContainer}>
                        <IncreasePicker
                            disabled={disabled}
                            suffix={t('generic_min')}
                            min={0}
                            required={false}
                            max={500}
                            label={t('generic_duration')}
                            step={5}
                            value={duration}
                            onChange={onChangeDuration}
                            helperText={textError}
                            error={!!textError}
                        />
                    </div>
                </Grid>
            </Grid>
        </>
    );
};

export default SelectDuration;
