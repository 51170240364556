import React from 'react';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import { SimpleSelect, SimpleInput } from 'components';
import DatePicker from 'components/DatePicker';
import { useTranslation } from 'react-i18next';
import style from './style.scss';

interface IFilterBlockProps {
    date: any;
    setDate: any;
    setMaster: any;
    master: any;
    masters: any;
    rightAlign?: boolean;
    variant?: string;
    dateFree: any;
    setDateFree: any;
    summ: any;
    setSumm: any;
    services: any;
    setSkill: any;
    skill: any;
    payment_ids: any[];
    payment: any;
    setPayment: any;
}

const FilterBlock = ({
    date,
    setDate,
    setMaster,
    master,
    masters = [],
    variant = '',
    summ,
    setSumm,
    services,
    setSkill,
    skill,
    payment_ids,
    payment,
    setPayment,
}: IFilterBlockProps) => {
    const { t } = useTranslation();
    return (
        <Grid container className={style.filterContainer}>
            <Grid item xs={12} className={style.filter}>
                <DatePicker
                    onChange={data => {
                        setDate({
                            from: moment(data[0].startDate),
                            to: moment(data[0].endDate),
                        });
                    }}
                    date={date}
                    variant="outlined"
                    color={undefined}
                    placeholder={undefined}
                    buttonProps={undefined}
                />
                <SimpleSelect
                    variant={!!skill.length ? 'contained' : variant}
                    color={!!skill.length ? 'primary' : ''}
                    placeholder={t('search_all_services')}
                    value={skill}
                    options={[...services]}
                    onChange={setSkill}
                />
                <SimpleSelect
                    variant={!!master?.length ? 'contained' : variant}
                    color={!!master?.length ? 'primary' : ''}
                    placeholder={t('analytics_all_staff')}
                    value={master}
                    onChange={setMaster}
                    options={[...masters]}
                />
                <SimpleSelect
                    variant={!!payment.length ? 'contained' : variant}
                    color={!!payment.length ? 'primary' : ''}
                    placeholder={t('payment_payment_method')}
                    value={payment}
                    onChange={setPayment}
                    options={[...payment_ids]}
                />

                <SimpleInput
                    placeholder={t('filter_by_the_amount_per_service')}
                    inputsProps={{ type: 'number' }}
                    inputStartProps={{ placeholder: '', label: t('generic_from') }}
                    inputEndProps={{ placeholder: '', label: t('generic_to') }}
                    variant={summ.from || summ.to ? 'contained' : variant}
                    color={summ.from || summ.to ? 'primary' : ''}
                    value={summ}
                    onChange={(summ: any) => {
                        setSumm(summ);
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default FilterBlock;
