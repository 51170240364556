import * as apiHelpers from 'api';

const modules = 'subscription';

const {
    helpers: { actionCreator, apiSelector },
    modules: { ApiRoutes },
} = apiHelpers;

export const INIT_SESSION_REQUEST = `${modules}/INIT_SESSION_REQUEST`;
export const CONFIRM_SESSION_REQUEST = `${modules}/CONFIRM_SESSION_REQUEST`;

export const initSubscriptionSessionRequest = actionCreator(INIT_SESSION_REQUEST);
export const confirmSessionRequest = actionCreator(CONFIRM_SESSION_REQUEST);

const apiRoutes = new ApiRoutes();

apiRoutes.add(INIT_SESSION_REQUEST, ({ id }) => {
    return {
        url: `/api/v2/salons/${id}/stripe/session`,
        method: 'POST',
    };
});

apiRoutes.add(CONFIRM_SESSION_REQUEST, ({ id, data }) => {
    return {
        url: `/api/v2/salons/${id}/stripe/session/confirm`,
        method: 'POST',
        data,
    };
});

export const getSubscriptionSessionSelector = apiSelector(INIT_SESSION_REQUEST);
