import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from 'components';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { useHooks } from './hooks';
import MyPayments from './components/MyPayments';
import Docs from './components/Docs';
import PaymentsData from './components/PaymentsData';
import PaymentsHistory from './components/PaymentsHistory';
import style from './style.scss';
import Popup from 'components/Popup';
import { STRIPE_STATUS } from 'constants/finance';
import Subscription from './components/Subscription';
import { confirmSessionRequest } from 'modules/subscription';
import { useDispatch } from 'react-redux';

const Payments = ({ history, currentLocalization }) => {
    const { countryCode } = currentLocalization;
    const {
        balance,
        paymentsHistory,
        orders,
        paymentsData,
        onClickPayment,
        stripe_account,
        subscription_status,
        initStripePaymentsAccount,
        id,
        getStripeLink,
        subscription_ends_at,
        subscription_trial_ends_at,
        getSalonRequest,
    } = useHooks({
        history,
    });
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [isShowCompletedPopup, setShowCompletedPopup] = useState(false);
    const [isShowRestrictedPopup, setShowRestrictedPopup] = useState(false);

    const onConfirmSubscription = useCallback(
        salonId => {
            const subscriptionID = sessionStorage.getItem('subscriptionID');

            if (subscriptionID && salonId) {
                dispatch(
                    confirmSessionRequest(
                        { id: salonId, data: { id: subscriptionID } },
                        {
                            onSuccess: () => {
                                sessionStorage.removeItem('subscriptionID');
                                dispatch(
                                    getSalonRequest(
                                        { id: salonId },
                                        { onSuccess: () => history.push('/payments') }
                                    )
                                );
                            },
                        }
                    )
                );
            }
        },
        [dispatch, getSalonRequest, history]
    );

    useEffect(() => {
        if (
            history.location.pathname === '/payments-register-completed' &&
            stripe_account &&
            (stripe_account.status === STRIPE_STATUS.COMPLETED ||
                stripe_account.status === STRIPE_STATUS.RESTRICTED)
        ) {
            stripe_account.status === STRIPE_STATUS.COMPLETED
                ? setShowCompletedPopup(!isShowCompletedPopup)
                : setShowRestrictedPopup(!isShowRestrictedPopup);
        } else if (history.location.pathname === '/payments-register-expired') {
            console.log(stripe_account, 'exp');
        }
    }, [history.location.pathname, isShowCompletedPopup, isShowRestrictedPopup, stripe_account]);

    useEffect(() => {
        if (history.location.pathname === '/payments/success' && id) {
            onConfirmSubscription(id);
        }
    }, [history.location.pathname, id, onConfirmSubscription]);

    return (
        <Skeleton
            backgroundColor="#fffaf6"
            textError=""
            title={t('menu_payments')}
            subTitle=""
            nextButtonText={t('payment_fee_amounts')}
            nextButtonProps={{ variant: 'outlined', endIcon: <InfoOutlinedIcon color="primary" /> }}
            onNext={() => {
                history.push('/payments-taxes');
            }}
            bottomPositionButtons={false}
        >
            <Grid container spacing={3} className={style.container}>
                <Grid container spacing={3} className={style.container}>
                    <Grid item md={6}>
                        <MyPayments
                            data={balance}
                            paymentsData={paymentsData}
                            onClick={onClickPayment}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <Subscription
                            id={id}
                            subscriptionStatus={subscription_status}
                            history={history}
                            subscriptionEndsAt={subscription_ends_at}
                            subscriptionTrialEndsAt={subscription_trial_ends_at}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <PaymentsData
                            data={paymentsData}
                            countryCode={countryCode}
                            history={history}
                            stripe={stripe_account}
                            initStripePaymentsAccount={initStripePaymentsAccount}
                            id={id}
                            getStripeLink={getStripeLink}
                        />
                    </Grid>

                    <Grid item md={6}>
                        <Docs data={orders} />
                    </Grid>

                    <Grid item md={6}>
                        <PaymentsHistory data={paymentsHistory} />
                    </Grid>
                </Grid>
            </Grid>
            {isShowCompletedPopup && (
                <Popup
                    title={t('completed_popup_title')}
                    showCancel={false}
                    submitButtonText={t('get_it')}
                    onSubmit={() => {
                        setShowCompletedPopup(false);
                        history.replace('/payments');
                    }}
                    message={t('completed_popup_text')}
                    style={{ maxWidth: '700px' }}
                />
            )}
            {isShowRestrictedPopup && (
                <Popup
                    style={{ maxWidth: '700px' }}
                    title={t('online_payment_status')}
                    cancelButtonText={t('add_later')}
                    submitButtonText={t('add_missing_info')}
                    onSubmit={() => {
                        if (id) getStripeLink(id);
                    }}
                    onCancel={() => {
                        setShowRestrictedPopup(false);
                        history.replace('/payments');
                    }}
                    message={`${t('add_missing_info_text')}${(<br />)}${t(
                        'add_missing_info_text_1'
                    )}${(<br />)}${t('add_missing_info_text_2')}`}
                />
            )}
        </Skeleton>
    );
};

Payments.propTypes = {
    history: PropTypes.object,
    currentLocalization: PropTypes.object,
};

export default Payments;
